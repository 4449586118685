import PropTypes from 'prop-types';
import React, { Component, useState, useEffect } from 'react';
import CareworksTrainingModal from '../../../shared/components/CareworksTrainingModal';
import SendEDIModal from '../../../modules/bill-operations/components/SendEDIModal';
import SearchPPOModal from '../../../modules/bill-operations/components/SearchPPOModal';
import FeeLookupModal from '../../../modules/bill-operations/components/FeeLookupModal';
import ODGLookupModal from '../../../modules/bill-operations/components/OdgLookupModal';
import PrintEORModal from '../../../modules/bill-operations/components/PrintEORModal';
import PrintReportsModal from '../../../modules/bill-operations/components/PrintReportsModal';
import PopulateCashApplication from '../../../modules/bill-operations/components/PopulateCashApplication';
import InfoModal from '../../../modules/fast-360/components/InfoModal';
import { authSelectors } from 'modules/auth/store';

import {
  Button,
  ButtonDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import {
  billOperationsActions,
  billOperationsSelectors,
  billOperationsOperations,
} from '../../../modules/bill-operations/store';
import { connect } from 'react-redux';

import { ADMIN_MODULE } from 'common/appModules';
import { FAST_360_MODULE, FAST_360_MAINTENANCE_MODULE } from 'common/appModules';
const Info = () => {
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const [isInfoModal, setIsInfoModal] = useState(false);
  const handleInfoClick = () => { setIsInfoModal(!isInfoModal); };
  return (
    <>
  <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret size="sm">
          About
        </DropdownToggle>
        
        <DropdownMenu md={12}>
          <DropdownItem value="Info" onClick={handleInfoClick}>
            Info
          </DropdownItem>
        </DropdownMenu>
  </ButtonDropdown>
  {isInfoModal && <InfoModal clicked={handleInfoClick} />}
  </>
  );
}
const Options = () => {
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const [isOpen, setIsOpen] = useState(false);
  const [trainingOpen, setTrainingOpen] = useState(false);
  const toggleTraining = () => setTrainingOpen(!trainingOpen);
  const onMouseEnterTraining = () => setTrainingOpen(true);
  const onMouseLeaveTraining = () => setTrainingOpen(false);

  const openPPOHistory = () => {
    setIsOpen(!isOpen);
  };

  const [isOpenSendEDI, setisOpenSendEDI] = useState(false);

  const openSendEDI = () => {
    setisOpenSendEDI(!isOpenSendEDI);
  };

  const [isOpenFeeLookup, setIsOpenFeeLookup] = useState(false);
  const [isOpenOdgLookup, setIsOpenOdgLookup] = useState(false);
  const [isOpenPrintEOR, setIsOpenPrintEOR] = useState(false);
  const [isOpenPrintReports, setIsOpenPrintReports] = useState(false);
  const [
    isOpenPopulateCashApplication,
    setIsOpenPopulateCashApplication,
  ] = useState(false);
  const [isOpenCareworksTraining, setIsOpenCareworksTraining] = useState(false);

  const openFeeLookup = () => {
    setIsOpenFeeLookup(!isOpenFeeLookup);
  };

  const openOdgLookup = () => {
    setIsOpenOdgLookup(!isOpenOdgLookup);
  };

  const openPrintEOR = () => {
    setIsOpenPrintEOR(!isOpenPrintEOR);
  };

  const openPrintReports = () => {
    setIsOpenPrintReports(!isOpenPrintReports);
  };

  const openPopulateCashApplication = () => {
    setIsOpenPopulateCashApplication(!isOpenPopulateCashApplication);
  };

  const openCareworksTraining = () => {
    setIsOpenCareworksTraining(!isOpenCareworksTraining);
  };

  const openCareworksTrainingPDF = () => {
    const domain = window.location.hostname;
    window.open(process.env.PUBLIC_URL + '/files/Z3_User_Guide.pdf', '_blank');
  };

  return (
    <>
      <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret size="sm">
          Options
        </DropdownToggle>
        <DropdownMenu md={12}>
          <DropdownItem value="feeLookup" onClick={openFeeLookup}>
            Fee Lookup
          </DropdownItem>
          <DropdownItem value="odgLookup" onClick={openOdgLookup}>
            ODG Lookup
          </DropdownItem>
          <DropdownItem value="printEORs" onClick={openPrintEOR}>
            Print EORs
          </DropdownItem>
          <DropdownItem value="printReports" onClick={openPrintReports}>
            Print Reports
          </DropdownItem>
          <DropdownItem
            value="populateCashApplication"
            onClick={openPopulateCashApplication}
          >
            Populate Cash Application
          </DropdownItem>
          <DropdownItem value="sendEDI" onClick={openSendEDI}>
            Send EDI
          </DropdownItem>
          <DropdownItem value="searchPPOHistory" onClick={openPPOHistory}>
            Search PPO History
          </DropdownItem>
          <DropdownItem onMouseOver={onMouseEnterTraining} onMouseLeave={onMouseLeaveTraining}>
            <Dropdown direction="right" className="d-inline-block submenu" isOpen={trainingOpen} toggle={toggleTraining}>
              <DropdownToggle className="dropdown-item" caret>
                Careworks Training  
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem value="careworksTraining" onClick={openCareworksTraining}>
                  Z3 Training Video
                </DropdownItem>
                <DropdownItem value="careworksTrainingPDF" onClick={openCareworksTrainingPDF}J>
                  Z3 Training Manual
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </DropdownItem>          
        </DropdownMenu>
      </ButtonDropdown>
      <SendEDIModal isOpen={isOpenSendEDI} onClose={openSendEDI} />
      <SearchPPOModal isOpen={isOpen} onClose={openPPOHistory} />
      <FeeLookupModal isOpen={isOpenFeeLookup} onClose={openFeeLookup} />
      <ODGLookupModal isOpen={isOpenOdgLookup} onClose={openOdgLookup} fromOptions={true} />
      <PrintEORModal isOpen={isOpenPrintEOR} onClose={openPrintEOR} />
      <PrintReportsModal
        isOpen={isOpenPrintReports}
        onClose={openPrintReports}
      />
      <PopulateCashApplication
        isOpen={isOpenPopulateCashApplication}
        onClose={openPopulateCashApplication}
      />
      <CareworksTrainingModal isOpen={isOpenCareworksTraining} onClose={openCareworksTraining} />
    </>
  );
};

const Profile = ({
  updateCurrentProfile,
  sendProfileDrodownData,
  userId,
  currentProfile,
  profiles,
  activeCurrentProfile,
}) => {
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const [val, setVal] = useState(currentProfile);
  const changeVal = newVal => {
    updateCurrentProfile(newVal);
  };

  useEffect(() => {
    if (currentProfile) {
      if (
        currentProfile !== activeCurrentProfile &&
        activeCurrentProfile !== ''
      ) {
        setVal(activeCurrentProfile);
      } else {
        setVal(currentProfile);
        const params = {
          currentProfile,
          userId,
        };
        sendProfileDrodownData(params);
      }
    }
  }, [currentProfile, activeCurrentProfile]);

  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
      <Button id="caret" size="sm" onClick={() => toggle()}>
        Profile ( {val} )
      </Button>
      <DropdownToggle caret size="sm" />
      <DropdownMenu md={12}>
        {profiles &&
          profiles.map((profile, id) => {
            return (
              <DropdownItem
                active={val === profile.profileAlias}
                onClick={() => changeVal(profile.profileAlias)}
                key={id}
              >
                {profile.profileAlias}
              </DropdownItem>
            );
          })}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

class HeaderMenu extends Component {
  render() {
    if (this.props.module === ADMIN_MODULE) {
      return null;
    }
    if (this.props.module === FAST_360_MODULE || this.props.module === FAST_360_MAINTENANCE_MODULE) {
      return null;
    }

    return (
      <div className={this.props.floating}>
        <Info />{' '}
        <Options />{' '}
        <Profile
          profiles={this.props.headerProfiles}
          currentProfile={this.props.currentProfile}
          updateCurrentProfile={this.props.updateCurrentProfile}
          sendProfileDrodownData={this.props.sendProfileDrodownData}
          userId={this.props.userId}
          activeCurrentProfile={this.props.activeCurrentProfile}
        />
      </div>
    );
  }
}

HeaderMenu.propTypes = {
  module: PropTypes.string.isRequired,
  //profiles: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func,
  headerProfiles: PropTypes.array,
  currentProfile: PropTypes.string.isRequired,
  updateCurrentProfile: PropTypes.func,
  sendProfileDrodownData: PropTypes.func,
  userId: PropTypes.string.isRequired,
  activeCurrentProfile: PropTypes.string.isRequired,
};

HeaderMenu.defaultProps = {
  //profiles: headerProfiles,
};

const mapStateToProps = state => {
  const billReviewInitialData = billOperationsSelectors.getInitialData(state);
  const user = authSelectors.getUser(state);
  return {
    headerProfiles: billReviewInitialData.profiles,
    currentProfile: billReviewInitialData.currentProfile,
    userId: user && user.user_id,
    activeCurrentProfile: billReviewInitialData.activeCurrentProfile || '',
  };
};

const mapDispatchToProps = dispatch => ({
  updateCurrentProfile: data =>
    dispatch(billOperationsActions.updateCurrentProfile(data)),
  sendProfileDrodownData: data =>
    dispatch(billOperationsOperations.sendProfileDrodownData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenu);
