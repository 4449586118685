import PropTypes from 'prop-types';
// import React, { lazy, Suspense, useState } from 'react';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import IdleTimer from 'react-idle-timer';
//import { fast360Operations } from 'modules/fast-360/store';
import { commonActions, commonSelectors } from 'common/store';

import SideNavMenu from 'core/layout/SideNavMenu';
import MainView from 'core/layout/MainView';

import PrivateRoute from 'shared/components/PrivateRoute';

import AppAlert from 'shared/components/alert/AppAlert';
import AppLoading from 'shared/components/loading/AppLoading';
import '../node_modules/react-big-calendar/lib/css/react-big-calendar.css';
import './App.css';
import TimeOutModal from './modules/auth/TimeOutModal';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { authSelectors } from 'modules/auth/store';
import Administration from 'modules/administration/Administration';
import BillReview from 'modules/bill-review/BillReview';
import Experts from 'modules/experts/Experts';
import Fast360 from 'modules/fast-360/Fast360';
import Fast360Maintenance from 'modules/fast-360-maintenance/Fast360Maintenance';
import { fast360Actions,fast360Operations } from 'modules/fast-360/store/index';
// const Administration = lazy(() =>
//   import('modules/administration/Administration')
// );
// const BillReview = lazy(() => import('modules/bill-review/BillReview'));
// const Experts = lazy(() => import('modules/experts/Experts'));
// const Fast360 = lazy(() => import('modules/fast-360/Fast360'));

const App = ({ activeModule, close, onSwitchMenu, onSwitchModule, onIdle,userMods, logBreadCrumbs, updateIsLogout, resetFilterData, timeoutResult}) => {
  const [idlePopupShow, setIdlePopupShow] = useState(false);
  const { logout, user, userId } = useAuth();
  const userName = user ? `${user.firstname} ${user.lastname}` : 'Unknown User';

//   useEffect(()=>{
//     if(timeoutResult) {
//       onIdle = e => {
//         setIdlePopupShow(true);
//       };
//     }
// }, [timeoutResult]);

  onIdle = e => {
    setIdlePopupShow(true);
  };
  const onClose = () => {
    setIdlePopupShow(false);
  };

//   useEffect(()=>{
//     if(timeoutResult !== null && timeoutResult !== undefined) {
//     const timer = setTimeout(() => {
//     resetFilterData();
//     updateIsLogout(true);
//     logout(userName, userId);
//     localStorage.setItem('auth_token', "");
//     window.location.href = '/login';
//     window.location.reload();
// }, timeoutResult * 1000);

// return()=>{
// clearTimeout(timer);

// }
//     }

// },[timeoutResult]);


useEffect(() => {
  if(timeoutResult !== null && timeoutResult !== undefined) {
  let timerId = null;
  const handleInactive = () => {
  resetFilterData();
  updateIsLogout(true);
  logout(userName, userId);
  localStorage.setItem('auth_token', '');
  window.location.href = '/login';
  window.location.reload();
  };
  
  const resetTimer = () => {
  clearTimeout(timerId);
  timerId = setTimeout(handleInactive, timeoutResult * 1000);
  };
  

  const handleActivity = () => {
  resetTimer();
  };
  
  const events = ['mousemove', 'mousedown', 'mouseup', 'keydown', 'keyup', 'scroll', 'keypress'];
  events.forEach(event => {
  document.addEventListener(event, handleActivity);
  });
  resetTimer();
  return () => {
  clearTimeout(timerId);
  events.forEach(event => {
  document.removeEventListener(event, handleActivity);
  });
  };
}
  }, [timeoutResult]);

  return (
    <div>
      {idlePopupShow ? <TimeOutModal onClose={onClose} updateIsLogout={updateIsLogout} resetFilterData={resetFilterData} />:null}
      <IdleTimer
        element={document}
        onIdle={onIdle}
        debounce={250}
        timeout={2100000}
      />

      <Container fluid>
        <Row>
          <Col style={close ? { marginLeft: '60px' } : { marginLeft: '200px' }}>
            <MainView header={activeModule.name}>
              <Switch>
                <PrivateRoute
                  path="/administration"
                  component={Administration}
                />
                <PrivateRoute path="/bill-review" component={BillReview} />
                <PrivateRoute path="/fast-360" component={Fast360} />
                <PrivateRoute path="/experts" component={Experts} />
                <PrivateRoute path="/fast-360-maintenance" component={Fast360Maintenance} />
                <Route path="/" exact>
                  <Redirect to="/bill-review" />
                </Route>
              </Switch>
            </MainView>
            <SideNavMenu
              activeModule={activeModule}
              switchModule={module => onSwitchModule(module)}
              switchMenu={() => onSwitchMenu()}
              close={close}
              modules={userMods}
              userObject={userMods}
            />
          </Col>
        </Row>
        <AppLoading />
        <AppAlert />
      </Container>
    </div>
  );
};

App.propTypes = {
  activeModule: PropTypes.object.isRequired,
  close: PropTypes.bool,
  onSwitchMenu: PropTypes.func.isRequired,
  onSwitchModule: PropTypes.func.isRequired,
  logBreadCrumbs: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const sideNavMenu = commonSelectors.sideNavMenu(state);
  const user = authSelectors.getUser(state);
  const timeoutResult = authSelectors.getZebraTimeoutResult(state);
  return {
    activeModule: sideNavMenu.activeModule,
    close: sideNavMenu.close,
    userMods: user?.modules ? user.modules : [],
    timeoutResult: timeoutResult
  };
};

const mapDispatchToProps = dispatch => ({
  onSwitchMenu: () => dispatch(commonActions.switchMenu()),
  onSwitchModule: module => dispatch(commonActions.switchModule(module)),
  logBreadCrumbs : (data) =>
  dispatch(fast360Operations.logBreadCrumbs(data)),
  updateIsLogout: (data) => dispatch(fast360Actions.updateIsLogout(data)),
  resetFilterData: (data) => dispatch(fast360Operations.resetFilterData(data))
  
});


export default connect(mapStateToProps, mapDispatchToProps)(App);
