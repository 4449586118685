import { combineReducers } from 'redux';
import {  
  LOAD_VENDOR_MAINTENANCE_LIST, 
  RESET_VENDOR_MAINTENANCE_LIST,
  LOAD_PRIMARY_CONTACT_FLIST,
  RESET_PRIMARY_CONTACT_FLIST,
  LOAD_PRIMARY_CONTACT_LNAME_LIST,
  RESET_PRIMARY_CONTACT_LNAME_LIST,
  SAVE_NEW_CONTACT,
  RESET_NEW_CONTACT,
  SAVE_VENDOR,
  RESET_VENDOR,
  LOAD_VENDOR_MAINTENANCE_DOCS,
  RESET_VENDOR_MAINTENANCE_DOCS,
  UPDATE_VENDOR_MAINTENANCE_DOCS,
  RESET_UPDATE_VENDOR_MAINTENANCE_DOCS,
  LOAD_VENDOR_MAINTENANCE_FILE_DATA,
  RESET_VENDOR_MAINTENANCE_FILE_DATA,
  LOAD_VENDOR_DOCTYPES,
  RESET_VENDOR_DOCTYPES,
  UPDATE_DELETE_VENDOR_DOCUMENT,
  RESET_DELETE_VENDOR_DOCUMENT,
  LOAD_NEW_VENDOR,
  RESET_NEW_VENDOR
} from './constants';

const fast360MaintenanceState = {
    vendorMaintenanceList: [],
    primaryContactFNameList: [],
    primaryContactLNameList: [],
    newContactInfo: undefined,
    vendorInfo: [],
    vendorDocsInfo: [],
    vendorSaveDocInfo: [],
    vendorFileDataInfo: [],
    docTypesInfo: [],
    deleteDocumentInfo: [],
    loadNewVendorInfo: []
}

const fast360MaintenanceReducer = (state = fast360MaintenanceState, action) => {
    switch (action.type) {
case LOAD_VENDOR_MAINTENANCE_LIST:
      return {
        ...state,
        vendorMaintenanceList: action.payload,
      };
    case RESET_VENDOR_MAINTENANCE_LIST:
      return {
        ...state,
        vendorMaintenanceList: [],
      };
      case LOAD_PRIMARY_CONTACT_FLIST:
      return {
        ...state,
        primaryContactFNameList: action.payload,
      };
    case RESET_PRIMARY_CONTACT_FLIST:
      return {
        ...state,
        primaryContactFNameList: [],
      };
      case LOAD_PRIMARY_CONTACT_LNAME_LIST:
      return {
        ...state,
        primaryContactLNameList: action.payload,
      };
    case RESET_PRIMARY_CONTACT_LNAME_LIST:
      return {
        ...state,
        primaryContactLNameList: [],
      };
      case SAVE_NEW_CONTACT:
        return {
          ...state,
          newContactInfo: action.payload,
        };
      case RESET_NEW_CONTACT:
        return {
          ...state,
          newContactInfo: undefined,
        };
        case SAVE_VENDOR:
          return {
            ...state,
            vendorInfo: action.payload,
          };
        case RESET_VENDOR:
          return {
            ...state,
            vendorInfo: [],
          };
          case LOAD_VENDOR_MAINTENANCE_DOCS:
            return {
              ...state,
              vendorDocsInfo: action.payload,
            };
          case RESET_VENDOR_MAINTENANCE_DOCS:
            return {
              ...state,
              vendorDocsInfo: [],
            };
            case UPDATE_VENDOR_MAINTENANCE_DOCS:
            return {
              ...state,
              vendorSaveDocInfo: action.payload,
            };
          case RESET_UPDATE_VENDOR_MAINTENANCE_DOCS:
            return {
              ...state,
              vendorSaveDocInfo: [],
            };
            case LOAD_VENDOR_MAINTENANCE_FILE_DATA:
            return {
              ...state,
              vendorFileDataInfo: action.payload,
            };
          case RESET_VENDOR_MAINTENANCE_FILE_DATA:
            return {
              ...state,
              vendorFileDataInfo: [],
            };
            case LOAD_VENDOR_DOCTYPES:
            return {
              ...state,
              docTypesInfo: action.payload,
            };
          case RESET_VENDOR_DOCTYPES:
            return {
              ...state,
              docTypesInfo: [],
            };
            case UPDATE_DELETE_VENDOR_DOCUMENT:
      return {
        ...state,
        deleteDocumentInfo: action.payload,
      };
      case RESET_DELETE_VENDOR_DOCUMENT:
      return {
        ...state,
        deleteDocumentInfo: [],
      };
      case LOAD_NEW_VENDOR:
        return {
          ...state,
          loadNewVendorInfo: action.payload,
        };
        case RESET_NEW_VENDOR:
        return {
          ...state,
          loadNewVendorInfo: [],
        };
      default:
        return { ...state };
    }
  };
  const reducers = combineReducers({
    fast360MaintenanceReducer: fast360MaintenanceReducer,
  });
  
  export default reducers;