export const ADMIN_MODULE = 'Administration';
export const BILL_REVIEW_MODULE = 'Bill Review';
export const EXPERT_MODULE = 'Experts';
export const FAST_360_MODULE = 'Fast360';
export const FAST_360_MAINTENANCE_MODULE = 'Fast360 Maintenance';

export const DEFAULT_MODULE = ADMIN_MODULE;

export const modules = [
  {
    name: ADMIN_MODULE,
    value: ADMIN_MODULE,
    path: '/administration/search',
    links: [],
  },
  {
    name: BILL_REVIEW_MODULE,
    value: BILL_REVIEW_MODULE,
    path: '/bill-review/user',
    links: [],
  },
  {
    name: FAST_360_MODULE,
    value: FAST_360_MODULE,
    path: '/fast-360/user',
    links: [],
  },
  {
    name: EXPERT_MODULE,
    value: EXPERT_MODULE,
    path: '/experts',
    links: [],
  },
  {
    name: FAST_360_MAINTENANCE_MODULE,
    value: FAST_360_MAINTENANCE_MODULE,
    path: '/fast-360-maintenance',
    links: [],
  },
];

export const getModule = name => {
  return modules.filter(module => {
    return module.name === name;
  })[0];
};

export const getDefaultModule = () => {
  return getModule(DEFAULT_MODULE);
};

export default {
  getDefaultModule,
  getModule,
  modules,
  ADMIN_MODULE,
  BILL_REVIEW_MODULE,
  EXPERT_MODULE,
  FAST_360_MODULE,
  FAST_360_MAINTENANCE_MODULE
};
