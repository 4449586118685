import React, { useEffect, useState } from 'react';
import Fast360TopMenu from '../fast-360/components/Fast360TopMenu';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
  Col,
  FormGroup,
  Row,
  Label
} from 'reactstrap';
import appModules, { FAST_360_MAINTENANCE_MODULE } from 'common/appModules';
import { commonActions } from 'common/store';
import VendorMaintenance from "./components/VendorMaintenance";
const CLIENT_MAINTENANCE_LMS = 'CLIENT_MAINTENANCE_LMS';
const TIER_MAINTENANCE = 'TIER_MAINTENANCE';
const VENDOR_MAINTENANCE = 'VENDOR_MAINTENANCE';
const Fast360Maintenance = ({switchModule}) => {
  useState(() => {
    switchModule(appModules.getModule(FAST_360_MAINTENANCE_MODULE));
  });
    const [isExternalReferral, setIsExternalReferral] = useState(false);
    const [currentView, setCurrentView] = useState('');

    const handleSelectChange = (selectedOption) => {
      setCurrentView(selectedOption? selectedOption.value: null)
    }
   

    const onExternalReferral = () => {
        setIsExternalReferral(true);
      };

      const ClientMaintenanceLMS = () => {
        return <div>Client Maintenance LMS Screen</div>
      }
  
      const TierMaintenance = () => {
        return <div>Tier Maintenance Screen</div>
      }

  const options = [
    { value: CLIENT_MAINTENANCE_LMS, label: 'Client Maintenance LMS' },
    { value: TIER_MAINTENANCE, label: 'Tier Maintenance' },
    { value: VENDOR_MAINTENANCE, label: 'Vendor Maintenance' }
  ]
    return (
        <div>
          <Fast360TopMenu onNewReferral={onExternalReferral} />
          <Row className="aligb-items-center">
              <Col xs="auto" style ={{marginTop: "10px"}}>
              <Label for="maintenanceSelect">Maintenance: </Label>
              </Col>
              <Col>
                <FormGroup style= {{width: "300px"}}>
                <Select
                  value={options.find(option =>option.value === currentView)}
                  name="fast360maintenanceSelect"
                  idKey="fast360maintenanceSelect"
                  onChange={handleSelectChange}
                  placeholder="Choose Maintenance screen"
                  options={options}
                  />
                   </FormGroup>
              </Col>
            </Row>
                  <div>
                    {currentView === CLIENT_MAINTENANCE_LMS && <ClientMaintenanceLMS/>}
                    {currentView === TIER_MAINTENANCE && <TierMaintenance/>}
                    {currentView === VENDOR_MAINTENANCE && <VendorMaintenance/>}
                  </div>
        </div>
      );
     
    };


    const mapStateToProps = state => {
    };

    const mapDispatchToProps = dispatch => ({
      switchModule: module => dispatch(commonActions.switchModule(module)),
    });

    export default connect(mapStateToProps, mapDispatchToProps)(Fast360Maintenance);