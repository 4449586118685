export const MODULE_NAME = 'FAST360 MAINTENANCE';
export const LOAD_VENDOR_MAINTENANCE_LIST = 'LOAD_VENDOR_MAINTENANCE_LIST';
export const RESET_VENDOR_MAINTENANCE_LIST = 'RESET_VENDOR_MAINTENANCE_LIST';
export const LOAD_PRIMARY_CONTACT_FLIST = 'LOAD_PRIMARY_CONTACT_FLIST';
export const RESET_PRIMARY_CONTACT_FLIST = 'RESET_PRIMARY_CONTACT_FLIST';
export const LOAD_PRIMARY_CONTACT_LNAME_LIST = 'LOAD_PRIMARY_CONTACT_LNAME_LIST';
export const RESET_PRIMARY_CONTACT_LNAME_LIST = 'RESET_PRIMARY_CONTACT_LNAME_LIST';
export const SAVE_NEW_CONTACT = 'SAVE_NEW_CONTACT';
export const RESET_NEW_CONTACT = 'RESET_NEW_CONTACT';
export const SAVE_VENDOR = 'SAVE_VENDOR';
export const RESET_VENDOR = 'RESET_VENDOR';
export const LOAD_VENDOR_MAINTENANCE_DOCS = 'LOAD_VENDOR_MAINTENANCE_DOCS';
export const RESET_VENDOR_MAINTENANCE_DOCS = 'RESET_VENDOR_MAINTENANCE_DOCS';
export const LOAD_VENDOR_MAINTENANCE_FILE_DATA = 'LOAD_VENDOR_MAINTENANCE_FILE_DATA';
export const RESET_UPDATE_VENDOR_MAINTENANCE_DOCS = 'RESET_UPDATE_VENDOR_MAINTENANCE_DOCS';
export const RESET_VENDOR_MAINTENANCE_FILE_DATA = 'RESET_VENDOR_MAINTENANCE_FILE_DATA';
export const UPDATE_VENDOR_MAINTENANCE_DOCS = 'UPDATE_VENDOR_MAINTENANCE_DOCS';
export const LOAD_VENDOR_DOCTYPES = 'LOAD_VENDOR_DOCTYPES';
export const RESET_VENDOR_DOCTYPES = 'RESET_VENDOR_DOCTYPES';
export const RESET_DELETE_VENDOR_DOCUMENT = 'RESET_DELETE_VENDOR_DOCUMENT';
export const UPDATE_DELETE_VENDOR_DOCUMENT = 'UPDATE_DELETE_VENDOR_DOCUMENT';
export const LOAD_NEW_VENDOR = 'LOAD_NEW_VENDOR';
export const RESET_NEW_VENDOR = 'RESET_NEW_VENDOR';

export default {
    MODULE_NAME,
  };