import {  
  LOAD_VENDOR_MAINTENANCE_LIST, 
  RESET_VENDOR_MAINTENANCE_LIST,
  LOAD_PRIMARY_CONTACT_FLIST,
  RESET_PRIMARY_CONTACT_FLIST,
  LOAD_PRIMARY_CONTACT_LNAME_LIST,
  RESET_PRIMARY_CONTACT_LNAME_LIST,
  SAVE_NEW_CONTACT,
  RESET_NEW_CONTACT,
  SAVE_VENDOR,
  RESET_VENDOR,
  LOAD_VENDOR_MAINTENANCE_DOCS,
  RESET_VENDOR_MAINTENANCE_DOCS,
  UPDATE_VENDOR_MAINTENANCE_DOCS,
  RESET_UPDATE_VENDOR_MAINTENANCE_DOCS,
  LOAD_VENDOR_MAINTENANCE_FILE_DATA,
  RESET_VENDOR_MAINTENANCE_FILE_DATA,
  LOAD_VENDOR_DOCTYPES,
  RESET_VENDOR_DOCTYPES,
  UPDATE_DELETE_VENDOR_DOCUMENT,
  RESET_DELETE_VENDOR_DOCUMENT,
  LOAD_NEW_VENDOR,
  RESET_NEW_VENDOR
} from './constants';

const loadVendorMaintenanceList = data => {
    return {
      type: LOAD_VENDOR_MAINTENANCE_LIST,
      payload: data
    }
  }

  const resetVendorMaintenanceList = () => {
    return {
      type: RESET_VENDOR_MAINTENANCE_LIST,
    };
  };

  const loadPrimaryContactFList = data => {
    return {
      type: LOAD_PRIMARY_CONTACT_FLIST,
      payload: data
    }
  }

  const resetPrimaryContactFList = () => {
    return {
      type: RESET_PRIMARY_CONTACT_FLIST,
    };
  };

  const loadPrimaryContactLList = data => {
    return {
      type: LOAD_PRIMARY_CONTACT_LNAME_LIST,
      payload: data
    }
  }

  const resetPrimaryContactLList = () => {
    return {
      type: RESET_PRIMARY_CONTACT_LNAME_LIST,
    };
  };

  const saveNewContactAction = data => {
    return {
      type: SAVE_NEW_CONTACT,
      payload: data
    }
  }

  const resetNewContactAction = () => {
    return {
      type: RESET_NEW_CONTACT,
    };
  };

  const saveVendorAction = data => {
    return {
      type: SAVE_VENDOR,
      payload: data
    }
  }

  const resetSaveVendorAction = () => {
    return {
      type: RESET_VENDOR,
    };
  };

  
  const loadVendorMaintenanceDocAction = data => {
    return {
      type: LOAD_VENDOR_MAINTENANCE_DOCS,
      payload: data
    }
  }

  const resetVendorMaintenanceDocAction = () => {
    return {
      type: RESET_VENDOR_MAINTENANCE_DOCS,
    };
  };

  const updateVendorMaintenanceDocAction = data => {
    return {
      type: UPDATE_VENDOR_MAINTENANCE_DOCS,
      payload: data
    }
  }

  const resetUpdatedVendorMaintenanceDocAction = () => {
    return {
      type: RESET_UPDATE_VENDOR_MAINTENANCE_DOCS,
    };
  };

  const loadVendorMaintenanceDocDataAction = data => {
    return {
      type: LOAD_VENDOR_MAINTENANCE_FILE_DATA,
      payload: data
    }
  }

  const resetVendorMaintenanceDocDataAction = () => {
    return {
      type: RESET_VENDOR_MAINTENANCE_FILE_DATA,
    };
  };

  const loadVendorDocTypesAction = data => {
    return {
      type: LOAD_VENDOR_DOCTYPES,
      payload: data
    }
  }

  const resetVendorDocTypesAction = () => {
    return {
      type: RESET_VENDOR_DOCTYPES,
    };
  };

  const updateDeleteVendorDocument = data => {
    return {
      type: UPDATE_DELETE_VENDOR_DOCUMENT,
      payload: data,
    };
  };

  const resetDeleteVendorDocument = data => {
    return {
      type: RESET_DELETE_VENDOR_DOCUMENT,
      payload: data,
    };
  };

  const loadNewVendorAction = data => {
    return {
      type: LOAD_NEW_VENDOR,
      payload: data,
    };
  };

  const resetLoadingNewVendorAction = data => {
    return {
      type: RESET_NEW_VENDOR,
      payload: data,
    };
  };

  export default {
    loadVendorMaintenanceList,
    resetVendorMaintenanceList,
    loadPrimaryContactFList,
    resetPrimaryContactFList,
    loadPrimaryContactLList,
    resetPrimaryContactLList,
    saveNewContactAction,
    resetNewContactAction,
    saveVendorAction,
    resetSaveVendorAction,
    loadVendorMaintenanceDocAction,
    resetVendorMaintenanceDocAction,
    updateVendorMaintenanceDocAction,
    resetUpdatedVendorMaintenanceDocAction,
    loadVendorMaintenanceDocDataAction,
    resetVendorMaintenanceDocDataAction,
    loadVendorDocTypesAction,
    resetVendorDocTypesAction,
    updateDeleteVendorDocument,
    resetDeleteVendorDocument,
    loadNewVendorAction,
    resetLoadingNewVendorAction
  }