import React, { useCallback, useEffect, useRef, useState } from 'react';
import { authSelectors } from 'modules/auth/store';
import * as R from 'ramda';
import { connect } from 'react-redux';
import PopupModal from 'shared/components/PopupModal';
import { Button, CustomInput, Form, Row, Col, Input } from 'reactstrap';
import AppTable from 'shared/components/table/AppTable';
import { commonOperations } from 'common/store';
import moment from 'moment';
import {
  fast360MaintenanceOperations,
  fast360MaintenanceSelectors,
  fast360MaintenanceActions,
} from '../store/index';
import * as NumberUtils from 'shared/utils/numberUtils';
import * as FileUtils from 'shared/utils/fileUtils';

let checkedItems = [];
const VendorMaintenanceFileModal = ({
  isOpen, clicked, loadVendorMaintenanceDocs, vendorDocsInfo, dataObject, userId, vendorItem,
  docTypes,
  updateVendorMaintenanceDocs,
  deleteVendorDocument,
  loadVendorMaintenanceDocData,
  vendorFileDataInfo,
  vendorSaveDocInfo,
  resetUpdatedVendorMaintenanceDocAction,
  alert
}) => {
  const [isOpenAttachFiles, setIsOpenAttachFiles] = useState(false);
  const [checkedVal, setCheckedVal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isOpenUploadFiles, setIsOpenUploadFiles] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [removeVal, setRemoveVal] = useState(false);
  const [filesPayload, setFilesPayload] = useState([]);
  const [fileName, setFileName] = useState('');
  const attachFileRef = useRef();
  useEffect(() => {
    if (isOpen) {
      setIsOpenAttachFiles(true);

      const payload = {
        functionName: 'loadVendorMaintenanceDocuments',
        zebraUserId: userId,
        vendor_id: vendorItem.id,
        tablename: 'vendor'

      };
      loadVendorMaintenanceDocs(payload);
    }
  }, [isOpen])

  useEffect(() => {
    if (vendorSaveDocInfo == "The file has been uploaded successfully") {
      setIsOpenAttachFiles(true);

      const payload = {
        functionName: 'loadVendorMaintenanceDocuments',
        zebraUserId: userId,
        vendor_id: vendorItem.id,
        tablename: 'vendor'

      };
      loadVendorMaintenanceDocs(payload);
      resetUpdatedVendorMaintenanceDocAction();

    }
  }, [vendorSaveDocInfo])

  useEffect(() => {
    if (vendorFileDataInfo?.fileData && vendorFileDataInfo.fileData !== 'Unable to open file!') {
      debugBase64(vendorFileDataInfo.fileData, fileName || 'DownloadedFile');
    }
  }, [vendorFileDataInfo]);

  const debugBase64 = (fileData, fileName) => {
    FileUtils.saveBase64File(fileName, fileData);
  };

  const renderCheckBoxColumn = item => {
    return (
      <div className="select-action">
        {item.bol_active && <CustomInput
          id={item.id}
          name={item.id}
          key={item.id}
          type="checkbox"
          onChange={event => {
            selectCheckBoxHandler(item, event);
          }}
        />}
      </div>
    );
  };

  const selectCheckBoxHandler = (item, event) => {
    checkedItems = event.target.checked
      ? checkedItems.concat(item)
      : checkedItems.filter(row => row.id !== item.id);
    setCheckedVal(checkedItems.length > 0);

  }

  const handleRemoveCheckedFiles = () => {
    setFilesToUpload(prevFiles => prevFiles.filter(file => !file.selected));
    setRemoveVal(false);
  }


  const handleClearAllFiles = () => {
    setFilesToUpload([]);
    setRemoveVal(false);
  }

  const getVendorDocFromTable = item => {
    const fileName = item.path.split('/')[1];
    const desc = item.description;
    loadVendorMaintenanceDocData({
      "functionName": "loadVendorDocumentData",
      "zebraUserId": userId,
      "fileName": fileName,
      "description": desc,
      "path": item.path,

    });
    setFileName(fileName);
  };


  const getAttachFilesBody = () => {
    const activeVendorDocs = vendorDocsInfo.filter(item => item.bol_active);
    return (
      <div>
        <AppTable
          modalHeight={400}
          columns={[
            {
              header: 'Date Added',
              field: 'upload_date',
              render: item => `${moment(item.upload_date).format('L')} ${moment(item.upload_date).format('LT')}`
            },
            {
              header: 'Document Type',
              field: 'type',
            },
            {
              header: 'Description',
              field: 'description',
            },
            {
              header: 'Delete',
              field: '',
              render: item => renderCheckBoxColumn(item),
            },
          ]}
          data={activeVendorDocs}
          autoPagination={true}
          sortAble={true}
          resizable={true}
          sortImmediately={false}
          rowDoubleClickHandler={getVendorDocFromTable}
          key="vendor-main-document-table"
        />
      </div>
    );
  };

  const toggleAttachFiles = () => {
    setIsOpenAttachFiles(!isOpenAttachFiles);
    if (clicked)
      clicked();
  };

  const handleAttachFiles = e => {
    e.preventDefault();
    toggleUploadFiles();
  };


  const toggleUploadFiles = () => {
    setIsOpenUploadFiles(!isOpenUploadFiles);
  };

  const handleDeleteFiles = async () => {
    if (checkedItems.length === 0) {
      return;
    }

    const deletePromises = checkedItems.map(item => {
      const payload = {
        documentId: item.id,
      };
      return deleteVendorDocument(payload);
    });

    try {
      await Promise.all(deletePromises);
      setCheckedVal(false);
      checkedItems = [];
      const payload = {
        functionName: 'loadVendorMaintenanceDocuments',
        zebraUserId: userId,
        vendor_id: vendorItem.id,
        tablename: 'vendor'

      };
      loadVendorMaintenanceDocs(payload);
    } catch (error) {
      console.error("Error deleting files:", error)
    }
  };

  const getAttachFilesFooter = () => {
    if (checkedVal === false) {
      return <Button onClick={handleAttachFiles}>UPLOAD FILE(S)</Button>;
    } else {
      return <Button onClick={handleDeleteFiles}>DELETE FILE(S)</Button>;
    }
  };


  const resetAttachFilesModal = () => {
    setIsOpenAttachFiles(false);
  };

  const getTotalUploadSize = () => {
    let totalBytes = 0;
    for (let i = 0; i < filesToUpload.length; i++) {
      totalBytes += filesToUpload[i].file.size;
    }

    return NumberUtils.formatFileSize(totalBytes);
  };

  const renderColumn = (item, index) => {
    return (
      <div className="select-action">
        <CustomInput
          id={`checkbox-${index}`}
          name={`checkbox-${index}`}
          type="checkbox"
          checked={item.selected || false}
          onChange={event => {
            selectRemoveHandler(index, event.target.checked);
          }}
        />
      </div>
    );
  };

  const selectRemoveHandler = (index, isChecked) => {
    setFilesToUpload(prevFiles => {
      const updatedFiles = [...prevFiles];
      updatedFiles[index] = {
        ...updatedFiles[index],
        selected: isChecked
      }
      return updatedFiles
    })
    if (isChecked == true) {
      setRemoveVal(true);
    }
  }

  const handleSelectUploadFile = e => {
    e.preventDefault();

    const selectedFiles = Array.from(e.target.files);
    const duplicateFiles = selectedFiles.filter(file =>
      filesToUpload.some(uploadFile => uploadFile.file.name === file.name)
    );
    if (duplicateFiles.length) {
      alert("Some files are already uploaded");
      return;
    }
    const keyOffset = filesToUpload.length;
    const filesToAdd = selectedFiles.map((file, index) => ({
      id: keyOffset + index + 1,
      file: file,
      selected: false,
      description: '',
      docType: ''
    }));
    setFilesToUpload(prevFiles => [...prevFiles, ...filesToAdd]);
    attachFileRef.current.value = null;
  };

  const handleFileTypeChange = (index, value) => {
    setFilesToUpload(prevFiles => {
      const updatedFiles = [...prevFiles];
      updatedFiles[index] = {
        ...updatedFiles[index],
        docType: value
      };
      return updatedFiles;
    });
  }

  const handleDescriptionChange = (index, value) => {
    setFilesToUpload(prevFiles => {
      const updatedFiles = [...prevFiles];
      updatedFiles[index] = {
        ...updatedFiles[index],
        description: value
      };
      return updatedFiles;
    });
  };

  const resetFileUploadState = () => {
    setFilesToUpload([]);
    checkedItems = [];
    setCheckedVal(false);
    setRemoveVal(false);
    setIsOpenUploadFiles(false);
  }

  const getUploadFilesBody = () => {
    const filesToUploadCount = filesToUpload.length;
    const filesToUploadLabel =
      filesToUploadCount < 1
        ? ''
        : `Total Files: ${filesToUploadCount}, Total Size: ${getTotalUploadSize()}`;

    return (
      <Form>
        <Row form>
          <Col sm={12}>
            <FileUploadTable
              filesToUpload={filesToUpload}
              handleFileTypeChange={handleFileTypeChange}
              handleDescriptionChange={handleDescriptionChange}
              renderColumn={renderColumn}
              documentTypes={docTypes}
            />
          </Col>
        </Row>
        <Row form>
          <Col sm={6}>
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="attachFile"
                multiple
                accept=".pdf,.jpg,.png,.tif,.gif,.doc,.docx,.xls,.xlsx"
                ref={attachFileRef}
                onChange={handleSelectUploadFile}
              />
              <label className="custom-file-label" htmlFor="attachFile">
                {filesToUploadLabel}
              </label>
            </div>
          </Col>
          <Col sm={6} align="right">
            <Button
              className="file-attachment-remove-button"
              disabled={!removeVal}
              onClick={handleRemoveCheckedFiles}
            >
              REMOVE
            </Button>
            <Button
              className="file-attachment-button"
              disabled={filesToUpload.length < 1}
              onClick={handleClearAllFiles}
            >
              CLEAR ALL
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  const uploadFileHandler = async () => {
    if (filesToUpload?.some(file => file.docType == "")) {
      alert("Error: you must assign a document type to all uploads")
    } else if (filesToUpload?.some(file => file.description == "")) {
      alert("Error: you must assign a document description to all uploads")
    } else {
      Promise.all([
        R.map(async item => {
          const fileData = await FileUtils.fileToBase64(item.file);
          return updateVendorMaintenanceDocs({
            "functionName": "uploadVendorMaintenanceDocuments",
            "zebraUserId": userId,
            "filedescription": item.description,
            "Filename": item.file ? item.file.name : '',
            "filedata": fileData,
            "fileprivate": false,
            "filetype": item.docType,
            "record_id": vendorItem.id,
            "table_name": 'vendor',
            "category": item.docType,
            "logical_name": item.file ? item.file.name : '',
          });
        }, filesToUpload),
      ])
      setIsOpenUploadFiles(false);
      setCheckedVal(false)
    }
  };


  const getUploadFilesFooter = () => {
    return (
      <Button
        className="file-attachment-button"
        disabled={filesToUpload.length < 1}
        onClick={uploadFileHandler}
      >
        UPLOAD
      </Button>
    );
  };


  return (
    <>
      <PopupModal
        content={getAttachFilesBody()}
        title="ADD A FILE"
        externalToggle={() => {
          toggleAttachFiles();
        }}
        isOpen={isOpenAttachFiles}
        footerContent={getAttachFilesFooter()}
        onClosed={resetAttachFilesModal}
        size="lg"
      />
      <PopupModal
        content={getUploadFilesBody()}
        title="ADD A UPLOAD FILE"
        externalToggle={() => {
          setIsOpenUploadFiles(!isOpenUploadFiles)
          checkedItems = [];
          setCheckedVal(false);
          setRemoveVal(false);
        }}
        isOpen={isOpenUploadFiles}
        footerContent={getUploadFilesFooter()}
        onClosed={resetFileUploadState}
        size="lg"
      />
    </>
  )
}

const FileUploadTable = ({ filesToUpload, handleFileTypeChange, handleDescriptionChange, renderColumn, documentTypes }) => {
  const columns =
    [
      {
        header: 'File Name',
        field: 'file.name',
        render: (item) => item.file.name,
        width: '150px',
      },
      {
        header: 'Document Type',
        field: 'docType',
        render: (item, index) => (
          <Input
            type="select"
            value={item.docType}
            onChange={(e) => handleFileTypeChange(index, e.target.value)}
          >
            <option value="" disabled>
              Select Document Type</option>
            {documentTypes.map((type) => (

              <option key={type.id} value={type.id}>
                {type.element}
              </option>
            ))}
          </Input>

        ),
        width: "250px"
      },
      {
        header: 'Description',
        field: 'description',
        render: (item, index) => (
          <Input
            type="text"
            value={item.description}
            onChange={(e) => handleDescriptionChange(index, e.target.value)}
          />
        ),
      },
      {
        header: 'File Size',
        field: 'file.size',
        render: (item) => `${(item.file.size / 1024).toFixed(2)} KB`,
      },
      {
        header: '',
        field: '',
        render: (item, index) => renderColumn(item, index)
      }
    ]
  return (
    <AppTable
      columns={columns}
      data={filesToUpload}
      autoPagination={false}
      sortAble={false}
      resizable={true}
      sortImmediately={false}
      key="vendor-file-table"
    />
  );


};

const mapStateToProps = state => {
  const userId = authSelectors.getUserId(state);
  const fast360MaintenacneInfo = fast360MaintenanceSelectors.getFast360Maintenance(state);
  return {
    userId: userId,
    vendorDocsInfo: fast360MaintenacneInfo.vendorDocsInfo,
    docTypes: fast360MaintenacneInfo.docTypesInfo,
    deleteDocumentInfo: fast360MaintenacneInfo.deleteDocumentInfo,
    vendorFileDataInfo: fast360MaintenacneInfo.vendorFileDataInfo,
    vendorSaveDocInfo: fast360MaintenacneInfo.vendorSaveDocInfo

  };
};

const mapDispatchToProps = dispatch => ({
  loadVendorMaintenanceDocs: (data) => dispatch(fast360MaintenanceOperations.loadVendorMaintenanceDocs(data)),
  updateVendorMaintenanceDocs: (data) => dispatch(fast360MaintenanceOperations.updateVendorMaintenanceDocs(data)),
  deleteVendorDocument: (data) => dispatch(fast360MaintenanceOperations.deleteVendorDocument(data)),
  loadVendorMaintenanceDocData: data => dispatch(fast360MaintenanceOperations.loadVendorMaintenanceDocData(data)),
  resetUpdatedVendorMaintenanceDocAction: () => dispatch(fast360MaintenanceActions.resetUpdatedVendorMaintenanceDocAction()),
  alert: msg => dispatch(commonOperations.addAlert(msg, 'danger')),


});

export default connect(mapStateToProps, mapDispatchToProps)(VendorMaintenanceFileModal);
