import * as R from 'ramda';

import * as ReduxUtils from 'shared/utils/reduxUtils';
import { createSelector } from 'reselect';

import { MODULE_NAME } from './constants';

const getModule = state => state[MODULE_NAME];

const getFast360Maintenance = createSelector(
  getModule,
  state => state.fast360MaintenanceReducer
);

export default {
    getFast360Maintenance,
};
