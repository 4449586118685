import { combineReducers } from 'redux';
import moment from 'moment';
import {
  LOAD_INITIAL_DATA,
  LOAD_CLIENT_DATA,
  UPDATE_DEFAULT_PERCENT,
  RESET_DEFAULT_PERCENT,
  UPDATE_PATIENT_ZIP_LIST,
  RESET_PATIENT_ZIP_LIST,
  UPDATE_SAVE_FEES_DATA,
  RESET_SAVE_FEES_DATA,
  UPDATE_SAVE_COSTS_DATA,
  RESET_SAVE_COSTS_DATA,
  UPDATE_SERVICE_ID_FROM_REF,
  RESET_SERVICE_ID_FROM_REF,
  UPDATE_LOAD_PRODUCT_SEARCH_LIST,
  RESET_LOAD_PRODUCT_SEARCH_LIST,
  UPDATE_LOAD_HCPC_LIST,
  RESET_LOAD_HCPC_LIST,
  UPDATE_LOAD_HCPC_LIST_WITH_LIMIT,
  RESET_LOAD_HCPC_LIST_WITH_LIMIT,
  UPDATE_SAVE_HHC_HCPC,
  RESET_SAVE_HHC_HCPC,
  UPDATE_SAVE_MSE_HCPC,
  RESET_SAVE_MSE_HCPC,
  UPDATE_SAVE_DTL_HCPC,
  RESET_SAVE_DTL_HCPC,
  UPDATE_SAVE_MOD_HCPC,
  RESET_SAVE_MOD_HCPC,
  UPDATE_DETAIL_LINE_FEE_LIST,
  RESET_DETAIL_LINE_FEE_LIST,
  UPDATE_CLIENT_FEE_LIST,
  RESET_CLIENT_FEE_LIST,
  UPDATE_VENDOR_LOOKUP_LIST,
  RESET_VENDOR_LOOKUP_LIST,
  UPDATE_AUD_INFO_LIST,
  RESET_AUD_INFO_LIST,
  UPDATE_TRN_INFO_LIST,
  UPDATE_TRN_DETAILS_LIST,
  UPDATE_DELETED_TRN_LIST,
  UPDATE_DELETED_SPT_LIST,
  UPDATE_SPT_DETAILS_LIST,
  RESET_TRN_INFO_LIST,
  UPDATE_SPT_INFO_LIST,
  RESET_SPT_INFO_LIST,
  UPDATE_SEARCH_CLAIMANT,
  RESET_SEARCH_CLAIMANT,
  UPDATE_LOAD_CLAIMANT_BY_ID,
  RESET_LOAD_CLAIMANT_BY_ID,
  UPDATE_LOAD_CLAIM,
  RESET_LOAD_CLAIM,
  UPDATE_LOAD_DX,
  RESET_LOAD_DX,
  UPDATE_DELETE_ICD,
  RESET_DELETE_ICD,
  UPDATE_DELETE_INJURY,
  RESET_DELETE_INJURY,
  UPDATE_SAVE_CLAIM,
  RESET_SAVE_CLAIM,
  UPDATE_SAVE_REFERRAL,
  RESET_SAVE_REFERRAL,
  UPDATE_SEARCH_REFERRER,
  RESET_SEARCH_REFERRER,
  UPDATE_SELECT_ITEM_REFERRER,
  UPDATE_DATA_FROM_REFERRER,
  UPDATE_SEARCH_ADJUSTER,
  RESET_SEARCH_ADJUSTER,
  UPDATE_LOAD_ADJUSTER,
  RESET_LOAD_ADJUSTER,
  UPDATE_ADJUSTER_INFO,
  RESET_ADJUSTER_INFO,
  UPDATE_SEARCH_EMPLOYER,
  RESET_SEARCH_EMPLOYER,
  UPDATE_LOAD_EMPLOYER,
  RESET_LOAD_EMPLOYER,
  UPDATE_EMPLOYER_INFO,
  RESET_EMPLOYER_INFO,
  UPDATE_SEARCH_PHYSICIAN,
  RESET_SEARCH_PHYSICIAN,
  UPDATE_LOAD_PHYSICIAN,
  RESET_LOAD_PHYSICIAN,
  UPDATE_PHYSICIAN_INFO,
  RESET_PHYSICIAN_INFO,
  UPDATE_SEARCH_ATTORNEY,
  RESET_SEARCH_ATTORNEY,
  UPDATE_LOAD_ATTORNEY,
  RESET_LOAD_ATTORNEY,
  UPDATE_ATTORNEY_INFO,
  RESET_ATTORNEY_INFO,
  UPDATE_SEARCH_CASE_MANAGER,
  RESET_SEARCH_CASE_MANAGER,
  UPDATE_LOAD_CASE_MANAGER,
  UPDATE_ADV_SEARCH,
  UPDATE_LOAD_HCPC_QUICK,
  UPDATE_CLIENT_LOOKUP,
  UPDATE_AUD_HCPC_LIST,
  RESET_AUD_HCPC_LIST,
  RESET_COST_SAVINGS,
  UPDATE_COST_SAVINGS,
  UPDATE_LOOK_UP_SPLOCATION,
  RESET_LOAD_CASE_MANAGER,
  UPDATE_CASE_MANAGER_INFO,
  RESET_CASE_MANAGER_INFO,
  UPDATE_SEARCH_CLIENT,
  UPDATE_CLIENT_INFO,
  RESET_CLIENT_INFO,
  UPDATE_USER_WORK_LIST,
  RESET_SEARCH_CLIENT,
  UPDATE_LOAD_REFERRER,
  RESET_LOAD_REFERRER,
  RESET_LOAD_CLIENT,
  UPDATE_ZIP_LIST,
  RESET_ZIP_LIST,
  UPDATE_REF_INFO,
  RESET_REF_INFO,
  UPDATE_NEW_USER_WORK_LIST,
  UPDATE_CLICK_RECORD,
  UPDATE_GET_NOTES_DATA,
  UPDATE_LOAD_ELIGIBILITY,
  UPDATE_GET_LOAD_NOTES,
  UPDATE_GET_FOLLOWUP_DATA,
  UPDATE_STATUS_CLICKED,
  UPDATE_STATUS_DATA,
  UPDATE_TASK_CLICKED,
  UPDATE_TASK_DATA,
  UPDATE_MILESTONE_CLICKED,
  UPDATE_MILESTONE_DATA,
  UPDATE_PREVIOUS_USER,
  TASK_WORKLIST_BACKUP,
  MILESTONE_WORKLIST_BACKUP,
  STATUS_WORKLIST_BACKUP,
  MILESTONE_AGAIN_CLICKED,
  UPDATE_GET_CANCEL_REASONS,
  UPDATE_GET_EMAIL_ADDRESS,
  UPDATE_GET_EXTERNAL_REFERRAL,
  UPDATE_GET_AP_EXTRACT,
  LOAD_ADVSEARCH_REPORT,
  RESET_ADVSEARCH_REPORT,
  UPDATE_POST_EXTERNAL,
  UPDATE_POST_EXTERNAL_CLIENT,
  UPDATE_GP_DATA,
  UPDATE_LOAD_FROM_WORKLIST,
  UPDATE_LOAD_EXISTING_TASKS,
  UPDATE_LOAD_CURRENT_TASK_ID,
  UPDATE_PRODUCT_CATALOG,
  UPDATE_LOAD_REFERRAL_ALERTS,
  UPDATE_GROUP_PROGRESSION,
  SELECTED_EXTERNAL_DATA,
  UPDATE_LEG_STATUS,
  UPDATE_VENDOR_LOOKUP,
  RESET_VENDOR_LOOKUP,
  SELECT_FROMWORKLIST_SERVICES,
  UPDATE_WORKLIST_EMAIL_REPORT_TEMPLATES,
  QUICK_SEARCH_CHECK,
  MERGE_FILTER_DATA,
  GET_AP_INFO,
  SAVE_AP_INFO,
  SAVE_OBJ,
  UPDATE_HCPC_TERM_CHECK,
  RESET_HCPC_TERM_CHECK,
  SET_CURRENT_WORK_LIST,
  SET_VENDOR_LOOKUP_SUGGESTIONS,
  RESET_VENDOR_LOOKUP_SUGGESTIONS,
  SET_VENDOR_LOOKUP_TOP5BYLOCATION,
  SET_VENDOR_LOOKUP_TOP5BYCOUNT,
  SET_EVALUATE_VENDOR_DATA,
  SET_VENDOR_HISTORY_DATA,
  RESET_VENDOR_LOOKUP_DATA,
  UPDATE_REQUIRED_FIELDS,
  UPDATE_GET_DOCTYPES,
  UPDATE_GET_CC_DOCUMENTS,
  UPDATE_SAVE_FILEUPLOAD,
  UPDATED_FILES_DATA,
  UPDATE_DELETE_DOCUMENT,
  UPDATE_RETRIVE_DOCUMENT,
  UPDATE_UPLOADED_DOCUMENT,
  RESET_UPLOADED_DOCUMENT,
  REQUIRED_SERVICE_DATA,
  UPDATE_GET_CLAIM_AUTH,
  UPDATE_SAVE_AUTH_HISTORY,
  UPDATE_USERS_TOT_ORDERS,
  RESET_USERS_TOT_ORDERS,
  UPDATE_USERS_OOC,
  RESET_USERS_OOC,
  UPDATE_USERS_RUSH,
  RESET_USERS_RUSH,
  UPDATE_NEW_ORDERS,
  RESET_NEW_ORDERS,
  UPDATE_FOLLOWUPS,
  RESET_FOLLOWUPS,
  UPDATE_SAVE_AUTH_SERVICE,
  UPDATE_CLEAR_AUTH_SERVICE,
  UPDATE_GET_AUTH_SERVICE,
  SAVE_FINALIZE_ASSIGNMENT_EMAIL_DATA,
  SELECTED_AUTH_RECORD,
  SET_SELECTED_VENDOR_DATA,
  SAVE_CONFIRM_CONTACT_LIST,
  SAVE_LMS_DOCUMENT_EMAIL,
  SAVE_REPORT_TEMPLATES_BODY,
  SAVE_ATTACHMENT_VALIDATION_RESULT,
  REFERRAL_TRANSFERED,
  CLEAR_REFERRAL_TRANSFERED,
  SET_PRODUCT_DATA,
  SET_SUB_PRODUCT_DATA,
  SET_SELECTED_TYPE,
  DETAIL_EMAIL_SENT,
  RESET_EMAIL_SENT,
  UPDATE_GP_INFO,
  SET_INPUT_FIELDS,
  SET_ELIGIBILITY,
  UPDATE_QUICK_SEARCH_LIST,
  RESET_QUICK_SEARCH_LIST,
  CLEAR_ELIGIBILITY,
  STATUS_BACKUP,
  MILESTONE_BACKUP,
  TASK_BACKUP,
  PRODUCT_BACKUP,
  SUBPRODUCT_BACKUP,
  REQ_FIELDS,
  SAVE_GREAT_PLAINS,
  CLEAR_GREAT_PLAINS,
  TRN_DETAIL_MODAL_DATA,
  TRN_TABLE_DETAILS,
  SPT_SAVE_DATA,
  TRN_SAVE_DATA,
  SPT_TABLE_DETAILS,
  SAVE_RENTAL_SERVICE_REFERRAL,
  SET_LAST_SERVICE_MODULE,
  CLEAR_ATTACHMENT_VALIDATION_RESULT,
  SET_VENDOR_DATA,
  SET_SPT_DETAILS,
  SET_TRN_DETAILS,
  SET_LAST_SUB_SERVICE_MODULE,
  UPDATE_DETAIL_PROVIDER,
  UPDTAE_FOLLOWUP_DATE,
  FOLLOW_UP_CHANGE_EVENT,
  FOLLOW_UP_DATE_EVENT,
  UPDATE_LINE_ITEMS_LIST,
  UPDATE_LINE_ITEM_DETAILS,
  UPDATE_PAYABLE_LINES,
  SET_SELECTED_FILTER_TYPE,
  SET_ADV_VALUES,
  UPDATE_TOGGLE_VALUES,
  UPDATE_INVOICE_TASK_DATA,
  IS_LOGOUT,
  APPROVAL_DENIAL_REASONS,
  SKIP_PAYABLE_LINES,
  SET_SELECTED_INVOICE_COORDINATOR_TABLE,
  SET_SELECTED_INVOICE_COORDINATOR_USER,
  RESET_NPPES_PHYSICIANS_LIST,
  SET_NPPES_PHYSICIANS_LIST,
  VM_PROVIDER_TYPES,
  VM_GROUPS,
  UPDATE_VM_RESULTS,
  RESET_PROVIDER_RESULTS,
} from './constants';

const initialDefaultState = {
  workTasks: [],
  workStatuses: [],
  milestones: [],
  docTypes: [],
  referrerType: [],
  ReferralSourceList: [],
  accommodationType: [],
  attorneyType: [],
  diagnosticType: [],
  appointmentType: [],
  translationCategory: [],
  workStatus: [],
  causes: [],
  translationCertification: [],
  injuredBodyPart: [],
  injuryNature: [],
  hhcCategory: [],
  language: [],
  timeZone: [],
  states: [],
  modificationCategory: [],
  homeType: [],
  bodyType: [],
  homeStructure: [],
  mseCategory: [],
  relationShipType: [],
  pgoVendorList: [],
  transportCategory: [],
  specialTransportCategory: [],
  placeOfServiceTypes: [],
  brTypes: [],
  loadLegStatus: {
    TransportCategory: [],
    ServiceStatus: [],
    TripType: [],
    LegStatus: [],
    ReturnType: [],
  },
  usersTotList: undefined,
  usersOOCList: undefined,
  usersRushList: undefined,
  usersNewList: undefined,
  usersFollowUpList: undefined,
};

const initialReducer = (state = initialDefaultState, action) => {
  switch (action.type) {
    case LOAD_INITIAL_DATA: {
      return {
        ...state,
        workTasks: action.payload.workTasks,
        workStatuses: action.payload.workStatuses,
        milestones: action.payload.milestones,
        docTypes: action.payload.docTypes,
        outcomes: action.payload.referralCombo.BillStatus,
        status: action.payload.referralSearch.NewServiceStatus,
        tasks: action.payload.referralSearch.Tasks,
        milestone: action.payload.referralSearch.MileStones,
        referrerType: action.payload.referrerType,
        language: action.payload.referralCombo.Language,
        timeZone: action.payload.referralCombo.TimeZones,
        states: action.payload.referralCombo.States,
        ReferralSourceList: action.payload.referralCombo.ReferralSourceList,
        gpIntegrationList: action.payload.referralSearch,
        roleUsers: action.payload.roleUsers,
        initialWorkList: action.payload.userWorklist,
        accommodationType: action.payload.referralCombo.AccommodationType,
        attorneyType: action.payload.referralCombo.AttorneyTypes,
        diagnosticType: action.payload.referralCombo.DiagnosticType,
        appointmentType: action.payload.referralCombo.AppointmentType,
        translationCategory: action.payload.referralCombo.TranslationCategory,
        transportCategory: action.payload.referralCombo.TransportCategory,
        specialTransportCategory:
          action.payload.referralCombo.SpecialTransportCategory,
        workStatus: action.payload.referralCombo.WorkStatus,
        causes: action.payload.referralCombo.Cause,
        translationCertification:
          action.payload.referralCombo.TranslationCertification,
        injuredBodyPart: action.payload.referralCombo.InjuredBodyPart,
        injuryNature: action.payload.referralCombo.InjuryNature,
        hhcCategory: action.payload.referralCombo.HHCCategory,
        modificationCategory: action.payload.referralCombo.ModificationCategory,
        homeType: action.payload.referralCombo.homeType,
        bodyType: action.payload.referralCombo.bodyType,
        homeStructure: action.payload.referralCombo.homeStructure,
        mseCategory: action.payload.referralCombo.MSECategory,
        relationShipType: action.payload.referralCombo.RelationShipType,
        pgoVendorList: action.payload.referralCombo.pgoVendorList,
        brTypes: action.payload.referralCombo.BRTypes,
        cmbBillReason: action.payload.referralCombo.BillReason,
        cmbAltBillReason: action.payload.referralCombo.AltBillReason,
        cmbMissedBillReason: action.payload.referralCombo.MissedBillReason,
        placeOfServiceTypes: action.payload.referralCombo.placeOfServiceTypes,
        authCloseReasons: action.payload.referralCombo.authCloseReasons,
      };
    }
    case UPDATE_USER_WORK_LIST:
      return {
        ...state,
        initialWorkList: action.payload,
        backupWorkList: action.payload,
      };
    case UPDATE_NEW_USER_WORK_LIST:
      return {
        ...state,
        initialWorkList: action.payload,
      };
    case UPDATE_USERS_TOT_ORDERS:
      return {
        ...state,
        initialWorkList: action.payload,
        usersTotList: action.payload
      };
    case RESET_USERS_TOT_ORDERS:
      return {
        ...state,
        usersTotList: undefined,
      };
    case UPDATE_USERS_OOC:
      const usersOOC = action.payload.filter(o => moment(o.currentTaskCreateDate
      ).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD'))
      return {
        ...state,
        initialWorkList: usersOOC,
        usersOOCList: usersOOC
      };
    case RESET_USERS_OOC:
      return {
        ...state,
        usersOOCList: undefined,
      };
    case UPDATE_USERS_RUSH:
      const usersRush = action.payload.filter(o => o.bol_rush === true || o.chr_priority === 'High' || o.serviceRush === 1)
      return {
        ...state,
        initialWorkList: usersRush,
        usersRushList: usersRush
      }
    case RESET_USERS_RUSH:
      return {
        ...state,
        usersRushList: undefined,
      };
    case UPDATE_NEW_ORDERS:
      const newOrderTime = new Date().getTime() - (1000 * 60 * 60 * 24);
      const newOrders = action.payload.filter(o => new Date(o.currentTaskCreateDate).getTime() > newOrderTime);
      return {
        ...state,
        initialWorkList: newOrders,
        usersNewList: newOrders
      }
    case RESET_NEW_ORDERS:
      return {
        ...state,
        usersNewList: undefined,
      };
    case UPDATE_FOLLOWUPS:

      const followupTime = new Date().getTime();
      const followups = action.payload.filter(o => o.FollowupDate && new Date(o.FollowupDate).getTime() > followupTime);
      return {
        ...state,
        initialWorkList: followups,
        usersFollowUpList: followups
      }
    case RESET_FOLLOWUPS:
      return {
        ...state,
        usersFollowUpList: undefined,
      };
    case UPDATE_ADV_SEARCH:
      return {
        ...state,
        loadStateTypes: action.payload,
      };
    case UPDATE_LOAD_HCPC_QUICK:
      return {
        ...state,
        loadHCPCLookUp: action.payload,
      };
    case UPDATE_CLIENT_LOOKUP:
      return {
        ...state,
        loadClientLookUp: action.payload,
      };
    case UPDATE_LOOK_UP_SPLOCATION:
      return {
        ...state,
        loadlookUpLocation: action.payload,
      };
    case UPDATE_LEG_STATUS:
      return {
        ...state,
        loadLegStatus: action.payload,
      };
    default:
      return { ...state };
  }
};

const fast360State = {
  patientZip: undefined,
  defaultPercent: '',
  productSearchList: [],
  hcpcList: undefined,
  feesInfo: undefined,
  costsInfo: undefined,
  serviceIdFromRef: undefined,
  hcpcListLimit: [],
  hcpcCheck: '',
  hhcHcpcList: undefined,
  mseHcpcList: undefined,
  dtlHcpcList: undefined,
  modHcpcList: undefined,
  detailLineFeeInfo: {},
  clientFeeInfo: {},
  vendorList: {},
  audInfo: {},
  trnDetailsList: [],
  sptDetailsList: [],
  referrerInfo: undefined,
  clientInfo: [],
  claimantInfo: undefined,
  loadClaimantByIdInfo: {},
  loadClaimInfo: {},
  quickSearchList: undefined,
  loadDXInfo: {},
  deleteIcdInfo: {},
  deleteInjuryInfo: {},
  updatedClaimInfo: {},
  updatedReferralInfo: {},
  loadRefInfo: {},
  loadAdjusterInfo: {},
  adjusterInfo: undefined,
  updatedAdjusterInfo: {},
  loadEmployerInfo: {},
  employerInfo: undefined,
  updatedEmployerInfo: {},
  loadPhysicianInfo: {},
  physicianInfo: undefined,
  updatedPhysicianInfo: undefined,
  loadAttorneyInfo: {},
  attorneyInfo: undefined,
  updatedAttorneyInfo: undefined,
  loadCaseManagerInfo: {},
  caseManagerInfo: undefined,
  updatedCaseManagerInfo: {},
  updateAudHcpcList: undefined,
  updateCostSavingList: undefined,
  updateSelectedItemRef: {},
  updateDataFromRef: {},
  zipList: [],
  updatedRefInfo: {},
  updatedPayorInfo: undefined,
  updateClickRecord: [],
  notesData: {},
  eligibilityData: {},
  loadNotesData: {},
  followupData: {},
  statusClicked: false,
  updatedStatusData: [],
  taskClicked: false,
  updateTaskData: [],
  milestoneClicked: false,
  updateMilestoneData: [],
  updatePreviousUser: '',
  TaskWorkListBackup: [],
  milestoneWorkListBackup: [],
  statusWorkListBackup: [],
  milestoneAgainClicked: false,
  updateGetCancelReasons: [],
  advSearchReportData: [],
  mergeFilterData: [],
  updateGetEmailAddress: [],
  updateGetExternalReferral: [],
  updateGetAPExtract: [],
  updatePostExternal: [],
  updatePostExternalClient: [],
  gpData: {},
  updateLoadFromWorkList: {},
  updateloadExistingTasks: {},
  updateloadReferralAlerts: {},
  updateloadGroupProgression: {},
  updateWorklistEmailReportTemplates: [],
  selectedExternalData: {},
  currentTaskId: 0,
  selectedWorklistFromLanding: {},
  quickSearchCheck: {},
  getAPInfo: {},
  currentWorkListItem: {},
  vendorLookupSuggestions: {},
  vendorLookupByTop5ByLocation: {},
  vendorLookupByTop5ByCount: {},
  updateRequiredFields: [],
  updateGetDocTypes: {},
  updateGetCcDocument: {},
  updateSaveFileUpload: {},
  updatedFilesData: [],
  updateDeleteDocument: {},
  retrieveDocument: {},
  uploadedDocuments: [],
  requiredServiceData: {},
  getClaimAuth: [],
  saveAuthHistory: {},
  saveAuthService: {},
  clearAuthService: {},
  getAuthService: {},
  finalizeEmailAssignmentData: [],
  selectedAuthRecord: { index: -1, state: false },
  selectedVendorData: [],
  confirmWithContactList: {},
  lmsDocumentsEmail: [],
  reportTemplatesBody: {},
  attachmentValidationResult: [],
  referralTransfered: false,
  selectedProduct: '',
  selectedSubProduct: '',
  selectedType: '',
  detailEmailSent: false,
  detailEmailRespnse: false,
  gpInfo: [],
  inputField: '',
  isEligible: true,
  statusBackUp: [],
  mileStoneBackUp: [],
  taskBackUp: [],
  productBackUp: [],
  subProductBackUp: [],
  reqFields: [],
  greatPlainsSaved: false,
  trnDetailModaldata: {},
  trnTableDetails: [],
  sptSaveinfo: true,
  trnsaveInfo: true,
  sptTableDetails: [],
  greatPlainsSaved: false,
  rentalServiceReferral: [],
  lastServiceModule: '',
  setVendorData: { selectedSubService: {}, flag: false },
  sptData: [],
  trnData: [],
  lastSubServiceModule: '',
  updateDetailProvider: {},
  trnDeletedList: [],
  sptDeletedList: [],
  updateFollowUpDates: {},
  followupChangeEvent: false,
  followupDateEvent: false,
  lineItemsList: [],
  lineItemDetails: [],
  payableLinesList: [],
  selectedFilterType: '',
  setAdvValues: '',
  updateToggleValues: false,
  invoiceTaskData: [],
  updateIsLogout: '',
  approvalDenialReasons: {},
  skipPayableLines: true,
  selectedInvoiceCoordinatorTable: '',
  selectedInvoiceCoordinatorUser: '',
  nppesPhysicianList: [],

};
const fast360Reducer = (state = fast360State, action) => {
  switch (action.type) {
    case RESET_VENDOR_LOOKUP_DATA:
      return {
        ...state,
        vendorLookupSuggestions: {},
        vendorLookupByTop5ByLocation: {},
        vendorLookupByTop5ByCount: {}
      }
    case SET_VENDOR_LOOKUP_SUGGESTIONS:
      return {
        ...state,
        vendorLookupSuggestions: action.payload
      }

    case RESET_VENDOR_LOOKUP_SUGGESTIONS:
      return {
        ...state,
        vendorLookupSuggestions: {}
      };
    case SET_VENDOR_LOOKUP_TOP5BYLOCATION:
      return {
        ...state,
        vendorLookupByTop5ByLocation: action.payload
      }
    case SET_VENDOR_LOOKUP_TOP5BYCOUNT:
      return {
        ...state,
        vendorLookupByTop5ByCount: action.payload,
      };
    case SET_EVALUATE_VENDOR_DATA:
      return {
        ...state,
        evaluateVendorData: action.payload,
      };
    case SET_VENDOR_DATA:
      return {
        ...state,
        setVendorData: action.payload,
      };

    case SET_SPT_DETAILS:
      return {
        ...state,
        sptData: action.payload,
      };

    case SET_TRN_DETAILS:
      return {
        ...state,
        trnData: action.payload,
      };



    case SET_VENDOR_HISTORY_DATA:
      return {
        ...state,
        vendorHistoryData: action.payload,
      };
    case UPDATE_PATIENT_ZIP_LIST:
      return {
        ...state,
        patientZip: action.payload,
      };
    case RESET_PATIENT_ZIP_LIST:
      return {
        ...state,
        patientZip: undefined,
      };
    case UPDATE_AUD_HCPC_LIST:
      return {
        ...state,
        updateAudHcpcList: action.payload,
      };
    case RESET_AUD_HCPC_LIST:
      return {
        ...state,
        updateAudHcpcList: undefined,
      };
    case UPDATE_COST_SAVINGS:
      return {
        ...state,
        updateCostSavingList: action.payload,
      };
    case RESET_COST_SAVINGS:
      return {
        ...state,
        updateCostSavingList: undefined,
      };
    case UPDATE_DEFAULT_PERCENT:
      return {
        ...state,
        defaultPercent: action.payload,
      };
    case RESET_DEFAULT_PERCENT:
      return {
        ...state,
        defaultPercent: '',
      };
    case UPDATE_SAVE_FEES_DATA:
      return {
        ...state,
        feesInfo: action.payload,
      };
    case RESET_SAVE_FEES_DATA:
      return {
        ...state,
        feesInfo: undefined,
      };
    case UPDATE_SAVE_COSTS_DATA:
      return {
        ...state,
        costsInfo: action.payload,
      };
    case RESET_SAVE_COSTS_DATA:
      return {
        ...state,
        costsInfo: undefined,
      };
    case UPDATE_SERVICE_ID_FROM_REF:
      return {
        ...state,
        serviceIdFromRef: action.payload,
      };
    case RESET_SERVICE_ID_FROM_REF:
      return {
        ...state,
        serviceIdFromRef: undefined,
      };
    case UPDATE_LOAD_PRODUCT_SEARCH_LIST:
      return {
        ...state,
        productSearchList: action.payload,
      };
    case RESET_LOAD_PRODUCT_SEARCH_LIST:
      return {
        ...state,
        productSearchList: [],
      };
    case UPDATE_LOAD_HCPC_LIST:
      return {
        ...state,
        hcpcList: action.payload,
      };
    case RESET_LOAD_HCPC_LIST:
      return {
        ...state,
        hcpcList: undefined,
      };
    case UPDATE_LOAD_HCPC_LIST_WITH_LIMIT:
      return {
        ...state,
        hcpcListLimit: action.payload,
      };
    case RESET_LOAD_HCPC_LIST_WITH_LIMIT:
      return {
        ...state,
        hcpcListLimit: undefined,
      };
    case UPDATE_QUICK_SEARCH_LIST:
      return {
        ...state,
        quickSearchList: action.payload,
      };
    case RESET_QUICK_SEARCH_LIST:
      return {
        ...state,
        quickSearchList: undefined,
      };
    case UPDATE_HCPC_TERM_CHECK:
      return {
        ...state,
        hcpcCheck: action.payload,
      };
    case RESET_HCPC_TERM_CHECK:
      return {
        ...state,
        hcpcCheck: '',
      };
    case UPDATE_SAVE_HHC_HCPC:
      return {
        ...state,
        hhcHcpcList: action.payload,
      };
    case RESET_SAVE_HHC_HCPC:
      return {
        ...state,
        hhcHcpcList: '',
      };
    case UPDATE_SAVE_MSE_HCPC:
      return {
        ...state,
        mseHcpcList: action.payload,
      };
    case RESET_SAVE_MSE_HCPC:
      return {
        ...state,
        mseHcpcList: '',
      };
    case UPDATE_SAVE_DTL_HCPC:
      return {
        ...state,
        dtlHcpcList: action.payload,
      };
    case RESET_SAVE_DTL_HCPC:
      return {
        ...state,
        dtlHcpcList: '',
      };
    case UPDATE_SAVE_MOD_HCPC:
      return {
        ...state,
        modHcpcList: action.payload,
      };
    case RESET_SAVE_MOD_HCPC:
      return {
        ...state,
        modHcpcList: '',
      };
    case UPDATE_DETAIL_LINE_FEE_LIST:
      return {
        ...state,
        detailLineFeeInfo: action.payload,
      };
    case RESET_DETAIL_LINE_FEE_LIST:
      return {
        ...state,
        detailLineFeeInfo: {},
      };
    case UPDATE_CLIENT_FEE_LIST:
      return {
        ...state,
        clientFeeInfo: action.payload,
      };
    case RESET_CLIENT_FEE_LIST:
      return {
        ...state,
        clientFeeInfo: {},
      };
    case UPDATE_VENDOR_LOOKUP_LIST:
      return {
        ...state,
        vendorList: action.payload,
      };
    case RESET_VENDOR_LOOKUP_LIST:
      return {
        ...state,
        vendorList: {},
      };
    case UPDATE_AUD_INFO_LIST:
      return {
        ...state,
        audInfo: action.payload,
      };
    case RESET_AUD_INFO_LIST:
      return {
        ...state,
        audInfo: {},
      };
    case UPDATE_TRN_INFO_LIST:
      return {
        ...state,
        trnDetailsList: [...state.trnDetailsList, ...action.payload],
      };
    case UPDATE_TRN_DETAILS_LIST:
      return {
        ...state,
        trnDetailsList: [...action.payload],
      };
    case UPDATE_DELETED_TRN_LIST:
      return {
        ...state,
        trnDeletedList: [...state.trnDeletedList, action.payload],
      };
    case UPDATE_DELETED_SPT_LIST:
      return {
        ...state,
        sptDeletedList: [...state.sptDeletedList, action.payload],
      };
    case UPDATE_SPT_DETAILS_LIST:
      return {
        ...state,
        sptDetailsList: [...action.payload],
      };
    case RESET_TRN_INFO_LIST:
      return {
        ...state,
        trnDetailsList: [],
      };
    case UPDATE_SPT_INFO_LIST:
      return {
        ...state,
        sptDetailsList: [...state.sptDetailsList, ...action.payload],
      };
    case RESET_SPT_INFO_LIST:
      return {
        ...state,
        sptDetailsList: [],
      };
    case UPDATE_SEARCH_CLAIMANT:
      return {
        ...state,
        claimantInfo: action.payload,
      };
    case RESET_SEARCH_CLAIMANT:
      return {
        ...state,
        claimantInfo: undefined,
      };
    case UPDATE_LOAD_CLAIMANT_BY_ID:
      return {
        ...state,
        loadClaimantByIdInfo: action.payload,
      };
    case RESET_LOAD_CLAIMANT_BY_ID:
      return {
        ...state,
        loadClaimantByIdInfo: {},
      };
    case UPDATE_LOAD_CLAIM:
      return {
        ...state,
        loadClaimInfo: action.payload,
      };
    case RESET_LOAD_CLAIM:
      return {
        ...state,
        loadClaimInfo: {},
      };
    case UPDATE_LOAD_DX:
      return {
        ...state,
        loadDXInfo: action.payload,
      };
    case RESET_LOAD_DX:
      return {
        ...state,
        loadDXInfo: {},
      };
    case UPDATE_DELETE_ICD:
      return {
        ...state,
        deleteIcdInfo: action.payload,
      };
    case RESET_DELETE_ICD:
      return {
        ...state,
        deleteIcdInfo: {},
      };
    case UPDATE_DELETE_INJURY:
      return {
        ...state,
        deleteInjuryInfo: action.payload,
      };
    case RESET_DELETE_INJURY:
      return {
        ...state,
        deleteInjuryInfo: {},
      };
    case UPDATE_SAVE_CLAIM:
      return {
        ...state,
        updatedClaimInfo: action.payload,
      };
    case RESET_SAVE_CLAIM:
      return {
        ...state,
        updatedClaimInfo: {},
      };
    case UPDATE_SAVE_REFERRAL:
      return {
        ...state,
        updatedReferralInfo: action.payload,
      };
    case RESET_SAVE_REFERRAL:
      return {
        ...state,
        updatedReferralInfo: {},
      };
    case UPDATE_SEARCH_REFERRER:
      return {
        ...state,
        referrerInfo: action.payload,
      };
    case UPDATE_SELECT_ITEM_REFERRER:
      return {
        ...state,
        updateSelectedItemRef: action.payload,
      };
    case UPDATE_DATA_FROM_REFERRER:
      return {
        ...state,
        updateDataFromRef: action.payload,
      };
    case UPDATE_SEARCH_CLIENT:
      return {
        ...state,
        clientInfo: action.payload,
      };
    case RESET_SEARCH_REFERRER:
      return {
        ...state,
        referrerInfo: undefined,
      };
    case UPDATE_SEARCH_ADJUSTER:
      return {
        ...state,
        adjusterInfo: action.payload,
      };
    case RESET_SEARCH_ADJUSTER:
      return {
        ...state,
        adjusterInfo: undefined,
      };
    case UPDATE_LOAD_ADJUSTER:
      return {
        ...state,
        loadAdjusterInfo: action.payload,
      };
    case RESET_LOAD_ADJUSTER:
      return {
        ...state,
        loadAdjusterInfo: {},
      };
    case UPDATE_ADJUSTER_INFO:
      return {
        ...state,
        updatedAdjusterInfo: action.payload,
      };
    case RESET_ADJUSTER_INFO:
      return {
        ...state,
        updatedAdjusterInfo: {},
      };
    case UPDATE_SEARCH_EMPLOYER:
      return {
        ...state,
        employerInfo: action.payload,
      };
    case RESET_SEARCH_EMPLOYER:
      return {
        ...state,
        employerInfo: undefined,
      };
    case UPDATE_LOAD_EMPLOYER:
      return {
        ...state,
        loadEmployerInfo: action.payload,
      };
    case RESET_LOAD_EMPLOYER:
      return {
        ...state,
        loadEmployerInfo: {},
      };
    case UPDATE_EMPLOYER_INFO:
      return {
        ...state,
        updatedEmployerInfo: action.payload,
      };
    case RESET_EMPLOYER_INFO:
      return {
        ...state,
        updatedEmployerInfo: {},
      };
    case UPDATE_SEARCH_PHYSICIAN:
      return {
        ...state,
        physicianInfo: action.payload,
      };
    case RESET_SEARCH_PHYSICIAN:
      return {
        ...state,
        physicianInfo: undefined,
      };
    case UPDATE_LOAD_PHYSICIAN:
      return {
        ...state,
        loadPhysicianInfo: action.payload,
      };
    case RESET_LOAD_PHYSICIAN:
      return {
        ...state,
        loadPhysicianInfo: {},
      };
    case UPDATE_PHYSICIAN_INFO:
      return {
        ...state,
        updatedPhysicianInfo: action.payload,
      };
    case RESET_PHYSICIAN_INFO:
      return {
        ...state,
        updatedPhysicianInfo: undefined,
      };
    case UPDATE_SEARCH_ATTORNEY:
      return {
        ...state,
        attorneyInfo: action.payload,
      };
    case RESET_SEARCH_ATTORNEY:
      return {
        ...state,
        attorneyInfo: undefined,
      };
    case UPDATE_LOAD_ATTORNEY:
      return {
        ...state,
        loadAttorneyInfo: action.payload,
      };
    case RESET_LOAD_ATTORNEY:
      return {
        ...state,
        loadAttorneyInfo: {},
      };
    case UPDATE_ATTORNEY_INFO:
      return {
        ...state,
        updatedAttorneyInfo: action.payload,
      };
    case RESET_ATTORNEY_INFO:
      return {
        ...state,
        updatedAttorneyInfo: undefined,
      };
    case UPDATE_SEARCH_CASE_MANAGER:
      return {
        ...state,
        caseManagerInfo: action.payload,
      };
    case RESET_SEARCH_CASE_MANAGER:
      return {
        ...state,
        caseManagerInfo: undefined,
      };
    case UPDATE_LOAD_CASE_MANAGER:
      return {
        ...state,
        loadCaseManagerInfo: action.payload,
      };
    case RESET_LOAD_CASE_MANAGER:
      return {
        ...state,
        loadCaseManagerInfo: {},
      };
    case UPDATE_CASE_MANAGER_INFO:
      return {
        ...state,
        updatedCaseManagerInfo: action.payload,
      };
    case RESET_CASE_MANAGER_INFO:
      return {
        ...state,
        updatedCaseManagerInfo: {},
      };
    case RESET_SEARCH_CLIENT:
      return {
        ...state,
        clientInfo: [],
      };
    case UPDATE_LOAD_REFERRER:
      return {
        ...state,
        loadRefInfo: action.payload,
      };
    case RESET_LOAD_REFERRER:
      return {
        ...state,
        loadRefInfo: {},
      };
    case RESET_LOAD_CLIENT:
      return {
        ...state,
        loadClientInfo: {},
      };
    case UPDATE_ZIP_LIST:
      return {
        ...state,
        zipList: action.payload,
      };
    case RESET_ZIP_LIST:
      return {
        ...state,
        zipList: [],
      };
    case UPDATE_REF_INFO:
      return {
        ...state,
        updatedRefInfo: action.payload,
      };
    case UPDATE_CLIENT_INFO:
      return {
        ...state,
        updatedPayorInfo: action.payload,
      };
    case RESET_CLIENT_INFO:
      return {
        ...state,
        updatedPayorInfo: undefined,
      };
    case UPDATE_CLICK_RECORD:
      return {
        ...state,
        updateClickRecord: action.payload,
      };
    case LOAD_CLIENT_DATA:
      return {
        ...state,
        updatedPayorInfo: action.payload,
      };
    case RESET_REF_INFO:
      return {
        ...state,
        updatedRefInfo: {},
      };
    case UPDATE_GET_NOTES_DATA:
      return {
        ...state,
        notesData: action.payload,
      };
    case UPDATE_LOAD_ELIGIBILITY:
      return {
        ...state,
        eligibilityData: action.payload,
      };

    case UPDATE_GET_LOAD_NOTES:
      return {
        ...state,
        loadNotesData: action.payload,
      };
    case UPDATE_GET_FOLLOWUP_DATA:
      return {
        ...state,
        followupData: action.payload,
      };
    case UPDATE_STATUS_CLICKED:
      return {
        ...state,
        statusClicked: action.payload,
      };
    case UPDATE_STATUS_DATA:
      return {
        ...state,
        updatedStatusData: action.payload,
      };
    case UPDATE_TASK_CLICKED:
      return {
        ...state,
        taskClicked: action.payload,
      };
    case UPDATE_TASK_DATA:
      return {
        ...state,
        updateTaskData: action.payload,
      };
    case UPDATE_MILESTONE_CLICKED:
      return {
        ...state,
        milestoneClicked: action.payload,
      };
    case UPDATE_MILESTONE_DATA:
      return {
        ...state,
        updateMilestoneData: action.payload,
      };

    case SET_SELECTED_TYPE:
      return {
        ...state,
        selectedType: action.payload,
      };

    case UPDATE_PREVIOUS_USER:
      return {
        ...state,
        updatePreviousUser: action.payload,
      };
    case TASK_WORKLIST_BACKUP:
      return {
        ...state,
        TaskWorkListBackup: action.payload,
      };
    case MILESTONE_WORKLIST_BACKUP:
      return {
        ...state,
        milestoneWorkListBackup: action.payload,
      };
    case STATUS_WORKLIST_BACKUP:
      return {
        ...state,
        statusWorkListBackup: action.payload,
      };
    case STATUS_BACKUP:
      return {
        ...state,
        statusBackUp: action.payload,
      };
    case MILESTONE_BACKUP:
      return {
        ...state,
        mileStoneBackUp: action.payload,
      };
    case TASK_BACKUP:
      return {
        ...state,
        taskBackUp: action.payload,
      };
    case PRODUCT_BACKUP:
      return {
        ...state,
        productBackUp: action.payload,
      };
    case SUBPRODUCT_BACKUP:
      return {
        ...state,
        subProductBackUp: action.payload,
      };

    case REQ_FIELDS:
      return {
        ...state,
        reqFields: action.payload,
      };

    case MILESTONE_AGAIN_CLICKED:
      return {
        ...state,
        milestoneAgainClicked: action.payload,
      };
    case UPDATE_GET_CANCEL_REASONS:
      return {
        ...state,
        updateGetCancelReasons: action.payload,
      };
    case LOAD_ADVSEARCH_REPORT:
      return {
        ...state,
        advSearchReportData: action.payload,
      };
    case MERGE_FILTER_DATA:
      return {
        ...state,
        mergeFilterData: action.payload,
      };
    case RESET_ADVSEARCH_REPORT:
      return {
        ...state,
        advSearchReportData: [],
        mergeFilterData: [],
      };
    case UPDATE_GET_EMAIL_ADDRESS:
      return {
        ...state,
        updateGetEmailAddress: action.payload,
      };
    case UPDATE_GET_EXTERNAL_REFERRAL:
      return {
        ...state,
        updateGetExternalReferral: action.payload,
      };
    case UPDATE_GET_AP_EXTRACT:
      return {
        ...state,
        updateGetAPExtract: action.payload,
      };
    case UPDATE_POST_EXTERNAL:
      return {
        ...state,
        updatePostExternal: action.payload,
      };
    case UPDATE_POST_EXTERNAL_CLIENT:
      return {
        ...state,
        updatePostExternalClient: action.payload,
      };
    case UPDATE_GP_DATA:
      return {
        ...state,
        gpData: action.payload,
      };

    case UPDATE_GP_INFO:
      return {
        ...state,
        gpInfo: action.payload,
      };


    case UPDATE_LOAD_FROM_WORKLIST:
      return {
        ...state,
        updateLoadFromWorkList: action.payload,
      };
    case UPDATE_SAVE_FILEUPLOAD:
      return {
        ...state,
        updateSaveFileUpload: action.payload,
      };

    case UPDATED_FILES_DATA:
      return {
        ...state,
        updatedFilesData: action.payload,
      };


    case UPDATE_DELETE_DOCUMENT:
      return {
        ...state,
        updateDeleteDocument: action.payload,
      };

    case UPDATE_RETRIVE_DOCUMENT:
      return {
        ...state,
        retrieveDocument: action.payload,
      };
    case UPDATE_UPLOADED_DOCUMENT:
      return {
        ...state,
        uploadedDocuments: [...state.uploadedDocuments, ...action.payload],
      };
    case RESET_UPLOADED_DOCUMENT:
      return {
        ...state,
        uploadedDocuments: [],
      };
  

    case UPDATE_GET_CLAIM_AUTH:
      return {
        ...state,
        getClaimAuth: action.payload,
      };

    case SELECTED_AUTH_RECORD:
      return {
        ...state,
        selectedAuthRecord: action.payload,
      };

    case UPDATE_SAVE_AUTH_HISTORY:
      return {
        ...state,
        saveAuthHistory: action.payload,
      };


    case UPDATE_SAVE_AUTH_SERVICE:
      return {
        ...state,
        saveAuthService: action.payload,
      };

    case UPDATE_CLEAR_AUTH_SERVICE:
      return {
        ...state,
        clearAuthService: action.payload,
      };
    case UPDATE_GET_AUTH_SERVICE:
      return {
        ...state,
        getAuthService: action.payload,
      };


    case REQUIRED_SERVICE_DATA:
      return {
        ...state,
        requiredServiceData: action.payload,
      };


    case UPDATE_GET_DOCTYPES:
      return {
        ...state,
        updateGetDocTypes: action.payload,
      };
    case UPDATE_GET_CC_DOCUMENTS:
      return {
        ...state,
        updateGetCcDocument: action.payload,
      };
    case SET_PRODUCT_DATA:
      return {
        ...state,
        selectedProduct: action.payload,
      };

    case SET_INPUT_FIELDS:
      return {
        ...state,
        inputField: action.payload,
      };

    case SET_SUB_PRODUCT_DATA:
      return {
        ...state,
        selectedSubProduct: action.payload,
      };

    case UPDATE_LOAD_EXISTING_TASKS:
      return {
        ...state,
        updateloadExistingTasks: action.payload,
      };
    case UPDATE_LOAD_CURRENT_TASK_ID:
      return {
        ...state,
        updateloadCurrentTaskId: action.payload,
      };
    case UPDATE_PRODUCT_CATALOG:
       return {
          ...state,
          productCatalog: action.payload,
        };
    case UPDATE_LOAD_REFERRAL_ALERTS:
      return {
        ...state,
        updateloadReferralAlerts: action.payload,
      };
    case UPDATE_REQUIRED_FIELDS:
      return {
        ...state,
        updateRequiredFields: action.payload,
      };

    case UPDATE_GROUP_PROGRESSION:
      return {
        ...state,
        updateloadGroupProgression: action.payload,
      };
    case SELECTED_EXTERNAL_DATA:
      return {
        ...state,
        selectedExternalData: action.payload,
      };
    case SELECT_FROMWORKLIST_SERVICES:
      return {
        ...state,
        selectedWorklistFromLanding: action.payload,
      };
    case UPDATE_WORKLIST_EMAIL_REPORT_TEMPLATES:
      return {
        ...state,
        updateWorklistEmailReportTemplates: action.payload,
      };
    case QUICK_SEARCH_CHECK:
      return {
        ...state,
        quickSearchCheck: action.payload,
      };
    case FOLLOW_UP_CHANGE_EVENT:
      return {
        ...state,
        followupChangeEvent: action.payload,
      };

    case FOLLOW_UP_DATE_EVENT:
      return {
        ...state,
        followupDateEvent: action.payload,
      };
    case GET_AP_INFO:
      return {
        ...state,
        getAPInfo: action.payload,
      };
    case SAVE_AP_INFO:
      return {
        ...state,
        saveAPInfo: action.payload,
      };
    case SAVE_OBJ:
      return {
        ...state,
        saveObj: action.payload,
      };
    case SET_CURRENT_WORK_LIST:
      return {
        ...state,
        currentWorkListItem: action.payload,
      }
    case SAVE_FINALIZE_ASSIGNMENT_EMAIL_DATA:
      return {
        ...state,
        finalizeEmailAssignmentData: action.payload

      }
    case SET_SELECTED_VENDOR_DATA:
      return {
        ...state,
        selectedVendorData: action.payload

      }
    case SAVE_CONFIRM_CONTACT_LIST:
      return {
        ...state,
        confirmWithContactList: action.payload

      }
    case SAVE_LMS_DOCUMENT_EMAIL:
      return {
        ...state,
        lmsDocumentsEmail: action.payload

      }
    case SAVE_REPORT_TEMPLATES_BODY:
      return {
        ...state,
        reportTemplatesBody: action.payload
      }
    case SAVE_ATTACHMENT_VALIDATION_RESULT:
      return {
        ...state,
        attachmentValidationResult: action.payload
      }

    case CLEAR_ATTACHMENT_VALIDATION_RESULT:
      return {
        ...state,
        attachmentValidationResult: []
      }
    case REFERRAL_TRANSFERED:
      return {
        ...state,
        referralTransfered: true
      }
    case CLEAR_REFERRAL_TRANSFERED:
      return {
        ...state,
        referralTransfered: false
      }

    case DETAIL_EMAIL_SENT:
      return {
        ...state,
        detailEmailSent: action.payload,
        detailEmailRespnse: true
      }

    case RESET_EMAIL_SENT:
      return {
        ...state,
        detailEmailSent: false,
        detailEmailRespnse: false
      }
    case SET_ELIGIBILITY:
      return {
        ...state,
        isEligible: action.payload
      }
    case CLEAR_ELIGIBILITY:
      return {
        ...state,
        isEligible: true
      }
    case SAVE_GREAT_PLAINS:
      return {
        ...state,
        greatPlainsSaved: true
      }
    case CLEAR_GREAT_PLAINS:
      return {
        ...state,
        greatPlainsSaved: false
      }
    case TRN_DETAIL_MODAL_DATA:
      return {
        ...state,
        trnDetailModaldata: action.payload
      }

    case TRN_TABLE_DETAILS:
      return {
        ...state,
        trnTableDetails: action.payload
      }
    case SPT_SAVE_DATA:
      return {
        ...state,
        sptSaveinfo: action.payload
      }
    case TRN_SAVE_DATA:
      return {
        ...state,
        trnsaveInfo: action.payload
      }



    case SPT_TABLE_DETAILS:
      return {
        ...state,
        sptTableDetails: action.payload
      }



    case SAVE_RENTAL_SERVICE_REFERRAL:
      return {
        ...state,
        rentalServiceReferral: action.payload
      }

    case SET_LAST_SERVICE_MODULE:
      return {
        ...state,
        lastServiceModule: action.payload
      }

    case SET_LAST_SUB_SERVICE_MODULE:
      return {
        ...state,
        lastSubServiceModule: action.payload
      }
    case UPDATE_DETAIL_PROVIDER:
      return {
        ...state,
        updateDetailProvider: action.payload
      }

    case UPDTAE_FOLLOWUP_DATE:
      return {
        ...state,
        updateFollowUpDates: action.payload
      }
    case SET_SELECTED_FILTER_TYPE:
      return {
        ...state,
        selectedFilterType: action.payload,
      };

    case SET_ADV_VALUES:
      return {
        ...state,
        setAdvValues: action.payload,
      };
    case UPDATE_TOGGLE_VALUES:
      return {
        ...state,
        updateToggleValues: action.payload,
      };

    case IS_LOGOUT:
      return {
        ...state,
        updateIsLogout: action.payload,
      };

    case UPDATE_LINE_ITEMS_LIST:
      return {
        ...state,
        lineItemsList: action.payload
      }

    case UPDATE_LINE_ITEM_DETAILS:
      return {
        ...state,
        lineItemDetails: action.payload
      }

    case UPDATE_PAYABLE_LINES:
      return {
        ...state,
        payableLinesList: action.payload
      }

    case UPDATE_INVOICE_TASK_DATA:
      return {
        ...state,
        invoiceTaskData: action.payload
      }

    case APPROVAL_DENIAL_REASONS:
      return {
        ...state,
        approvalDenialReasons: action.payload
      }
      case SKIP_PAYABLE_LINES:
        return {
          ...state,
          skipPayableLines: action.payload
        }
    case SET_SELECTED_INVOICE_COORDINATOR_TABLE:
      return {
        ...state,
        selectedInvoiceCoordinatorTable: action.payload
      }
    case SET_SELECTED_INVOICE_COORDINATOR_USER:
      return {
        ...state,
        selectedInvoiceCoordinatorUser: action.payload
      }
    case SET_NPPES_PHYSICIANS_LIST:
      return {
        ...state,
        nppesPhysicianList: action.payload
      }
    case RESET_NPPES_PHYSICIANS_LIST:
      return {
        ...state,
        nppesPhysicianList: []
      }
  
    default:
      return { ...state };
  }
};

const vendorLookupInitialState = {
  miles: [],
  services: [],
  subServices: [],
};

const vendorLookupReducer = (state = vendorLookupInitialState, action) => {
  switch (action.type) {
    case UPDATE_VENDOR_LOOKUP:
      return {
        ...state,
        ...action.payload,
      };

    case RESET_VENDOR_LOOKUP:
      return vendorLookupInitialState;

    default:
      return { ...state };
  }
};

const vendorMaintenanceInitialState = {
  vmProviderTypes: [],
  vmGroups: [],
  vmResults: [],
};

const vendorMaintenanceReducer = (state = vendorMaintenanceInitialState, action) => {
  switch (action.type) {
    case VM_PROVIDER_TYPES:
      return {
        ...state,
        vmProviderTypes: action.payload,
      };

      case VM_GROUPS:
      return {
        ...state,
        vmGroups: action.payload,
      };

      case UPDATE_VM_RESULTS:
        return {
          ...state,
          vmResults: action.payload,
        };
        
      case RESET_PROVIDER_RESULTS:
        return {
            ...state,
            vmResults: [],
        };

    default:
      return { ...state };
  }
};

const reducers = combineReducers({
  initialData: initialReducer,
  fast360Reducer: fast360Reducer,
  vendorLookup: vendorLookupReducer,
  vendorMaintenance: vendorMaintenanceReducer,
});

export default reducers;
