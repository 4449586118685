import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  fast360Operations, fast360Actions
} from '../store';
import { Modal, ModalHeader, ModalBody, CustomInput } from 'reactstrap';
import moment from 'moment';
import { Col, Row, Label, Form, FormGroup, Input, Button } from 'reactstrap';
import { isEmpty } from 'shared/utils/typeUtils';

const Fast360Reason = ({
  isOpen,
  reasonList,
  onClose,
  setOpen,
  selectedOutcome,
  propsPayload,
  postrequiredFileds,
  updateRequiredFields,
  updateRequiredFieldsCall,
  onChangeOutcomeDefault,
  postCancelService,
  postOutcomes,
  postCancelServiceUpdateOutcomes,
  worklistData,
  onWorklistExit,
  refreshCall,
  selectedItem,
  setSelectedItem,
}) => {
  const [openReason, setopenReason] = useState(false);
  const [intCurrentReason, setCurrentReason] = useState(0);
  const [saveClicked, setSaveClicked] = useState(false);
  const [closedClicked, setClosedClicked] = useState(false);
  const [cancelOutcome, setCancelOutcome] = useState(false);

  useEffect(() => {
    if (isOpen) {
      var newDate
      newDate = Date();
      // setTaskNote(currentTaskData[currentTaskId - 1].description +  ' was closed on ' +  moment(newDate).format('MM/DD/YYYY h:mm a'))
      //setServicesDefault();
      setopenReason(true);
    } else {
      setopenReason(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (updateRequiredFields.length >  0 ) {
      if (updateRequiredFields[0] === 'close'){
        closeReasonSuccess();
      }
    }
  }, [updateRequiredFields]);


  const closeReason = () => {
    setopenReason(!openReason);
    setOpen(false);
    updateRequiredFieldsCall([]);
    setSaveClicked(false);
    selectedItem.outcomeId = 39; //39 is the id of the default outcome, "referral"
    selectedItem.reasonId = 0;
    setSelectedItem(selectedItem);
    onChangeOutcomeDefault({ target: { value: 'status_id' } });
    setClosedClicked(false);
    setCancelOutcome(false);
  };

  const closeReasonSuccess = () => {
    const { serviceId, module } = selectedItem
    const { currentUserId } = worklistData
    setopenReason(!openReason);
    setOpen(false);
    selectedItem.reasonId = intCurrentReason;
    setSelectedItem(selectedItem);
   // setParentCurrentReason(intCurrentReason)
    updateRequiredFieldsCall([]);
    setSaveClicked(true);
    setClosedClicked(false);
    const payload2 = {
      "functionName": "updateOutcomes",
      "zebraUserId": currentUserId,
      "outcomeId": Number(selectedOutcome),
      "reasonId": intCurrentReason,
      "refTable": module,
      "serviceId": serviceId
    }
    postOutcomes(payload2);
    refreshCall();
  };

  const onChangeReason = event => {
    const target = event.target;
    setCurrentReason(target.value);
  }

  const saveReason = (outcomeId) => {
    setSaveClicked(true);
    setCancelOutcome(false);
    if (outcomeId === 52 || outcomeId === 1050)
      setCancelOutcome(true);
  }
  const getTableFromMod = (serviceAbrv) => {
    if (serviceAbrv === "HHC") {
      return "hhc";
    } else if (serviceAbrv === "AUD") {
      return "audiology";
    } else if (serviceAbrv === "DTL") {
      return "dental";
    } else if (serviceAbrv === "DGN") {
      return "diagnostics";
    } else if (serviceAbrv === "MOD") {
      return "modifications";
    } else if (serviceAbrv === "MSE") {
      return "mse";
    } else if (serviceAbrv === "TRN") {
      return "transportation";
    } else if (serviceAbrv === "INT") {
      return "interpretation";
    } else if (serviceAbrv === "SPT") {
      return "special_transp";
    } else if (serviceAbrv === "ACC") {
      return "accommodation";
    }
    return "";
  };

  const handleOptions = (opt) => {
    if (opt === 'YES') {
      let payload = {
        "functionName": "checkRequiredFields",
        "referralId": selectedItem.referralId,
        "tablename": getTableFromMod(selectedItem.module),
        "serviceId": selectedItem.serviceId,
        "zebraUserId": propsPayload.currentUserId
      }
      postrequiredFileds(payload);
      //setTimeout(() => {
      //  closeReason();
      //}, 2000);
      
      //setopenReason(!openReason);
    }
    if (opt === 'NO') {
      closeReason();
    }
  }

  const openCloseModal = () => {
    setClosedClicked(true);
    setCancelOutcome(false);
  }

  const renderHeadingText = () => {
    let text = '';
    if (!saveClicked && !closedClicked) {
      text = 'select reason';
    }
    if (saveClicked && updateRequiredFields.length == 0)
      text = 'Confirmation';
    return text;
  }

  const handleCancelSave = () => {
    const { currentUserId, referralId, serviceId, tableName } = worklistData
    /*
    const payload1 = {
      "functionName": "cancelService",
      "zebraUserId": currentUserId,
      "referralId": referralId,
      "refTable": selectedItem.module,
      "serviceId": selectedItem.serviceId
    }
    const payload2 = {
      "functionName": "updateOutcomes",
      "zebraUserId": currentUserId,
      "outcomeId": Number(selectedOutcome),
      "reasonId": intCurrentReason,
      "refTable": selectedItem.module,
      "serviceId": selectedItem.serviceId
    }
    postCancelService(payload1);
    postOutcomes(payload2);
    */
    const payload = {
      "functionName": "updateOutcomeCancelService",
      "zebraUserId": currentUserId,
      "outcomeId": Number(selectedOutcome),
      "reasonId": intCurrentReason,
      "referralId": referralId,
      "refTable": selectedItem.module,
      "serviceId": selectedItem.serviceId
    }
    postCancelServiceUpdateOutcomes(payload);
    onWorklistExit();
    refreshCall();
  }

  return (
    <>
      <Modal size="md" isOpen={openReason} >
        <ModalHeader toggle={(saveClicked && !closedClicked) ? closeReason : ((!saveClicked && closedClicked) ? closeReason : openCloseModal)} >
          {renderHeadingText()}
        </ModalHeader>
        <ModalBody>
          {!saveClicked && !closedClicked && !cancelOutcome && <>
            <Row style={{ marginTop: '10px' }}>
              <Col sm={12} style={{ marginTop: '6px' }}> Please select a reason.</Col>
            </Row>
            <Row style={{ marginTop: '5px' }}>
              <Col sm="12" style={{ marginTop: '12px' }}>
                <Input id="Reason US" type="select" onChange={e => onChangeReason(e)}
                  option={{
                    value: 'status_id',
                    displayValue: 'value',
                  }}
                  placeholder="Reason"
                  value={intCurrentReason}
                  data={reasonList}
                >
                  <option value="default">Reason</option>
                  {reasonList.map((id, index) => (
                    <option key={index} value={id.status_id}>
                      {id.value}
                    </option>
                  ))}
                </Input>
              </Col>
            </Row>
            <Form>
              <div style={{ float: 'right', marginTop: '5px' }}>
                {Number(selectedOutcome) !== 53 && Number(selectedOutcome) !== 52 && Number(selectedOutcome) !== 1050 && <Button style={{ marginLeft: 5 }}
                  type="button"
                  onClick={() => saveReason()}
                >
                  Skip
                </Button>}
                {(Number(selectedOutcome) === 53 || Number(selectedOutcome) === 52 || Number(selectedOutcome) === 1050) && <Button style={{ marginLeft: 5 }}
                  type="button"
                  onClick={() => closeReason()}
                >
                  Cancel
                </Button>}
                <Button style={{ marginLeft: 5 }}
                  type="button"
                  onClick={() => saveReason(Number(selectedOutcome))}
                  disabled={intCurrentReason == 0}
                >
                  Save
                </Button>
              </div>

            </Form>
          </>}
          {!saveClicked && closedClicked &&
            <>
              <Row style={{ marginTop: '10px' }}>
                <Col sm={12} style={{ marginTop: '6px' }}>A reason is required in order to select this outcome.
                Outcome will be reset.</Col>
              </Row>
              <Form>
                <div style={{ float: 'right', marginTop: '5px' }}>
                  <Button style={{ marginLeft: 5 }}
                    type="button"
                    onClick={() => {
                      closeReason();
                    }}>OK</Button>

                </div>
              </Form>
            </>
          }
          {saveClicked && !cancelOutcome && updateRequiredFields.length === 0 &&
            <>
              <Row style={{ marginTop: '10px' }}>
                <Col sm={12} style={{ marginTop: '6px' }}>Do you want to send this service to the Invoice Coordinator? </Col>
              </Row>
              <Form>
                <div style={{ float: 'right', marginTop: '5px' }}>
                  <Button style={{ marginLeft: 5 }}
                    type="button"
                    onClick={() => handleOptions('NO')}>NO</Button>
                  <Button style={{ marginLeft: 5 }}
                    type="button"
                    onClick={() => handleOptions('YES')}>YES</Button>
                </div>
              </Form>
            </>
          }
          {saveClicked && !cancelOutcome && updateRequiredFields.length > 0 &&
            <>
              <Row style={{ marginTop: '10px' }}>
                <Col sm={12} style={{ marginTop: '6px' }}>The following fields are required to transfer the service to Invoice Coordinator:</Col>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                {updateRequiredFields.map(item => <Row style={{ margin: '5px', width: '100%' }}><b>{item}</b></Row>)}
              </Row>
              {Number(selectedOutcome) === 183 && <Row style={{ marginTop: '10px' }}>
                <Col sm={12} style={{ marginTop: '6px' }}>Tasks still need to be completed before progressing this service to the Invoice Coordinator</Col>
              </Row>}
              <Form>
                <div style={{ float: 'right', marginTop: '5px' }}>
                  <Button style={{ marginLeft: 5 }}
                    type="button"
                    onClick={() => {
                      handleOptions('NO');
                      setCurrentReason(39);
                      // onChangeOutcomeDefault({ target: { value: 'status_id' } });
                    }}>OK</Button>

                </div>
              </Form>
            </>
          }
          {saveClicked && cancelOutcome &&
            <>
              <Row style={{ marginTop: '10px' }}>
                <Col sm={12} style={{ marginTop: '6px' }}>
                  {Number(selectedOutcome) === 52 ? 'Are you sure you want to cancel the selected services?' : 'Are you sure you want to close the selected services as Missed?'}</Col>
              </Row>
              <Form>
                <div style={{ float: 'right', marginTop: '5px' }}>

                  <Button style={{ marginLeft: 5 }}
                    type="button"
                    onClick={() => {
                      handleOptions('NO');
                      setCancelOutcome(false);
                    }}>NO</Button>
                  <Button style={{ marginLeft: 5 }}
                    type="button"
                    onClick={() => {
                      handleOptions('NO');
                      setCurrentReason(0);
                      setCancelOutcome(false);
                      handleCancelSave();
                      // onChangeOutcomeDefault({ target: { value: 'status_id' } });
                    }}>YES</Button>

                </div>
              </Form>
            </>}
        </ModalBody>
      </Modal >

    </>
  );
};

Fast360Reason.propTypes = {
  progressTasks: PropTypes.func,
  loadExistingTasks: PropTypes.func,
  groupTasksProgression: PropTypes.func,
  loadFromWorkList: PropTypes.func,
};


const mapStateToProps = state => {
  return {
    lmsUserId: state.FAST360.initialData.roleUsers ? state.FAST360.initialData.roleUsers.LmsUserId : '',
    currentTaskData: state.FAST360.fast360Reducer.updateloadExistingTasks ? state.FAST360.fast360Reducer.updateloadExistingTasks : [],
    groupTasksProgression: state.FAST360.fast360Reducer.updateloadGroupProgression ? state.FAST360.fast360Reducer.updateloadGroupProgression : [],
    updateRequiredFields: state.FAST360.fast360Reducer.updateRequiredFields ? state.FAST360.fast360Reducer.updateRequiredFields : [],

  };
};

const mapDispatchToProps = dispatch => ({
  progressTasks: (data) => dispatch(fast360Operations.progressTasks(data)),
  loadFromWorkList: (data) => dispatch(fast360Operations.progressTasks(data)),
  loadExistingTasks: (data) => dispatch(fast360Operations.loadExistingTasks(data)),
  groupTasksProgression: (data) => dispatch(fast360Operations.groupTasksProgression(data)),
  postrequiredFileds: data => dispatch(fast360Operations.postrequiredFileds(data)),
  updateRequiredFieldsCall: data => dispatch(fast360Actions.updateRequiredFields(data)),
  postCancelService: (data) => dispatch(fast360Operations.postCancelService(data)),
  postOutcomes: (data) => dispatch(fast360Operations.postOutcomes(data)),
  postCancelServiceUpdateOutcomes: (data) => dispatch(fast360Operations.postCancelServiceUpdateOutcomes(data))
});


export default connect(mapStateToProps, mapDispatchToProps)(Fast360Reason);
