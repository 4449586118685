import actionCreatorUtils from 'core/utils/actionCreatorUtils';
import {
  LOAD_INITIAL_DATA,
  LOAD_CLIENT_DATA,
  UPDATE_DEFAULT_PERCENT,
  RESET_DEFAULT_PERCENT,
  UPDATE_PATIENT_ZIP_LIST,
  RESET_PATIENT_ZIP_LIST,
  UPDATE_LOAD_PRODUCT_SEARCH_LIST,
  RESET_LOAD_PRODUCT_SEARCH_LIST,
  UPDATE_LOAD_HCPC_LIST,
  UPDATE_QUICK_SEARCH_LIST,
  RESET_QUICK_SEARCH_LIST,
  UPDATE_SAVE_FEES_DATA,
  RESET_SAVE_FEES_DATA,
  UPDATE_SAVE_COSTS_DATA,
  RESET_SAVE_COSTS_DATA,
  UPDATE_SERVICE_ID_FROM_REF,
  RESET_SERVICE_ID_FROM_REF,
  RESET_LOAD_HCPC_LIST,
  UPDATE_LOAD_HCPC_LIST_WITH_LIMIT,
  RESET_LOAD_HCPC_LIST_WITH_LIMIT,
  UPDATE_HCPC_TERM_CHECK,
  RESET_HCPC_TERM_CHECK,
  UPDATE_SAVE_HHC_HCPC,
  RESET_SAVE_HHC_HCPC,
  UPDATE_SAVE_MSE_HCPC,
  RESET_SAVE_MSE_HCPC,
  UPDATE_SAVE_DTL_HCPC,
  RESET_SAVE_DTL_HCPC,
  UPDATE_SAVE_MOD_HCPC,
  RESET_SAVE_MOD_HCPC,
  UPDATE_DETAIL_LINE_FEE_LIST,
  RESET_DETAIL_LINE_FEE_LIST,
  UPDATE_CLIENT_FEE_LIST,
  RESET_CLIENT_FEE_LIST,
  UPDATE_VENDOR_LOOKUP_LIST,
  RESET_VENDOR_LOOKUP_LIST,
  UPDATE_AUD_INFO_LIST,
  RESET_AUD_INFO_LIST,
  UPDATE_TRN_INFO_LIST,
  UPDATE_TRN_DETAILS_LIST,
  UPDATE_SPT_DETAILS_LIST,
  UPDATE_DELETED_TRN_LIST,
  UPDATE_DELETED_SPT_LIST,
  RESET_TRN_INFO_LIST,
  UPDATE_SPT_INFO_LIST,
  RESET_SPT_INFO_LIST,
  RESET_SEARCH_REFERRER,
  RESET_SEARCH_CLIENT,
  UPDATE_SEARCH_CLAIMANT,
  RESET_SEARCH_CLAIMANT,
  UPDATE_LOAD_CLAIMANT_BY_ID,
  RESET_LOAD_CLAIMANT_BY_ID,
  UPDATE_LOAD_CLAIM,
  RESET_LOAD_CLAIM,
  UPDATE_LOAD_DX,
  RESET_LOAD_DX,
  UPDATE_DELETE_ICD,
  RESET_DELETE_ICD,
  UPDATE_DELETE_INJURY,
  RESET_DELETE_INJURY,
  UPDATE_SAVE_CLAIM,
  RESET_SAVE_CLAIM,
  UPDATE_SAVE_REFERRAL,
  RESET_SAVE_REFERRAL,
  UPDATE_ADV_SEARCH,
  UPDATE_LEG_STATUS,
  UPDATE_LOAD_HCPC_QUICK,
  UPDATE_LOOK_UP_SPLOCATION,
  UPDATE_SEARCH_REFERRER,
  UPDATE_SELECT_ITEM_REFERRER,
  UPDATE_DATA_FROM_REFERRER,
  UPDATE_SEARCH_ADJUSTER,
  RESET_SEARCH_ADJUSTER,
  UPDATE_LOAD_ADJUSTER,
  RESET_LOAD_ADJUSTER,
  UPDATE_ADJUSTER_INFO,
  RESET_ADJUSTER_INFO,
  UPDATE_SEARCH_EMPLOYER,
  RESET_SEARCH_EMPLOYER,
  UPDATE_LOAD_EMPLOYER,
  RESET_LOAD_EMPLOYER,
  UPDATE_EMPLOYER_INFO,
  RESET_EMPLOYER_INFO,
  UPDATE_SEARCH_PHYSICIAN,
  RESET_SEARCH_PHYSICIAN,
  UPDATE_LOAD_PHYSICIAN,
  RESET_LOAD_PHYSICIAN,
  UPDATE_PHYSICIAN_INFO,
  RESET_PHYSICIAN_INFO,
  UPDATE_SEARCH_ATTORNEY,
  RESET_SEARCH_ATTORNEY,
  UPDATE_LOAD_ATTORNEY,
  RESET_LOAD_ATTORNEY,
  UPDATE_ATTORNEY_INFO,
  RESET_ATTORNEY_INFO,
  UPDATE_SEARCH_CASE_MANAGER,
  RESET_SEARCH_CASE_MANAGER,
  UPDATE_LOAD_CASE_MANAGER,
  RESET_LOAD_CASE_MANAGER,
  UPDATE_CASE_MANAGER_INFO,
  RESET_CASE_MANAGER_INFO,
  UPDATE_SEARCH_CLIENT,
  UPDATE_LOAD_REFERRER,
  RESET_LOAD_REFERRER,
  RESET_LOAD_CLIENT,
  UPDATE_ZIP_LIST,
  RESET_ZIP_LIST,
  UPDATE_REF_INFO,
  UPDATE_CLIENT_INFO,
  RESET_CLIENT_INFO,
  RESET_REF_INFO,
  UPDATE_USER_WORK_LIST,
  UPDATE_NEW_USER_WORK_LIST,
  UPDATE_CLICK_RECORD,
  UPDATE_GET_NOTES_DATA,
  UPDATE_LOAD_ELIGIBILITY,
  UPDATE_GET_LOAD_NOTES,
  UPDATE_GET_FOLLOWUP_DATA,
  UPDATE_STATUS_CLICKED,
  UPDATE_STATUS_DATA,
  UPDATE_TASK_CLICKED,
  UPDATE_TASK_DATA,
  UPDATE_MILESTONE_CLICKED,
  UPDATE_MILESTONE_DATA,
  UPDATE_PREVIOUS_USER,
  TASK_WORKLIST_BACKUP,
  MILESTONE_WORKLIST_BACKUP,
  STATUS_WORKLIST_BACKUP,
  MILESTONE_AGAIN_CLICKED,
  UPDATE_GET_CANCEL_REASONS,
  UPDATE_GET_EMAIL_ADDRESS,
  UPDATE_GET_EXTERNAL_REFERRAL,
  UPDATE_GET_AP_EXTRACT,
  LOAD_ADVSEARCH_REPORT,
  RESET_ADVSEARCH_REPORT,
  UPDATE_POST_EXTERNAL,
  UPDATE_POST_EXTERNAL_CLIENT,
  UPDATE_GP_DATA,
  UPDATE_LOAD_FROM_WORKLIST,
  UPDATE_LOAD_EXISTING_TASKS,
  UPDATE_LOAD_CURRENT_TASK_ID,
  UPDATE_LOAD_REFERRAL_ALERTS,
  UPDATE_GROUP_PROGRESSION,
  SELECTED_EXTERNAL_DATA,
  UPDATE_VENDOR_LOOKUP,
  RESET_VENDOR_LOOKUP,
  SELECT_FROMWORKLIST_SERVICES,
  UPDATE_WORKLIST_EMAIL_REPORT_TEMPLATES,
  QUICK_SEARCH_CHECK,
  MERGE_FILTER_DATA,
  GET_AP_INFO,
  SAVE_AP_INFO,
  SET_CURRENT_WORK_LIST,
  SET_VENDOR_LOOKUP_SUGGESTIONS,
  RESET_VENDOR_LOOKUP_SUGGESTIONS,
  SET_VENDOR_DATA,
  SET_SPT_DETAILS,
  SET_TRN_DETAILS,
  SPT_SAVE_DATA,
  TRN_SAVE_DATA,
  SET_VENDOR_LOOKUP_TOP5BYLOCATION,
  SET_VENDOR_LOOKUP_TOP5BYCOUNT,
  SET_EVALUATE_VENDOR_DATA,
  SET_VENDOR_HISTORY_DATA,
  RESET_VENDOR_LOOKUP_DATA,
  SAVE_OBJ,
  UPDATE_REQUIRED_FIELDS,
  UPDATE_GET_DOCTYPES,
  UPDATE_GET_CC_DOCUMENTS,
  UPDATE_SAVE_FILEUPLOAD,
  UPDATED_FILES_DATA,
  UPDATE_DELETE_DOCUMENT,
  UPDATE_RETRIVE_DOCUMENT,
  UPDATE_UPLOADED_DOCUMENT,
  RESET_UPLOADED_DOCUMENT,
  REQUIRED_SERVICE_DATA,
  UPDATE_GET_CLAIM_AUTH,
  UPDATE_SAVE_AUTH_HISTORY,
  UPDATE_USERS_TOT_ORDERS,
  RESET_USERS_TOT_ORDERS,
  UPDATE_USERS_OOC,
  RESET_USERS_OOC,
  UPDATE_USERS_RUSH,
  RESET_USERS_RUSH,
  UPDATE_NEW_ORDERS,
  RESET_NEW_ORDERS,
  UPDATE_FOLLOWUPS,
  RESET_FOLLOWUPS,
  UPDATE_SAVE_AUTH_SERVICE,
  UPDATE_CLEAR_AUTH_SERVICE,
  UPDATE_GET_AUTH_SERVICE,
  SAVE_FINALIZE_ASSIGNMENT_EMAIL_DATA,
  SELECTED_AUTH_RECORD,
  SET_SELECTED_VENDOR_DATA,
  SAVE_CONFIRM_CONTACT_LIST,
  SAVE_LMS_DOCUMENT_EMAIL,
  SAVE_REPORT_TEMPLATES_BODY,
  SAVE_ATTACHMENT_VALIDATION_RESULT,
  REFERRAL_TRANSFERED,
  CLEAR_REFERRAL_TRANSFERED,
  SET_PRODUCT_DATA,
  SET_SUB_PRODUCT_DATA,
  SET_SELECTED_TYPE,
  DETAIL_EMAIL_SENT,
  RESET_EMAIL_SENT,
  UPDATE_GP_INFO,
  SET_INPUT_FIELDS,
  SET_ELIGIBILITY,
  CLEAR_ELIGIBILITY,
  UPDATE_CLIENT_LOOKUP,
  UPDATE_AUD_HCPC_LIST,
  RESET_AUD_HCPC_LIST,
  RESET_COST_SAVINGS,
  UPDATE_COST_SAVINGS,
  STATUS_BACKUP,
  MILESTONE_BACKUP,
  TASK_BACKUP,
  PRODUCT_BACKUP,
  SUBPRODUCT_BACKUP,
  REQ_FIELDS,
  SAVE_GREAT_PLAINS,
  CLEAR_GREAT_PLAINS,
  TRN_DETAIL_MODAL_DATA,
  TRN_TABLE_DETAILS,
  SPT_TABLE_DETAILS,
  SAVE_RENTAL_SERVICE_REFERRAL,
  SET_LAST_SERVICE_MODULE,
  CLEAR_ATTACHMENT_VALIDATION_RESULT,
  SET_LAST_SUB_SERVICE_MODULE,
  UPDATE_DETAIL_PROVIDER,
  UPDTAE_FOLLOWUP_DATE,
  FOLLOW_UP_CHANGE_EVENT,
  FOLLOW_UP_DATE_EVENT,
  UPDATE_PRODUCT_CATALOG,

  UPDATE_LINE_ITEMS_LIST,
  UPDATE_LINE_ITEM_DETAILS,
  UPDATE_PAYABLE_LINES,

  SET_SELECTED_FILTER_TYPE,

  SET_ADV_VALUES,
  UPDATE_TOGGLE_VALUES,
  UPDATE_INVOICE_TASK_DATA,
  IS_LOGOUT,
  APPROVAL_DENIAL_REASONS,
  SKIP_PAYABLE_LINES,
  SET_SELECTED_INVOICE_COORDINATOR_TABLE,
  SET_SELECTED_INVOICE_COORDINATOR_USER,
  SET_NPPES_PHYSICIANS_LIST,
  RESET_NPPES_PHYSICIANS_LIST,
  VM_PROVIDER_TYPES,
  VM_GROUPS,
  UPDATE_VM_RESULTS,
  RESET_PROVIDER_RESULTS,
} from './constants';

const loadInitialData = data => {
  return {
    type: LOAD_INITIAL_DATA,
    payload: data,
  };
};

const loadLookupReport = data => {
  return {
    type: LOAD_ADVSEARCH_REPORT,
    payload: data,
  };
};

const mergeFilterData = data => {
  return {
    type: MERGE_FILTER_DATA,
    payload: data,
  };
};

const resetAdvancedSearchReport = () => {
  return {
    type: RESET_ADVSEARCH_REPORT,
  };
};

const loadClientData = data => {
  return {
    type: LOAD_CLIENT_DATA,
    payload: data,
  };
};

const updateDefaultPercent = data => {
  return {
    type: UPDATE_DEFAULT_PERCENT,
    payload: data,
  };
};

const resetDefaultPercent = () => {
  return {
    type: RESET_DEFAULT_PERCENT,
  };
};

const updatePatientZip = data => {
  return {
    type: UPDATE_PATIENT_ZIP_LIST,
    payload: data,
  };
};

const resetPatientZip = () => {
  return {
    type: RESET_PATIENT_ZIP_LIST,
  };
};

const updateSaveFeesData = data => {
  return {
    type: UPDATE_SAVE_FEES_DATA,
    payload: data,
  };
};

const resetSaveFeesData = () => {
  return {
    type: RESET_SAVE_FEES_DATA,
  };
};

const updateSaveCostsData = data => {
  return {
    type: UPDATE_SAVE_COSTS_DATA,
    payload: data,
  };
};

const resetSaveCostsData = () => {
  return {
    type: RESET_SAVE_COSTS_DATA,
  };
};

const updateServiceIdFromReference = data => {
  return {
    type: UPDATE_SERVICE_ID_FROM_REF,
    payload: data,
  };
};

const resetServiceIdFromReference = () => {
  return {
    type: RESET_SERVICE_ID_FROM_REF,
  };
};

const updateLoadProductSearchTable = data => {
  return {
    type: UPDATE_LOAD_PRODUCT_SEARCH_LIST,
    payload: data,
  };
};

const productSubmit = data => {
  return {
    type: UPDATE_PRODUCT_CATALOG,
    payload: data,
  };
}
const resetLoadProductSearchTable = () => {
  return {
    type: RESET_LOAD_PRODUCT_SEARCH_LIST,
  };
};

const updateLoadHCPCTable = data => {
  return {
    type: UPDATE_LOAD_HCPC_LIST,
    payload: data,
  };
};

const resetLoadHCPCTable = () => {
  return {
    type: RESET_LOAD_HCPC_LIST,
  };
};

const updateLoadHCPCWithLimit = data => {
  return {
    type: UPDATE_LOAD_HCPC_LIST_WITH_LIMIT,
    payload: data,
  };
};

const resetLoadHCPCWithLimit = () => {
  return {
    type: RESET_LOAD_HCPC_LIST_WITH_LIMIT,
  };
};

const updateHcpcTermCheck = data => {
  return {
    type: UPDATE_HCPC_TERM_CHECK,
    payload: data,
  };
};

const resetHcpcTermCheck = () => {
  return {
    type: RESET_HCPC_TERM_CHECK,
  };
};

const updateSaveHHCHCPC = data => {
  return {
    type: UPDATE_SAVE_HHC_HCPC,
    payload: data,
  };
};

const resetSaveHHCHCPC = () => {
  return {
    type: RESET_SAVE_HHC_HCPC,
  };
};

const updateSaveMSEHCPC = data => {
  return {
    type: UPDATE_SAVE_MSE_HCPC,
    payload: data,
  };
};

const resetSaveMSEHCPC = () => {
  return {
    type: RESET_SAVE_MSE_HCPC,
  };
};

const updateSaveDTLHCPC = data => {
  return {
    type: UPDATE_SAVE_DTL_HCPC,
    payload: data,
  };
};

const resetSaveDTLHCPC = () => {
  return {
    type: RESET_SAVE_DTL_HCPC,
  };
};

const updateSaveMODHCPC = data => {
  return {
    type: UPDATE_SAVE_MOD_HCPC,
    payload: data,
  };
};

const resetSaveMODHCPC = () => {
  return {
    type: RESET_SAVE_MOD_HCPC,
  };
};

const updateGetDetailLineFeeTable = data => {
  return {
    type: UPDATE_DETAIL_LINE_FEE_LIST,
    payload: data,
  };
};

const resetGetDetailLineFeeTable = () => {
  return {
    type: RESET_DETAIL_LINE_FEE_LIST,
  };
};

const updateClientFeeLookupTable = data => {
  return {
    type: UPDATE_CLIENT_FEE_LIST,
    payload: data,
  };
};

const resetClientFeeLookupTable = () => {
  return {
    type: RESET_CLIENT_FEE_LIST,
  };
};

const updateVendorLookupTable = data => {
  return {
    type: UPDATE_VENDOR_LOOKUP_LIST,
    payload: data,
  };
};

const resetVendorLookupTable = () => {
  return {
    type: RESET_VENDOR_LOOKUP_LIST,
  };
};

const updateAudInfoTable = data => {
  return {
    type: UPDATE_AUD_INFO_LIST,
    payload: data,
  };
};

const updateTRNInfoTable = data => {
  return {
    type: UPDATE_TRN_INFO_LIST,
    payload: data,
  };
};
const updateTrnDetailList = data => {
  return {
    type: UPDATE_TRN_DETAILS_LIST,
    payload: data,
  };
};

const updateDeletedTRNList = data => {
  return {
    type: UPDATE_DELETED_TRN_LIST,
    payload: data,
  };
};

const updateDeletedSPTList = data => {
  return {
    type: UPDATE_DELETED_SPT_LIST,
    payload: data,
  };
};

const updateSptDetailList = data => {
  return {
    type: UPDATE_SPT_DETAILS_LIST,
    payload: data,
  };
};

const resetTRNInfoTable = data => {
  return {
    type: RESET_TRN_INFO_LIST,
    payload: data,
  };
};

const updateSPTInfoTable = data => {
  return {
    type: UPDATE_SPT_INFO_LIST,
    payload: data,
  };
};

const resetSPTInfoTable = data => {
  return {
    type: RESET_SPT_INFO_LIST,
    payload: data,
  };
};
const resetAudInfoTable = () => {
  return {
    type: RESET_AUD_INFO_LIST,
  };
};

const updateSearchReferrerTable = data => {
  return {
    type: UPDATE_SEARCH_REFERRER,
    payload: data,
  };
};

const updateSelectItemRefTable = data => {
  return {
    type: UPDATE_SELECT_ITEM_REFERRER,
    payload: data,
  };
};

const updateDataFromReferrer = data => {
  return {
    type: UPDATE_DATA_FROM_REFERRER,
    payload: data,
  };
};

const resetSearchReferrerTable = () => {
  return {
    type: RESET_SEARCH_REFERRER,
  };
};

const updateSearchClientTable = data => {
  return {
    type: UPDATE_SEARCH_CLIENT,
    payload: data,
  };
};

const updatePayoerInfo = data => {
  return {
    type: UPDATE_SEARCH_CLIENT,
    payload: data,
  };
};

const updateSearchClaimant = data => {
  return {
    type: UPDATE_SEARCH_CLAIMANT,
    payload: data,
  };
};

const resetSearchClaimant = () => {
  return {
    type: RESET_SEARCH_CLAIMANT,
  };
};

const updateLoadClaimantById = data => {
  return {
    type: UPDATE_LOAD_CLAIMANT_BY_ID,
    payload: data,
  };
};

const resetLoadClaimantById = () => {
  return {
    type: RESET_LOAD_CLAIMANT_BY_ID,
  };
};

const updateLoadClaim = data => {
  return {
    type: UPDATE_LOAD_CLAIM,
    payload: data,
  };
};

const resetLoadClaim = () => {
  return {
    type: RESET_LOAD_CLAIM,
  };
};

const updateLoadDX = data => {
  return {
    type: UPDATE_LOAD_DX,
    payload: data,
  };
};

const resetLoadDX = () => {
  return {
    type: RESET_LOAD_DX,
  };
};

const updateDeleteIcd = data => {
  return {
    type: UPDATE_DELETE_ICD,
    payload: data,
  };
};

const resetDeleteIcd = () => {
  return {
    type: RESET_DELETE_ICD,
  };
};

const updateDeleteInjury = data => {
  return {
    type: UPDATE_DELETE_INJURY,
    payload: data,
  };
};

const resetDeleteInjury = () => {
  return {
    type: RESET_DELETE_INJURY,
  };
};

const updateSaveClaim = data => {
  return {
    type: UPDATE_SAVE_CLAIM,
    payload: data,
  };
};

const resetSaveClaim = () => {
  return {
    type: RESET_SAVE_CLAIM,
  };
};

const updateSaveReferral = data => {
  return {
    type: UPDATE_SAVE_REFERRAL,
    payload: data,
  };
};

const resetSaveReferral = () => {
  return {
    type: RESET_SAVE_REFERRAL,
  };
};

const updateSearchAdjusterTable = data => {
  return {
    type: UPDATE_SEARCH_ADJUSTER,
    payload: data,
  };
};

const resetSearchAdjusterTable = () => {
  return {
    type: RESET_SEARCH_ADJUSTER,
  };
};

const updateLoadAdjuster = data => {
  return {
    type: UPDATE_LOAD_ADJUSTER,
    payload: data,
  };
};

const resetLoadAdjuster = () => {
  return {
    type: RESET_LOAD_ADJUSTER,
  };
};

const updateAdjusterInfo = data => {
  return {
    type: UPDATE_ADJUSTER_INFO,
    payload: data,
  };
};

const resetAdjusterInfo = () => {
  return {
    type: RESET_ADJUSTER_INFO,
  };
};

const updateSearchEmployerTable = data => {
  return {
    type: UPDATE_SEARCH_EMPLOYER,
    payload: data,
  };
};

const resetSearchEmployerTable = () => {
  return {
    type: RESET_SEARCH_EMPLOYER,
  };
};

const updateLoadEmployer = data => {
  return {
    type: UPDATE_LOAD_EMPLOYER,
    payload: data,
  };
};

const resetLoadEmployer = () => {
  return {
    type: RESET_LOAD_EMPLOYER,
  };
};

const updateEmployerInfo = data => {
  return {
    type: UPDATE_EMPLOYER_INFO,
    payload: data,
  };
};

const resetEmployerInfo = () => {
  return {
    type: RESET_EMPLOYER_INFO,
  };
};

const updateSearchPhysicianTable = data => {
  return {
    type: UPDATE_SEARCH_PHYSICIAN,
    payload: data,
  };
};

const resetSearchPhysicianTable = () => {
  return {
    type: RESET_SEARCH_PHYSICIAN,
  };
};

const updateLoadPhysician = data => {
  return {
    type: UPDATE_LOAD_PHYSICIAN,
    payload: data,
  };
};

const resetLoadPhysician = () => {
  return {
    type: RESET_LOAD_PHYSICIAN,
  };
};

const updatePhysicianInfo = data => {
  return {
    type: UPDATE_PHYSICIAN_INFO,
    payload: data,
  };
};

const resetPhysicianInfo = () => {
  return {
    type: RESET_PHYSICIAN_INFO,
  };
};

const updateSearchAttorneyTable = data => {
  return {
    type: UPDATE_SEARCH_ATTORNEY,
    payload: data,
  };
};

const resetSearchAttorneyTable = () => {
  return {
    type: RESET_SEARCH_ATTORNEY,
  };
};

const updateLoadAttorney = data => {
  return {
    type: UPDATE_LOAD_ATTORNEY,
    payload: data,
  };
};

const resetLoadAttorney = () => {
  return {
    type: RESET_LOAD_ATTORNEY,
  };
};

const updateAttorneyInfo = data => {
  return {
    type: UPDATE_ATTORNEY_INFO,
    payload: data,
  };
};

const resetAttorneyInfo = () => {
  return {
    type: RESET_ATTORNEY_INFO,
  };
};

const updateSearchCaseManagerTable = data => {
  return {
    type: UPDATE_SEARCH_CASE_MANAGER,
    payload: data,
  };
};

const resetSearchCaseManagerTable = () => {
  return {
    type: RESET_SEARCH_CASE_MANAGER,
  };
};

const updateLoadCaseManager = data => {
  return {
    type: UPDATE_LOAD_CASE_MANAGER,
    payload: data,
  };
};

const updateAdvSearch = data => {
  return {
    type: UPDATE_ADV_SEARCH,
    payload: data,
  };
};

const updateLegStatus = data => {
  return {
    type: UPDATE_LEG_STATUS,
    payload: data,
  };
};

const updateLoadHCPCQuick = data => {
  return {
    type: UPDATE_LOAD_HCPC_QUICK,
    payload: data,
  };
};

const updateClientLookup = data => {
  return {
    type: UPDATE_CLIENT_LOOKUP,
    payload: data,
  };
};

const updateAudiologyHcpcsList = data => {
  return {
    type: UPDATE_AUD_HCPC_LIST,
    payload: data,
  };
};

const resetAudiologyHcpcsList = data => {
  return {
    type: RESET_AUD_HCPC_LIST
  };
};

const resetCostSavings = data => {
  return {
    type: RESET_COST_SAVINGS,
  };
};

const updateCostSavings = data => {
  return {
    type: UPDATE_COST_SAVINGS,
    payload: data,
  };
};

const updateLookUpSpLocation = data => {
  return {
    type: UPDATE_LOOK_UP_SPLOCATION,
    payload: data,
  };
};

const resetLoadCaseManager = () => {
  return {
    type: RESET_LOAD_CASE_MANAGER,
  };
};

const updateCaseManagerInfo = data => {
  return {
    type: UPDATE_CASE_MANAGER_INFO,
    payload: data,
  };
};

const resetCaseManagerInfo = () => {
  return {
    type: RESET_CASE_MANAGER_INFO,
  };
};

const updateUsersWorkList = data => {
  return {
    type: UPDATE_USER_WORK_LIST,
    payload: data,
  };
};
const updateNewUsersWorkList = data => {
  return {
    type: UPDATE_NEW_USER_WORK_LIST,
    payload: data,
  };
};

const resetSearchClientTable = () => {
  return {
    type: RESET_SEARCH_CLIENT,
  };
};

const updateLoadReferrer = data => {
  return {
    type: UPDATE_LOAD_REFERRER,
    payload: data,
  };
};

const resetLoadReferrer = () => {
  return {
    type: RESET_LOAD_REFERRER,
  };
};

const resetLoadClient = () => {
  return {
    type: RESET_LOAD_CLIENT,
  };
};



const updateZipList = data => {
  return {
    type: UPDATE_ZIP_LIST,
    payload: data,
  };
};

const resetZipList = () => {
  return {
    type: RESET_ZIP_LIST,
  };
};

const updateReferrerInfo = data => {
  return {
    type: UPDATE_REF_INFO,
    payload: data,
  };
};

const resetReferrerInfo = () => {
  return {
    type: RESET_REF_INFO,
  };
};

const updateClientInfo = data => {
  return {
    type: UPDATE_CLIENT_INFO,
    payload: data,
  };
};

const resetClientInfo = data => {
  return {
    type: RESET_CLIENT_INFO,
    payload: data,
  };
};

const updateClickRecord = data => {
  return {
    type: UPDATE_CLICK_RECORD,
    payload: data,
  };
};
const updateGetNotesData = data => {
  return {
    type: UPDATE_GET_NOTES_DATA,
    payload: data,
  };
};

const updateloadEligibility = data => {
  return {
    type: UPDATE_LOAD_ELIGIBILITY,
    payload: data,
  };
};

const updateGetLoadNotes = data => {
  return {
    type: UPDATE_GET_LOAD_NOTES,
    payload: data,
  };
};

const updateGetFollowupData = data => {
  return {
    type: UPDATE_GET_FOLLOWUP_DATA,
    payload: data,
  };
};
const updateStatusClicked = data => {
  return {
    type: UPDATE_STATUS_CLICKED,
    payload: data,
  };
};
const updatedStatusData = data => {
  return {
    type: UPDATE_STATUS_DATA,
    payload: data,
  };
};
const updateTaskClicked = data => {
  return {
    type: UPDATE_TASK_CLICKED,
    payload: data,
  };
};
const updateTaskData = data => {
  return {
    type: UPDATE_TASK_DATA,
    payload: data,
  };
};
const updateMilestoneClicked = data => {
  return {
    type: UPDATE_MILESTONE_CLICKED,
    payload: data,
  };
};
const updateMilestoneData = data => {
  return {
    type: UPDATE_MILESTONE_DATA,
    payload: data,
  };
};
const updatePreviousUser = data => {
  return {
    type: UPDATE_PREVIOUS_USER,
    payload: data,
  };
};
const TaskWorkListBackup = data => {
  return {
    type: TASK_WORKLIST_BACKUP,
    payload: data,
  };
};
const milestoneWorkListBackup = data => {
  return {
    type: MILESTONE_WORKLIST_BACKUP,
    payload: data,
  };
};
const statusWorkListBackup = data => {
  return {
    type: STATUS_WORKLIST_BACKUP,
    payload: data,
  };
};
const milestoneAgainClicked = data => {
  return {
    type: MILESTONE_AGAIN_CLICKED,
    payload: data,
  };
};

const updateGetCancelReasons = data => {
  return {
    type: UPDATE_GET_CANCEL_REASONS,
    payload: data,
  };
};
const updateGetEmailAddress = data => {
  return {
    type: UPDATE_GET_EMAIL_ADDRESS,
    payload: data,
  };
};
const updateGetExternalReferral = data => {
  return {
    type: UPDATE_GET_EXTERNAL_REFERRAL,
    payload: data,
  };
};
const updateGetAPExtract = data => {
  return {
    type: UPDATE_GET_AP_EXTRACT,
    payload: data,
  };
};
const updatePostExternal = data => {
  return {
    type: UPDATE_POST_EXTERNAL,
    payload: data,
  };
};
const updatePostExternalClient = data => {
  return {
    type: UPDATE_POST_EXTERNAL_CLIENT,
    payload: data,
  };
};

const updateGpData = data => {
  return {
    type: UPDATE_GP_DATA,
    payload: data,
  };
};

const updateGpInfo = data => {
  return {
    type: UPDATE_GP_INFO,
    payload: data,
  };
};

const updateLoadFromWorkList = data => {
  return {
    type: UPDATE_LOAD_FROM_WORKLIST,
    payload: data,
  };
};

const updateSaveFileUpload = data => {
  return {
    type: UPDATE_SAVE_FILEUPLOAD,
    payload: data,
  };
};

const updatedFilesData = data => {
  return {
    type: UPDATED_FILES_DATA,
    payload: data,
  };
};


const updateGetDocTypes = data => {
  return {
    type: UPDATE_GET_DOCTYPES,
    payload: data,
  };
};

const updateGetCcDocument = data => {
  return {
    type: UPDATE_GET_CC_DOCUMENTS,
    payload: data,
  };
};

const selectedProduct = data => {
  return {
    type: SET_PRODUCT_DATA,
    payload: data,
  };
};

const setInputFields = data => {
  return {
    type: SET_INPUT_FIELDS,
    payload: data,
  };
};

const selectedSubProduct = data => {
  return {
    type: SET_SUB_PRODUCT_DATA,
    payload: data,
  };
};

const setSelectedType = data => {
  return {
    type: SET_SELECTED_TYPE,
    payload: data,
  };
};

const updateDeleteDocument = data => {
  return {
    type: UPDATE_DELETE_DOCUMENT,
    payload: data,
  };
};
const updateRetriveDocument = data => {
  return {
    type: UPDATE_RETRIVE_DOCUMENT,
    payload: data,
  };
};
const updateUploadedFiles = data => {
  return {
    type: UPDATE_UPLOADED_DOCUMENT,
    payload: data,
  };
};
const resetUploadedFiles = data => {
  return {
    type: RESET_UPLOADED_DOCUMENT,
    payload: [],
  };
};

const updateGetClaimAuth = data => {
  return {
    type: UPDATE_GET_CLAIM_AUTH,
    payload: data,
  };
};

const updateSelectedAuthRecord = data => {
  return {
    type: SELECTED_AUTH_RECORD,
    payload: data,
  };
};

const updateSaveAuthHistory = data => {
  return {
    type: UPDATE_SAVE_AUTH_HISTORY,
    payload: data,
  };
};

const updateSaveAuthService = data => {
  return {
    type: UPDATE_SAVE_AUTH_SERVICE,
    payload: data,
  };
};

const updateClearAuthService = data => {
  return {
    type: UPDATE_CLEAR_AUTH_SERVICE,
    payload: data,
  };
};

const updateGetAuthService = data => {
  return {
    type: UPDATE_GET_AUTH_SERVICE,
    payload: data,
  };
};



const requiredServiceData = data => {
  return {
    type: REQUIRED_SERVICE_DATA,
    payload: data
  };
};


const updateloadExistingTasks = data => {
  return {
    type: UPDATE_LOAD_EXISTING_TASKS,
    payload: data,
  };
};

const updateloadCurrentTaskId = data => {
  return {
    type: UPDATE_LOAD_CURRENT_TASK_ID,
    payload: data,
  };
};

const updateProductCatalog = data => {
  return {
    type: UPDATE_PRODUCT_CATALOG,
    payload: data,
  };
};

const updateloadReferralAlerts = data => {
  return {
    type: UPDATE_LOAD_REFERRAL_ALERTS,
    payload: data,
  };
};

const updateRequiredFields = data => {
  return {
    type: UPDATE_REQUIRED_FIELDS,
    payload: data,
  };
};
const updateloadGroupProgression = data => {
  return {
    type: UPDATE_GROUP_PROGRESSION,
    payload: data,
  };
};

const selectedExternalData = data => {
  return {
    type: SELECTED_EXTERNAL_DATA,
    payload: data,
  };
};
const selectFromWorkListService = data => {
  return {
    type: SELECT_FROMWORKLIST_SERVICES,
    payload: data,
  };
};

const updateVendorLookup = data => {
  return {
    type: UPDATE_VENDOR_LOOKUP,
    payload: data,
  };
};

const resetVendorLookup = () => {
  return {
    type: RESET_VENDOR_LOOKUP,
  };
};

const updateWorklistEmailReportTemplates = data => {
  return {
    type: UPDATE_WORKLIST_EMAIL_REPORT_TEMPLATES,
    payload: data,
  };
};

const quickSearchCheck = data => {
  return {
    type: QUICK_SEARCH_CHECK,
    payload: data,
  };
}

const followupChangeEvent = data => {
  return {
    type: FOLLOW_UP_CHANGE_EVENT,
    payload: data,
  };
}

const followupDateEvent = data => {
  return {
    type: FOLLOW_UP_DATE_EVENT,
    payload: data,
  };
}



const getAPInfo = data => {
  return {
    type: GET_AP_INFO,
    payload: data,
  };
};

const saveAPInfo = data => {
  return {
    type: SAVE_AP_INFO,
    payload: data,
  };
};

const resetVendorLookupData = () => {
  return {
    type: RESET_VENDOR_LOOKUP_DATA
  }
}

const setCurrentWorkList = data => {
  return {
    type: SET_CURRENT_WORK_LIST,
    payload: data
  }
}
const vendorLookupSuggestions = data => {
  return {
    type: SET_VENDOR_LOOKUP_SUGGESTIONS,
    payload: data
  }
}

const resetVendorData = data => {
  return {
    type: RESET_VENDOR_LOOKUP_SUGGESTIONS,
    payload: data
  }
}


const setVendorData = data => {
  return {
    type: SET_VENDOR_DATA,
    payload: data
  }
}

const setSptDetails = data => {
  return {
    type: SET_SPT_DETAILS,
    payload: data
  }
}

const setTrnDetails = data => {
  return {
    type: SET_TRN_DETAILS,
    payload: data
  }
}

const sptsaveData = data => {
  return {
    type: SPT_SAVE_DATA,
    payload: data
  }
}

const trnSaveData = data => {
  return {
    type: TRN_SAVE_DATA,
    payload: data
  }
}




const vendorLookupTop5ByLocation = data => {
  return {
    type: SET_VENDOR_LOOKUP_TOP5BYLOCATION,
    payload: data
  }
}

const vendorLookupTop5ByCount = data => {
  return {
    type: SET_VENDOR_LOOKUP_TOP5BYCOUNT,
    payload: data
  }
}

const evaluateVendorData = data => {
  return {
    type: SET_EVALUATE_VENDOR_DATA,
    payload: data
  }
}

const getVendorHistoryData = data => {
  return {
    type: SET_VENDOR_HISTORY_DATA,
    payload: data
  }
}

const saveObj = data => {
  return {
    type: SAVE_OBJ,
    payload: data,
  };
};

const updateQuickSearchList = data => {
  return {
    type: UPDATE_QUICK_SEARCH_LIST,
    payload: data
  }
}

const resetQuickSearchList = () => {
  return {
    type: RESET_QUICK_SEARCH_LIST,
  };
};

const updateUsersTotOrders = data => {
  return {
    type: UPDATE_USERS_TOT_ORDERS,
    payload: data
  }
}

const resetUsersTotOrders = () => {
  return {
    type: RESET_USERS_TOT_ORDERS,
  };
};

const updateUsersOOC = data => {
  return {
    type: UPDATE_USERS_OOC,
    payload: data
  }
}

const resetUsersOOC = () => {
  return {
    type: RESET_USERS_OOC,
  };
};

const updateUsersRush = (payload, currentUserId) => {
  return {
    type: UPDATE_USERS_RUSH,
    payload,
    currentUserId
  }
}

const resetUsersRush = () => {
  return {
    type: RESET_USERS_RUSH,
  };
};

const updateNewOrders = data => {
  return {
    type: UPDATE_NEW_ORDERS,
    payload: data
  }
}

const resetNewOrders = () => {
  return {
    type: RESET_NEW_ORDERS,
  };
};

const updateFollowups = data => {
  return {
    type: UPDATE_FOLLOWUPS,
    payload: data
  }
}

const resetFollowups = () => {
  return {
    type: RESET_FOLLOWUPS,
  };
};

const setSelectedVendor = data => {
  return {
    type: SET_SELECTED_VENDOR_DATA,
    payload: data
  }
}

const saveFinalizeAssignmentEmailData = data => {
  return {
    type: SAVE_FINALIZE_ASSIGNMENT_EMAIL_DATA,
    payload: data
  }
}

const saveConfirmWithContactList = data => {
  return {
    type: SAVE_CONFIRM_CONTACT_LIST,
    payload: data
  }
}

const saveLMSDocumentsEmail = data => {
  return {
    type: SAVE_LMS_DOCUMENT_EMAIL,
    payload: data
  }
}

const saveReportTemplatesBody = data => {
  return {
    type: SAVE_REPORT_TEMPLATES_BODY,
    payload: data
  }
}

const saveAttachmentValidationResult = data => {
  return {
    type: SAVE_ATTACHMENT_VALIDATION_RESULT,
    payload: data
  }
}

const clearAttachmentValidationResult = () => {
  return {
    type: CLEAR_ATTACHMENT_VALIDATION_RESULT,
  }
}

const referralTransfered = () => {
  return {
    type: REFERRAL_TRANSFERED
  }
}

const clearReferralTransfered = () => {
  return {
    type: CLEAR_REFERRAL_TRANSFERED
  }
}

const detailEmailSent = data => {
  return {
    type: DETAIL_EMAIL_SENT,
    payload: data
  }
}

const resetDetailEmailSent = () => {
  return {
    type: RESET_EMAIL_SENT
  }
}

const setEligibility = (data) => {
  return {
    type: SET_ELIGIBILITY,
    payload: data
  }
}

const clearEligibility = () => {
  return {
    type: CLEAR_ELIGIBILITY
  }
}

const statusBackUp = data => {
  return {
    type: STATUS_BACKUP,
    payload: data,
  };
};

const mileStoneBackUp = data => {
  return {
    type: MILESTONE_BACKUP,
    payload: data,
  };
};

const reqFields = data => {
  return {
    type: REQ_FIELDS,
    payload: data,
  };
};

const taskBackUp = data => {
  return {
    type: TASK_BACKUP,
    payload: data,
  };
};

const productBackUp = data => {
  return {
    type: PRODUCT_BACKUP,
    payload: data,
  };
};

const subProductBackUp = data => {
  return {
    type: SUBPRODUCT_BACKUP,
    payload: data,
  };
};

const saveGreatPlains = data => {
  return {
    type: SAVE_GREAT_PLAINS,
  }
}

const clearGreatPlains = data => {
  return {
    type: CLEAR_GREAT_PLAINS,
  }
}

const trnDetailModaldata = data => {
  return {
    type: TRN_DETAIL_MODAL_DATA,
    payload: data,
  }
}

const trnTableDetails = data => {
  return {
    type: TRN_TABLE_DETAILS,
    payload: data,
  }
}

const sptTableDetails = data => {
  return {
    type: SPT_TABLE_DETAILS,
    payload: data,
  }
}



const loadByServiceReferral = data => {
  return {
    type: SAVE_RENTAL_SERVICE_REFERRAL,
    payload: data
  }
}

const setLastServiceModule = data => {
  return {
    type: SET_LAST_SERVICE_MODULE,
    payload: data
  }
}

const setLastSubServiceModule = data => {
  return {
    type: SET_LAST_SUB_SERVICE_MODULE,
    payload: data
  }
}

const updateDetailProvider = data => {
  return {
    type: UPDATE_DETAIL_PROVIDER,
    payload: data
  }
}

const updateFollowUpDates = data => {
  return {
    type: UPDTAE_FOLLOWUP_DATE,
    payload: data
  }
}


const updateLineItemsList = data => {
  return {
    type: UPDATE_LINE_ITEMS_LIST,
    payload: data
  }
}

const updateLineItemDetails = data => {
  return {
    type: UPDATE_LINE_ITEM_DETAILS,
    payload: data
  }
}

const updatePayableLines = data => {
  return {
    type: UPDATE_PAYABLE_LINES,
    payload: data
  }
}

const setSelectedFilterType = data => {
  return {
    type: SET_SELECTED_FILTER_TYPE,
    payload: data,
  };
};

const setAdvValues = data => {
  return {
    type: SET_ADV_VALUES,
    payload: data,
  };
};

const updateToggleValues = data => {
  return {
    type: UPDATE_TOGGLE_VALUES,
    payload: data,
  };
};

const updateInvoiceTaskData = data => {
  return {
    type: UPDATE_INVOICE_TASK_DATA,
    payload: data
  };
};

const updateIsLogout = data => {
  return {
    type: IS_LOGOUT,
    payload: data,
  };
};

const setApprovalDenialReasons = data => {
  return {
    type: APPROVAL_DENIAL_REASONS,
    payload: data,
  };
}

const setSkipPayableLines = data => {
  return {
    type: SKIP_PAYABLE_LINES,
    payload: data,
  }
}

const setSelectedInvoiceCoordinatorTable = data => {
  return {
    type: SET_SELECTED_INVOICE_COORDINATOR_TABLE,
    payload: data,
  }
};

const setVMProviderTypes = data => {
  return {
    type: VM_PROVIDER_TYPES,
    payload: data,
  };
};

const setSelectedInvoiceCoordinatorUser = data => {
  return {
    type: SET_SELECTED_INVOICE_COORDINATOR_USER,
    payload: data,
  }
}

const setNppesPhysicianList = data => {
  return {
    type: SET_NPPES_PHYSICIANS_LIST,
    payload: data,
  }
}

const resetNppesPhysicianList = data => {
  return {
    type: RESET_NPPES_PHYSICIANS_LIST,
  }
}

const setVMGroups = data => {
  return {
    type: VM_GROUPS,
    payload: data,
  };
};

const updateVMResults = data => {
  return {
    type: UPDATE_VM_RESULTS, 
    payload:data,
  }
}

const resetProviderResults = () => {
  return {
    type: RESET_PROVIDER_RESULTS,
  };
};


export default {
  loadInitialData,
  loadClientData,
  updateDefaultPercent,
  resetDefaultPercent,
  updatePatientZip,
  resetPatientZip,
  updateSaveFeesData,
  resetSaveFeesData,
  updateSaveCostsData,
  resetSaveCostsData,
  updateServiceIdFromReference,
  resetServiceIdFromReference,
  updateLoadProductSearchTable,
  productSubmit,
  resetLoadProductSearchTable,
  updateLoadHCPCTable,
  resetLoadHCPCTable,
  updateLoadHCPCWithLimit,
  resetLoadHCPCWithLimit,
  updateHcpcTermCheck,
  resetHcpcTermCheck,
  updateSaveHHCHCPC,
  resetSaveHHCHCPC,
  updateSaveMSEHCPC,
  resetSaveMSEHCPC,
  updateSaveDTLHCPC,
  resetSaveDTLHCPC,
  updateSaveMODHCPC,
  resetSaveMODHCPC,
  updateGetDetailLineFeeTable,
  resetGetDetailLineFeeTable,
  updateClientFeeLookupTable,
  resetClientFeeLookupTable,
  updateVendorLookupTable,
  resetVendorLookupTable,
  updateAudInfoTable,
  resetAudInfoTable,
  updateTRNInfoTable,
  updateTrnDetailList,
  updateDeletedTRNList,
  updateDeletedSPTList,
  updateSptDetailList,
  resetTRNInfoTable,
  updateSPTInfoTable,
  resetSPTInfoTable,
  updateSearchReferrerTable,
  updateSearchClientTable,
  updateSearchClaimant,
  resetSearchClaimant,
  updateLoadClaimantById,
  resetLoadClaimantById,
  updateLoadClaim,
  resetLoadClaim,
  updateLoadDX,
  resetLoadDX,
  updateDeleteIcd,
  resetDeleteIcd,
  updateDeleteInjury,
  resetDeleteInjury,
  updateSaveClaim,
  resetSaveClaim,
  updateSaveReferral,
  resetSaveReferral,
  resetSearchReferrerTable,
  updateSelectItemRefTable,
  updateDataFromReferrer,
  updateSearchAdjusterTable,
  resetSearchAdjusterTable,
  updateLoadAdjuster,
  resetLoadAdjuster,
  updateAdjusterInfo,
  resetAdjusterInfo,
  updateSearchEmployerTable,
  resetSearchEmployerTable,
  updateLoadEmployer,
  resetLoadEmployer,
  updateEmployerInfo,
  resetEmployerInfo,
  updateSearchPhysicianTable,
  resetSearchPhysicianTable,
  updateLoadPhysician,
  resetLoadPhysician,
  updatePhysicianInfo,
  resetPhysicianInfo,
  updateSearchAttorneyTable,
  resetSearchAttorneyTable,
  updateLoadAttorney,
  resetLoadAttorney,
  updateAttorneyInfo,
  resetAttorneyInfo,
  updateSearchCaseManagerTable,
  resetSearchCaseManagerTable,
  updateLoadCaseManager,
  resetLoadCaseManager,
  updateCaseManagerInfo,
  resetCaseManagerInfo,
  resetSearchClientTable,
  updateLoadReferrer,
  resetLoadReferrer,
  resetLoadClient,
  updateZipList,
  resetZipList,
  updateReferrerInfo,
  updateClientInfo,
  resetClientInfo,
  resetReferrerInfo,
  updateUsersWorkList,
  updateNewUsersWorkList,
  updateClickRecord,
  updateGetNotesData,
  updateloadEligibility,
  updateGetLoadNotes,
  updateGetFollowupData,
  updateAdvSearch,
  updateLoadHCPCQuick,
  updateClientLookup,
  updateAudiologyHcpcsList,
  resetAudiologyHcpcsList,
  resetCostSavings,
  updateCostSavings,
  updateLookUpSpLocation,
  updateLegStatus,
  updateStatusClicked,
  updatedStatusData,
  updateTaskClicked,
  updateTaskData,
  updateMilestoneClicked,
  updateMilestoneData,
  updatePreviousUser,
  TaskWorkListBackup,
  milestoneWorkListBackup,
  statusWorkListBackup,
  milestoneAgainClicked,
  updateGetCancelReasons,
  updateGetEmailAddress,
  updateGetExternalReferral,
  updateGetAPExtract,
  loadLookupReport,
  resetAdvancedSearchReport,
  updatePostExternal,
  updatePostExternalClient,
  updateGpData,
  updateLoadFromWorkList,
  updateloadExistingTasks,
  updateloadCurrentTaskId,
  updateProductCatalog,
  updateloadReferralAlerts,
  updateloadGroupProgression,
  selectedExternalData,
  updateVendorLookup,
  resetVendorLookup,
  selectFromWorkListService,
  updateWorklistEmailReportTemplates,
  updateQuickSearchList,
  resetQuickSearchList,
  quickSearchCheck,
  mergeFilterData,
  getAPInfo,
  saveAPInfo,
  setCurrentWorkList,
  vendorLookupSuggestions,
  resetVendorData,
  setVendorData,
  vendorLookupTop5ByLocation,
  vendorLookupTop5ByCount,
  saveObj,
  updateRequiredFields,
  resetVendorLookupData,
  updateGetDocTypes,
  updateGetCcDocument,
  updateSaveFileUpload,
  updatedFilesData,
  updateDeleteDocument,
  updateRetriveDocument,
  updateUploadedFiles,
  resetUploadedFiles,
  evaluateVendorData,
  getVendorHistoryData,
  requiredServiceData,
  updateGetClaimAuth,
  updateSaveAuthHistory,
  updateSaveAuthService,
  updateClearAuthService,
  updateGetAuthService,
  requiredServiceData,
  saveFinalizeAssignmentEmailData,
  updateSelectedAuthRecord,
  setSelectedVendor,
  saveConfirmWithContactList,
  saveLMSDocumentsEmail,
  saveReportTemplatesBody,
  saveAttachmentValidationResult,
  referralTransfered,
  clearReferralTransfered,
  selectedProduct,
  selectedSubProduct,
  setSelectedType,
  detailEmailSent,
  resetDetailEmailSent,
  updateGpInfo,
  setInputFields,
  updateUsersTotOrders,
  resetUsersTotOrders,
  updateUsersOOC,
  resetUsersOOC,
  updateUsersRush,
  resetUsersRush,
  updateNewOrders,
  resetNewOrders,
  updateFollowups,
  resetFollowups,
  setEligibility,
  clearEligibility,
  statusBackUp,
  mileStoneBackUp,
  taskBackUp,
  productBackUp,
  subProductBackUp,
  reqFields,
  saveGreatPlains,
  clearGreatPlains,
  trnDetailModaldata,
  trnTableDetails,
  sptTableDetails,
  loadByServiceReferral,
  setLastServiceModule,
  clearAttachmentValidationResult,
  setLastSubServiceModule,
  setSptDetails,
  setTrnDetails,
  sptsaveData,
  trnSaveData,
  updateDetailProvider,
  updateFollowUpDates,
  followupChangeEvent,
  followupDateEvent,
  updateLineItemsList,
  updateLineItemDetails,
  updatePayableLines,
  setSelectedFilterType,
  setAdvValues,
  updateToggleValues,
  updateInvoiceTaskData,
  updateIsLogout,
  setApprovalDenialReasons,
  setSkipPayableLines,
  setSelectedInvoiceCoordinatorTable,
  setSelectedInvoiceCoordinatorUser,
  setNppesPhysicianList,
  resetNppesPhysicianList,
  setVMProviderTypes,
  setVMGroups,
  updateVMResults,
  resetProviderResults,
};
