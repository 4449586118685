import { ServiceExecutor } from 'core/services/serviceExecutor';
import { commonActions } from 'common/store';
import service from '../services/service';
import actions from './actions';


const getVendorMaintenanceList = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getVendorMaintenanceList(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadVendorMaintenanceList(responseData));
        if (responseData === undefined || responseData.length === 0) {
          let msg = 'Zero results found';
          dispatch(commonActions.addAlert(new Date().getTime(), msg, 'danger'));
        }
      })
      .withFailureAlert('An error occurred while loading vendor Maintenance data')
      .build()
      .execute();
  };
};

const getPrimaryContactFNameList = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getPrimaryContactFNameList(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadPrimaryContactFList(responseData));
      })
      .withFailureAlert('An error occurred while loading primary contact firstname data')
      .build()
      .execute();
  };
};

const getPrimaryContactLNameList = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getPrimaryContactLNameList(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadPrimaryContactLList(responseData));
      })
      .withFailureAlert('An error occurred while loading primary contact lastname data')
      .build()
      .execute();
  };
};

const saveNewContact = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveNewContact(data))
      .withResponseHandler(responseData => {
        dispatch(actions.saveNewContactAction(responseData));
      })
      .withFailureAlert('An error occurred while saving new contact data')
      .build()
      .execute();
  };
};

const saveVendor = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.saveVendor(data))
      .withResponseHandler(responseData => {
        dispatch(actions.saveVendorAction(responseData));
      })
      .withSuccessAlert('Successfully Saved Vendor.')
      .withFailureAlert('An error occurred while saving vendor data')
      .build()
      .execute();
  };
};

const loadVendorMaintenanceDocs = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadVendorMiantenanceDocs(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadVendorMaintenanceDocAction(responseData));
      })
      .withFailureAlert('An error occurred while loading docs data')
      .build()
      .execute();
  };
};

const updateVendorMaintenanceDocs = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.updateVendorMiantenanceDocs(data))
      .withResponseHandler(responseData => {
        if (responseData == 'The file has been uploaded successfully') {
        dispatch(actions.updateVendorMaintenanceDocAction(responseData));
      } else {
        dispatch(commonActions.addAlert(new Date().getTime(),'Unable to upload file' , 'danger'));
      }
  })
      .build()
      .execute();
  };
};

const loadVendorMaintenanceDocData = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadVendorMiantenanceDocData(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadVendorMaintenanceDocDataAction(responseData));
      })
      .withFailureAlert('An error occurred while loading the file data')
      .build()
      .execute();
  };
};

const loadVendorDocTypes = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.loadVendorDocTypes(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadVendorDocTypesAction(responseData));
      })
      .withFailureAlert('An error occurred while loading the doctypes data')
      .build()
      .execute();
  };
};

const deleteVendorDocument = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.deleteVendorDocument(data))
      .withResponseHandler(responseData => {
        dispatch(actions.updateDeleteVendorDocument(responseData));
      })
      .withFailureAlert('An error occurred while deleting the Documents')
      .build()
      .execute();
  };
};

const getNewVendor = data => {
  return async dispatch => {
    await ServiceExecutor.Builder(dispatch)
      .withServiceCall(() => service.getNewVendor(data))
      .withResponseHandler(responseData => {
        dispatch(actions.loadNewVendorAction(responseData));
      })
      .withFailureAlert('An error occurred while loading new vendor')
      .build()
      .execute();
  };
};

export default {
  getVendorMaintenanceList,
  getPrimaryContactFNameList,
  getPrimaryContactLNameList,
  saveNewContact,
  saveVendor,
  loadVendorMaintenanceDocs,
  updateVendorMaintenanceDocs,
  loadVendorMaintenanceDocData,
  loadVendorDocTypes,
  deleteVendorDocument,
  getNewVendor
};