import React, { useEffect, useState, useRef, forwardRef } from 'react';
import {
    Col,
    FormGroup,
    Row,
    Label,
    Button,
    Input,
    Form,
    ListGroup,
    ListGroupItem,
    Alert
} from 'reactstrap';
import './NewVendorEdit.css';
import { connect } from 'react-redux';
import {
    fast360Selectors,
    fast360Operations,
    fast360Actions
} from '../../fast-360/store/index';
import {
    fast360MaintenanceOperations,
    fast360MaintenanceSelectors,
    fast360MaintenanceActions,
} from '../store/index';
import { authSelectors } from 'modules/auth/store';
import { isEmpty } from 'lodash';

const CustomInput = forwardRef((props, ref) => {
    return <Input ref={ref} {...props} />;
})

const NewVendorEditHeader = ({
    saveNewContact,
    onExit,
    services,
    primaryContactFNameList,
    primaryContactLNameList,
    getPrimaryContactLNameList,
    getPrimaryContactFNameList,
    resetPrimaryContactFList,
    resetPrimaryContactLList,
    getZipList,
    resetZipList,
    zipList,
    userId,
    saveVendor,
    newContactInfo,
    vendorInfo,
    getNewVendor,
    loadNewVendorInfo
}) => {
    const [paymentMethod, setPaymentMethod] = useState('');
    const [primaryContactFName, setPrimaryContactFName] = useState('');
    const [primaryContactLName, setPrimaryContactLName] = useState('');
    const [primaryContactEmail1, setPrimaryContactEmail1] = useState('');
    const [primaryContactEmail2, setPrimaryContactEmail2] = useState('');
    const [inActiveReason, setInActiveReason] = useState('');
    const [isActive, setIsActive] = useState(true);
    const [isMemberOfGroupActive, setIsMemberOfGroupActive] = useState(false);
    const [isProspectiveActive, setIsProspectiveActive] = useState(true);
    const [isRetrospectiveActive, setIsRetrospectiveActive] = useState(false);
    const [isPgoVendorActive, setIsPgoVendorActive] = useState(false);
    const [isContractedActive, setIsContractedActive] = useState(false);
    const [effectiveDate, setEffectiveDate] = useState('');
    const [terminateDate, setTerminateDate] = useState('');
    const [credentialedDate, setCredentialedDate] = useState('');
    const [recredentialedDate, setRecredentialedDate] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [city, setCity] = useState('');
    const [county, setCounty] = useState('');
    const [state, setState] = useState('');
    const [streetText, setStreetText] = useState('');
    const [street2Text, setStreet2Text] = useState('');
    const [vendorType, setVendorType] = useState('');
    const [firmNameText, setFirmNameText] = useState('');
    const [dbaText, setDbaText] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [isNationwideActive, setIsNationwideActive] = useState(false);
    const [isPreferredActive, setIsPreferredActive] = useState(false);
    const [firmEmail, setFirmEmail] = useState('');
    const [websiteText, setWebsiteText] = useState('');
    const [mainPhoneNumber, setMainPhoneNumber] = useState('');
    const [phoneExt, setPhoneExt] = useState('');
    const [alternatePhoneNumber, setAlternatePhoneNumber] = useState('');
    const [cellPhoneNumber, setCellPhoneNumber] = useState('');
    const [faxNumber, setFaxNumber] = useState('');
    const [paymentType, setPaymentType] = useState('');
    const [gpVendor, setGpVendor] = useState('');
    const [gpCode, setGpCode] = useState('');
    const [npiVal, setNpiVal] = useState('');
    const [tinVal, setTinVal] = useState('');
    const [licenseNum, setLicenseNum] = useState('');
    const [paymentTermsVal, setPaymentTermsVal] = useState('');
    const [submissionTermsVal, setSubmissionTermsVal] = useState('');
    const [sapIdVal, setSapIdVal] = useState('');
    const [achAccountNum, setAchAccountNum] = useState('');
    const [remitStreetText, setRemitStreetText] = useState('');
    const [remitStreet2Text, setRemitStreet2Text] = useState('');
    const [remitZipCode, setRemitZipCode] = useState('');
    const [remitCity, setRemitCity] = useState('');
    const [remitCounty, setRemitCounty] = useState('');
    const [remitState, setRemitState] = useState('');
    const [activeField, setActiveField] = useState('');
    const [notesText, setNotesText] = useState('');
    const [alertText, setAlertText] = useState('');
    const [groupNameVal, setGroupNameVal] = useState('');
    const [suggestionContact, setSuggestionContact] = useState('');
    const [address, setAddress] = useState({});
    const [remitAddress, setRemitAddress] = useState({});
    const [vendorTypeId, setVendorTypeId] = useState(null);
    const [vendorTypeName, setVendorTypeName] = useState('');
    const [altFirmEmail, setAltFirmEmail] = useState('');
    const [showFirstNameSuggestions, setShowFirstNameSuggestions] = useState(false);
    const [showLastNameSuggestions, setShowLastNameSuggestions] = useState(false);
    const [isAfterVendorSaved, setIsAfterVendorSaved] = useState(false);
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const firstNameSuggestionRef = useRef(null);
    const lastNameSuggestionRef = useRef(null);
    const [vendorId, setVendorId] = useState(0);

    const handlePaymentMethodChange = (e) => {
        setPaymentMethod(e.target.value);
    };

    const handleFirstNameChange = (e) => {
        const value = e.target.value;
        setPrimaryContactFName(value);
        if (value.length >= 2) {
            const params = {
                "name": value
            }
            getPrimaryContactFNameList(params);
            setShowFirstNameSuggestions(true);
        } else {
            setShowFirstNameSuggestions(false);
        }
    };

    const handleSuggestionFNameClick = (suggestion) => {
        setPrimaryContactFName(suggestion.chr_firstname);
        setPrimaryContactLName(suggestion.chr_lastname);
        setPrimaryContactEmail1(suggestion.chr_email1);
        setPrimaryContactEmail2(suggestion.chr_email2);
        setSuggestionContact(suggestion);
        resetPrimaryContactFList();
        setShowFirstNameSuggestions(false);
    };

    const handleLastNameChange = (e) => {
        const value = e.target.value;
        setPrimaryContactLName(value);
        if (value.length >= 2) {
            const params = {
                "name": value
            }
            getPrimaryContactLNameList(params);
            setShowLastNameSuggestions(true);
        } else {
            setShowLastNameSuggestions(false);
        }
    };

    const handleSuggestionLNameClick = (suggestion) => {
        setPrimaryContactFName(suggestion.chr_firstname);
        setPrimaryContactLName(suggestion.chr_lastname);
        setPrimaryContactEmail1(suggestion.chr_email1);
        setPrimaryContactEmail2(suggestion.chr_email2);
        setSuggestionContact(suggestion);
        resetPrimaryContactLList();
        setShowLastNameSuggestions(false);
    };

    const handleClickOutside = (event) => {
        if (!showFirstNameSuggestions) {
           const isFirstNameRef = firstNameRef.current && firstNameRef.current instanceof HTMLElement;
           const  isFirstNameSuggestionRef =  firstNameSuggestionRef.current && firstNameSuggestionRef.current instanceof HTMLElement;
            if(isFirstNameRef && !isFirstNameRef.current.contains(event.target) &&
        isFirstNameSuggestionRef && !isFirstNameSuggestionRef.current.contains(event.target) ) {
                setShowFirstNameSuggestions(false);
                resetPrimaryContactFList(); // Call the reset function here
            }
        }
        if (!showLastNameSuggestions) {
            const isLastNameRef = lastNameRef.current && lastNameRef.current instanceof HTMLElement;
           const  isLastNameSuggestionRef =  lastNameSuggestionRef.current && lastNameSuggestionRef.current instanceof HTMLElement;
           if(isLastNameRef && !isLastNameRef.current.contains(event.target) &&
           isLastNameSuggestionRef && !isLastNameSuggestionRef.current.contains(event.target) ) {
                setShowLastNameSuggestions(false);
                resetPrimaryContactLList();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, [showLastNameSuggestions, showFirstNameSuggestions]);


    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    const toggleReason = (e) => {
        setIsActive(e.target.checked);
    };

    const toggleGroupName = (e) => {
        setIsMemberOfGroupActive(e.target.checked);
    };

    const toggleProspective = (e) => {
        setIsProspectiveActive(e.target.checked);
    };

    const toggleRetrospective = (e) => {
        setIsRetrospectiveActive(e.target.checked);
    };

    const togglePgoVendor = (e) => {
        setIsPgoVendorActive(e.target.checked);
    };

    const toggleContracted = (e) => {
        setIsContractedActive(e.target.checked);
        if (e.target.checked == true) {
            setIsProspectiveActive(e.target.checked);
        }
    };

    const handleEffectiveDateChange = (e) => {
        setEffectiveDate(e.target.value);
    };

    const handleTerminateDateChange = (e) => {
        setTerminateDate(e.target.value);
    }

    const handleCredentialedDateChange = (e) => {
        setCredentialedDate(e.target.value);
    }

    const handleRecredentialedDateChange = (e) => {
        setRecredentialedDate(e.target.value);
    }

    const handleTypeAheadSearch = event => {
        const name = event.target.name;
        const value = event.target.value;
        event.persist();
        setActiveField(name);

        if (name === 'zipCode') {
            setZipCode(value);
            if (value.length > 3) {
                const params = {
                    userId: userId,
                    zipCode: value,
                };
                getZipList(params);
            } else if (value.length > 0 && zipList.length) {
                resetZipList();
            }
        } else if (name === 'remitZip') {
            setRemitZipCode(value);
            if (value.length > 3) {
                const params = {
                    userId: userId,
                    zipCode: value,
                };
                getZipList(params);
            } else if (value.length > 0 && zipList.length) {
                resetZipList();
            }
        }
    };

    const handleZipCodeClick = (zip) => {
        setZipCode(zip.chr_zipcode);
        setCity(zip.chr_city);
        setCounty(zip.chr_county);
        setState(zip.chr_state);
        setAddress(zip);
        resetZipList();
    };

    const handleRemitZipCodeClick = (zip) => {
        setRemitZipCode(zip.chr_zipcode);
        setRemitCity(zip.chr_city);
        setRemitCounty(zip.chr_county);
        setRemitState(zip.chr_state);
        setRemitAddress(zip);
        resetZipList();
    };

    const handleStreetChange = (e) => {
        const name = e.target.name;
        if (name === 'remitStreet') {
            setRemitStreetText(e.target.value);
        } else if (name === 'street') {
            setStreetText(e.target.value);
        }
    }

    const handleStreet2Change = (e) => {
        const name = e.target.name;
        if (name === 'remitStreet2') {
            setRemitStreet2Text(e.target.value);
        } else if (name === 'street2') {
            setStreet2Text(e.target.value);
        }
    }

    const handlevendorTypeSelect = (event) => {
        const selectedAbbreviation = event.target.value;
        const selectedService = services.find(service => service.abbreviation === selectedAbbreviation);
        const parentId = selectedService ? selectedService.parentId : null;
        const vendorTypeName = selectedService ? selectedService.displayName : null;
        const vendorTypeVal = selectedAbbreviation;
        setVendorType(vendorTypeVal);
        setVendorTypeId(parentId);
        setVendorTypeName(vendorTypeName);
    }

    const handleFirmNameChange = (e) => {
        setFirmNameText(e.target.value);
    }

    const handleDbaChange = (e) => {
        setDbaText(e.target.value);
    }

    const handleAccountNumberChange = (e) => {
        setAccountNumber(e.target.value);
    }
    const toggleNationwide = (e) => {
        setIsNationwideActive(e.target.checked);
    }
    const togglePreferred = (e) => {
        setIsPreferredActive(e.target.checked);
    }
    const handleContactEmail1Change = (e) => {
        setPrimaryContactEmail1(e.target.value);
    }
    const handleContactEmail2Change = (e) => {
        setPrimaryContactEmail2(e.target.value);
    }
    const handleFirmEmailChange = (e) => {
        setFirmEmail(e.target.value);
    }
    const handleFieldChange = (e) => {
        const name = e.target.name;
        if (name === "website") {
            setWebsiteText(e.target.value);
        } else if (name === "mainPhone") {
            //const phNum = formatPhoneNumber(e.target.value);
            setMainPhoneNumber(e.target.value)
        } else if (name === 'phoneExt') {
            setPhoneExt(e.target.value)
        } else if (name === 'alternatePhone') {
            const altPhNum = formatPhoneNumber(e.target.value);
            setAlternatePhoneNumber(altPhNum)
        } else if (name === 'cellPhone') {
            const cellPhNum = formatPhoneNumber(e.target.value);
            setCellPhoneNumber(cellPhNum);
        } else if (name === 'fax') {
            const faxNum = formatPhoneNumber(e.target.value);
            setFaxNumber(faxNum);
        } else if (name === 'inactiveReason') {
            setInActiveReason(e.target.value);
        } else if (name === 'paymentType') {
            setPaymentType(e.target.value);
        } else if (name === "gpVendor") {
            setGpVendor(e.target.value);
        } else if (name === 'gpCode') {
            setGpCode(e.target.value);
        } else if (name === 'npi') {
            setNpiVal(e.target.value);
        } else if (name === 'tin') {
            setTinVal(e.target.value);
        } else if (name === 'licenseNo') {
            setLicenseNum(e.target.value);
        } else if (name === 'paymentTerms') {
            setPaymentTermsVal(e.target.value);
        } else if (name === 'submissionTerms') {
            setSubmissionTermsVal(e.target.value);
        } else if (name === 'sapId') {
            setSapIdVal(e.target.value);
        } else if (name === 'achAccountNo') {
            setAchAccountNum(e.target.value);
        } else if (name === 'notes') {
            setNotesText(e.target.value);
        } else if (name === 'alert') {
            setAlertText(e.target.value);
        } else if (name === 'groupName') {
            setGroupNameVal(e.target.value);
        } else if (name === 'altFirmEmail') {
            setAltFirmEmail(e.target.value);
        }
    }

    const formatPhoneNumber = value => {
        let phoneNumber = value;
        let formattedNumber;
        phoneNumber = phoneNumber.replace(/[^0-9]/g, '');
        const areaCode = phoneNumber.substr(0, 3);
        const firstPart = phoneNumber.substr(3, 3);
        const secondPart = phoneNumber.substr(6, 4);
        if (areaCode.length === 0) {
            formattedNumber = '';
        } else if (areaCode.length < 3) {
            formattedNumber = '(' + areaCode;
        } else if (areaCode.length === 3 && firstPart.length < 3) {
            formattedNumber = '(' + areaCode + ') ' + firstPart;
        } else if (areaCode.length === 3 && firstPart.length === 3) {
            formattedNumber = '(' + areaCode + ') ' + firstPart + ' - ' + secondPart;
        }

        return formattedNumber;
    };

    const unformatPhoneNumber = formattedNumber => {
        return formattedNumber.replace(/[^0-9]/g, '');
    };

    useEffect(() => {
        if (!isEmpty(vendorInfo)) {
            const payload = {
                "functionName" : "getNewVendor",
                "name" :firmNameText
            }
            getNewVendor(payload);
        }
    },[vendorInfo])

    useEffect(() => {
        if (loadNewVendorInfo && loadNewVendorInfo.length && loadNewVendorInfo.length > 0) {
            setIsAfterVendorSaved(true);
            setVendorId(parseInt(loadNewVendorInfo[0].id))
        }

    }, [loadNewVendorInfo])

    useEffect(() => {
        if ((newContactInfo !== undefined || newContactInfo > 0 ) && vendorId == 0) {
            let response = {
                "zebraId": userId,
                "functionName": "saveVendor",
                "vendorId": 0,
                "id": 0,
                "profile": "",
                "loadFees": true,
                "vendor": {
                    "parentId": 0,
                    "address": {
                        "zipCodeId": address.code_id,
                        "zipCode": {
                            "city": city,
                            "state": state,
                            "zip": zipCode,
                            "county": county,
                            "timeZone": address.chr_timezone,
                            "id": address.code_id
                        },
                        "street1": streetText,
                        "street2": street2Text,
                        "active": false,
                        "permAddress": false,
                        "id": 0
                    },
                    "remitAddress": {
                        "zipCodeId": remitAddress.code_id,
                        "zipCode": {
                            "city": remitCity,
                            "state": remitState,
                            "zip": remitZipCode,
                            "county": remitCounty,
                            "timeZone": remitAddress.chr_timezone,
                            "id": remitAddress.code_id
                        },
                        "street1": remitStreetText,
                        "street2": remitStreet2Text,
                        "active": false,
                        "permAddress": false,
                        "id": 0
                    },
                    "addressId": 0,
                    "remitAddressId": 0,
                    "primaryContact": {
                        "peopleId": newContactInfo,
                        "people": {
                            "first": primaryContactFName,
                            "last": primaryContactLName,
                            "email1": primaryContactEmail1,
                            "email2": primaryContactEmail2,
                            "id": newContactInfo
                        },
                        "contactTypeId": 58,
                        "active": true,
                        "primary": false,
                        "referralReceived": false,
                        "qaAssignedConfirmed": false,
                        "psAssignedConfirmed": false,
                        "vosAssignedConfirmed": false,
                        "orderCancelledBilled": false,
                        "id": 0
                    },
                    "primaryContactId": 0,
                    "level": 0,
                    "name": firmNameText,
                    "dbaName": dbaText,
                    "taxId": tinVal,
                    "npi": npiVal,
                    "alert": alertText,
                    "phone": unformatPhoneNumber(mainPhoneNumber),
                    "phoneExt": phoneExt,
                    "altPhone": unformatPhoneNumber(alternatePhoneNumber),
                    "fax": unformatPhoneNumber(faxNumber),
                    "cellPhone": unformatPhoneNumber(cellPhoneNumber),
                    "email1": firmEmail,
                    "email2": altFirmEmail,
                    "webSite": websiteText,
                    "active": isActive,
                    "preferred": isPreferredActive,
                    "licenseNo": licenseNum,
                    "inactiveReason": inActiveReason,
                    "notes": notesText,
                    "contactList": [
                        {
                            "peopleId": newContactInfo,
                            "vendorContactId": 0,
                            "people": {
                                "first": primaryContactFName,
                                "last": primaryContactLName,
                                "email1": primaryContactEmail1,
                                "email2": primaryContactEmail2,
                                "id": newContactInfo
                            },
                            "contactTypeId": 58,
                            "active": true,
                            "primary": true,
                            "referralReceived": false,
                            "qaAssignedConfirmed": false,
                            "psAssignedConfirmed": false,
                            "vosAssignedConfirmed": false,
                            "orderCancelledBilled": false,
                            "id": 0
                        }
                    ],
                    "serviceList": [],
                    "accountNumber": accountNumber,
                    "terms": paymentTermsVal,
                    "submissionTerms": submissionTermsVal,
                    "paymentType": paymentType,
                    "gpName": gpVendor,
                    "gpCode": gpCode,
                    "sapId": sapIdVal,
                    "nationwide": isNationwideActive,
                    "hasChildren": false,
                    "feeCount": 0,
                    "contractedDate": effectiveDate,
                    "credentialedDate": credentialedDate,
                    "endDate": terminateDate,
                    "recredentialedDate": recredentialedDate,
                    "prospective": isProspectiveActive,
                    "retrospective": isRetrospectiveActive,
                    "pgo": isPgoVendorActive,
                    "mainServiceId": vendorTypeId,
                    "mainService": vendorTypeName,
                    "id": 0,
                    "contracted": isContractedActive,
                    "paymentMethod": paymentMethod
                }
            }

            if (vendorTypeName === '') {
                alert('You must select a vendor type.');
            } else if (firmNameText === '') {
                alert('You must enter a vendor name');
            } else if (streetText === "" || zipCode === "") {
                alert("You must enter a complete address.");
            } else if (primaryContactFName === "" || primaryContactLName === "") {
                alert("You must enter the primary contact's first and last name.");
            } else if (primaryContactFName === "" || primaryContactLName === "") {
                alert("You must enter the primary contact's first and last name.");
            } else if (npiVal.length < 10) {
                alert("You must obtain the NPI for that provider before saving record and it must be 10 digit numerical format only. No special characters or dashes are allowed");
            } else if (tinVal.length < 9) {
                alert("You must enter a TIN and it must be 9 digit numerical format only. No special characters or dashes are allowed");
            } else if (tinVal.length == 9 && (tinVal == 123456789 || tinVal == 987654321)) {
                alert("Invalid TIN; please re-enter");
            } else {
                saveVendor(response);
            }
        } else if ((newContactInfo !== undefined || newContactInfo > 0 ) && isAfterVendorSaved && vendorId > 0) {
            let response = {
                "zebraId": userId,
                "functionName": "saveVendor",
                "vendorId": vendorId,
                "id": vendorId,
                "profile": "",
                "loadFees": true,
                "vendor": {
                    "parentId": 0,
                    "address": {
                        "zipCodeId": address.code_id,
                        "zipCode": {
                            "city": city,
                            "state": state,
                            "zip": zipCode,
                            "county": county,
                            "timeZone": address.chr_timezone,
                            "id": address.code_id
                        },
                        "street1": streetText,
                        "street2": street2Text,
                        "active": false,
                        "permAddress": false,
                        "id": 0
                    },
                    "remitAddress": {
                        "zipCodeId": remitAddress.code_id,
                        "zipCode": {
                            "city": remitCity,
                            "state": remitState,
                            "zip": remitZipCode,
                            "county": remitCounty,
                            "timeZone": remitAddress.chr_timezone,
                            "id": remitAddress.code_id
                        },
                        "street1": remitStreetText,
                        "street2": remitStreet2Text,
                        "active": false,
                        "permAddress": false,
                        "id": 0
                    },
                    "addressId": 0,
                    "remitAddressId": 0,
                    "primaryContact": {
                        "peopleId": newContactInfo,
                        "people": {
                            "first": primaryContactFName,
                            "last": primaryContactLName,
                            "email1": primaryContactEmail1,
                            "email2": primaryContactEmail2,
                            "id": newContactInfo
                        },
                        "contactTypeId": 58,
                        "active": true,
                        "primary": false,
                        "referralReceived": false,
                        "qaAssignedConfirmed": false,
                        "psAssignedConfirmed": false,
                        "vosAssignedConfirmed": false,
                        "orderCancelledBilled": false,
                        "id": 0
                    },
                    "primaryContactId": 0,
                    "level": 0,
                    "name": firmNameText,
                    "dbaName": dbaText,
                    "taxId": tinVal,
                    "npi": npiVal,
                    "alert": alertText,
                    "phone": unformatPhoneNumber(mainPhoneNumber),
                    "phoneExt": phoneExt,
                    "altPhone": unformatPhoneNumber(alternatePhoneNumber),
                    "fax": unformatPhoneNumber(faxNumber),
                    "cellPhone": unformatPhoneNumber(cellPhoneNumber),
                    "email1": firmEmail,
                    "email2": altFirmEmail,
                    "webSite": websiteText,
                    "active": isActive,
                    "preferred": isPreferredActive,
                    "licenseNo": licenseNum,
                    "inactiveReason": inActiveReason,
                    "notes": notesText,
                    "contactList": [
                        {
                            "peopleId": newContactInfo,
                            "vendorContactId": 0,
                            "people": {
                                "first": primaryContactFName,
                                "last": primaryContactLName,
                                "email1": primaryContactEmail1,
                                "email2": primaryContactEmail2,
                                "id": newContactInfo
                            },
                            "contactTypeId": 58,
                            "active": true,
                            "primary": true,
                            "referralReceived": false,
                            "qaAssignedConfirmed": false,
                            "psAssignedConfirmed": false,
                            "vosAssignedConfirmed": false,
                            "orderCancelledBilled": false,
                            "id": 0
                        }
                    ],
                    "serviceList": [],
                    "accountNumber": accountNumber,
                    "terms": paymentTermsVal,
                    "submissionTerms": submissionTermsVal,
                    "paymentType": paymentType,
                    "gpName": gpVendor,
                    "gpCode": gpCode,
                    "sapId": sapIdVal,
                    "nationwide": isNationwideActive,
                    "hasChildren": false,
                    "feeCount": 0,
                    "contractedDate": effectiveDate,
                    "credentialedDate": credentialedDate,
                    "endDate": terminateDate,
                    "recredentialedDate": recredentialedDate,
                    "prospective": isProspectiveActive,
                    "retrospective": isRetrospectiveActive,
                    "pgo": isPgoVendorActive,
                    "mainServiceId": vendorTypeId,
                    "mainService": vendorTypeName,
                    "id": vendorId,
                    "contracted": isContractedActive,
                    "paymentMethod": paymentMethod
                }
            }

            if (vendorTypeName === '') {
                alert('You must select a vendor type.');
            } else if (firmNameText === '') {
                alert('You must enter a vendor name');
            } else if (streetText === "" || zipCode === "") {
                alert("You must enter a complete address.");
            } else if (primaryContactFName === "" || primaryContactLName === "") {
                alert("You must enter the primary contact's first and last name.");
            } else if (primaryContactFName === "" || primaryContactLName === "") {
                alert("You must enter the primary contact's first and last name.");
            } else if (npiVal.length < 10) {
                alert("You must obtain the NPI for that provider before saving record and it must be 10 digit numerical format only. No special characters or dashes are allowed");
            } else if (tinVal.length < 9) {
                alert("You must enter a TIN and it must be 9 digit numerical format only. No special characters or dashes are allowed");
            } else if (tinVal.length == 9 && (tinVal == 123456789 || tinVal == 987654321)) {
                alert("Invalid TIN; please re-enter");
            } else {
                saveVendor(response);
            }
        }

    }, [newContactInfo])
    
    const handleInputBlur = (fieldType) => {
        setTimeout(() => {
            if(fieldType === 'firstname') {
                setShowFirstNameSuggestions(false);
            } else if(fieldType === 'lastname') {
                setShowLastNameSuggestions(false);
            }
        }, 150)
    }

    const onSave = async () => {
        let response = {};

        if ((suggestionContact.chr_firstname !== primaryContactFName) && (suggestionContact.chr_lastname !== primaryContactLName) && vendorId == 0) {
            if (vendorTypeName === '') {
                alert('You must select a vendor type.');
            } else if (firmNameText === '') {
                alert('You must enter a vendor name');
            } else if (streetText === "" || zipCode === "") {
                alert("You must enter a complete address.");
            } else if (primaryContactFName === "" || primaryContactLName === "") {
                alert("You must enter the primary contact's first and last name.");
            } else if (primaryContactFName === "" || primaryContactLName === "") {
                alert("You must enter the primary contact's first and last name.");
            } else if (npiVal.length < 10) {
                alert("You must obtain the NPI for that provider before saving record and it must be 10 digit numerical format only. No special characters or dashes are allowed");
            } else if (tinVal.length < 9) {
                alert("You must enter a TIN and it must be 9 digit numerical format only. No special characters or dashes are allowed");
            } else if (tinVal.length == 9 && (tinVal == 123456789 || tinVal == 987654321)) {
                alert("Invalid TIN; please re-enter");
        }   else{
            let contactResponse = {}
            contactResponse.active = isActive;
            contactResponse.contactTypeId = 0;
            contactResponse.peopleId = 0;
            contactResponse.id = 0;
            contactResponse.functionName = 'saveNewContact';
            contactResponse.zebraUserId = userId;
            contactResponse = {
                first: primaryContactFName,
                last: primaryContactLName,
                email1: primaryContactEmail1,
                email2: primaryContactEmail2,
                phone: unformatPhoneNumber(mainPhoneNumber),
                phoneExt: phoneExt,
                fax: unformatPhoneNumber(faxNumber),
                id: 0,
                cellPhone: unformatPhoneNumber(cellPhoneNumber),
            }
            saveNewContact(contactResponse);
        }
    }else if((suggestionContact.chr_firstname !== primaryContactFName) && (suggestionContact.chr_lastname !== primaryContactLName) &&
    (primaryContactFName == loadNewVendorInfo[0].primaryContact.people.first) &&  (primaryContactLName == loadNewVendorInfo[0].primaryContact.people.last) && (vendorId > 0)) {
        const peopleObj = loadNewVendorInfo && loadNewVendorInfo.length && loadNewVendorInfo[0].primaryContact.people;
        response = {
            "zebraId": userId,
            "functionName": "saveVendor",
            "vendorId": vendorId,
            "id": vendorId,
            "profile": "",
            "loadFees": true,
            "vendor": {
                "parentId": 0,
                "address": {
                    "zipCodeId": address.code_id,
                    "zipCode": {
                        "city": city,
                        "state": state,
                        "zip": zipCode,
                        "county": county,
                        "timeZone": address.chr_timezone,
                        "id": address.code_id
                    },
                    "street1": streetText,
                    "street2": street2Text,
                    "active": false,
                    "permAddress": false,
                    "id": 0
                },
                "remitAddress": {
                    "zipCodeId": remitAddress.code_id,
                    "zipCode": {
                        "city": remitCity,
                        "state": remitState,
                        "zip": remitZipCode,
                        "county": remitCounty,
                        "timeZone": remitAddress.chr_timezone,
                        "id": remitAddress.code_id
                    },
                    "street1": remitStreetText,
                    "street2": remitStreet2Text,
                    "active": false,
                    "permAddress": false,
                    "id": 0
                },
                "addressId": 0,
                "remitAddressId": 0,
                "primaryContact": {
                    "peopleId": loadNewVendorInfo && loadNewVendorInfo.length && loadNewVendorInfo[0].primaryContact.peopleId,
                    "people": {
                        "first": peopleObj.first,
                        "last": peopleObj.last,
                        "email1": primaryContactEmail1,
                        "email2": primaryContactEmail2,
                        "id": loadNewVendorInfo && loadNewVendorInfo.length && loadNewVendorInfo[0].primaryContact.peopleId
                    },
                    "contactTypeId": 58,
                    "active": true,
                    "primary": false,
                    "referralReceived": false,
                    "qaAssignedConfirmed": false,
                    "psAssignedConfirmed": false,
                    "vosAssignedConfirmed": false,
                    "orderCancelledBilled": false,
                    "id": peopleObj.id
                },
                "primaryContactId": loadNewVendorInfo && loadNewVendorInfo.length && loadNewVendorInfo[0].primaryContactId,
                "level": 0,
                "name": firmNameText,
                "dbaName": dbaText,
                "taxId": tinVal,
                "npi": npiVal,
                "alert": alertText,
                "phone": unformatPhoneNumber(mainPhoneNumber),
                "phoneExt": phoneExt,
                "altPhone": unformatPhoneNumber(alternatePhoneNumber),
                "fax": unformatPhoneNumber(faxNumber),
                "cellPhone": unformatPhoneNumber(cellPhoneNumber),
                "email1": firmEmail,
                "email2": altFirmEmail,
                "webSite": websiteText,
                "active": isActive,
                "preferred": isPreferredActive,
                "licenseNo": licenseNum,
                "inactiveReason": inActiveReason,
                "notes": notesText,
                "contactList": [
                    {
                        "peopleId": suggestionContact.people_id,
                        "vendorContactId": 0,
                        "people": {
                            "first": primaryContactFName,
                            "last": primaryContactLName,
                            "email1": primaryContactEmail1,
                            "email2": primaryContactEmail2,
                            "id": suggestionContact.people_id
                        },
                        "contactTypeId": 58,
                        "active": true,
                        "primary": true,
                        "referralReceived": false,
                        "qaAssignedConfirmed": false,
                        "psAssignedConfirmed": false,
                        "vosAssignedConfirmed": false,
                        "orderCancelledBilled": false,
                        "id": suggestionContact.contact_id
                    }
                ],
                "serviceList": [],
                "accountNumber": accountNumber,
                "terms": paymentTermsVal,
                "submissionTerms": submissionTermsVal,
                "paymentType": paymentType,
                "gpName": gpVendor,
                "gpCode": gpCode,
                "sapId": sapIdVal,
                "nationwide": isNationwideActive,
                "hasChildren": false,
                "feeCount": 0,
                "contractedDate": effectiveDate,
                "credentialedDate": credentialedDate,
                "endDate": terminateDate,
                "recredentialedDate": recredentialedDate,
                "prospective": isProspectiveActive,
                "retrospective": isRetrospectiveActive,
                "pgo": isPgoVendorActive,
                "mainServiceId": vendorTypeId,
                "mainService": vendorTypeName,
                "id": vendorId,
                "contracted": isContractedActive,
                "paymentMethod": paymentMethod
            }
        }
        if (vendorTypeName === '') {
            alert('You must select a vendor type.');
        } else if (firmNameText === '') {
            alert('You must enter a vendor name');
        } else if (streetText === "" || zipCode === "") {
            alert("You must enter a complete address.");
        } else if (primaryContactFName === "" || primaryContactLName === "") {
            alert("You must enter the primary contact's first and last name.");
        } else if (primaryContactFName === "" || primaryContactLName === "") {
            alert("You must enter the primary contact's first and last name.");
        } else if (npiVal.length < 10) {
            alert("You must obtain the NPI for that provider before saving record and it must be 10 digit numerical format only. No special characters or dashes are allowed");
        } else if (tinVal.length < 9) {
            alert("You must enter a TIN and it must be 9 digit numerical format only. No special characters or dashes are allowed");
        } else if (tinVal.length == 9 && (tinVal == 123456789 || tinVal == 987654321)) {
            alert("Invalid TIN; please re-enter");
        } else {
            saveVendor(response);
        }

        } else if ((suggestionContact.chr_firstname === primaryContactFName) && (suggestionContact.chr_lastname === primaryContactLName) && vendorId == 0) {
            response = {
                "zebraId": userId,
                "functionName": "saveVendor",
                "vendorId": 0,
                "id": 0,
                "profile": "",
                "loadFees": true,
                "vendor": {
                    "parentId": 0,
                    "address": {
                        "zipCodeId": address.code_id,
                        "zipCode": {
                            "city": city,
                            "state": state,
                            "zip": zipCode,
                            "county": county,
                            "timeZone": address.chr_timezone,
                            "id": address.code_id
                        },
                        "street1": streetText,
                        "street2": street2Text,
                        "active": false,
                        "permAddress": false,
                        "id": 0
                    },
                    "remitAddress": {
                        "zipCodeId": remitAddress.code_id,
                        "zipCode": {
                            "city": remitCity,
                            "state": remitState,
                            "zip": remitZipCode,
                            "county": remitCounty,
                            "timeZone": remitAddress.chr_timezone,
                            "id": remitAddress.code_id
                        },
                        "street1": remitStreetText,
                        "street2": remitStreet2Text,
                        "active": false,
                        "permAddress": false,
                        "id": 0
                    },
                    "addressId": 0,
                    "remitAddressId": 0,
                    "primaryContact": {
                        "peopleId": suggestionContact.people_id,
                        "people": {
                            "first": primaryContactFName,
                            "last": primaryContactLName,
                            "email1": primaryContactEmail1,
                            "email2": primaryContactEmail2,
                            "id": suggestionContact.people_id
                        },
                        "contactTypeId": 58,
                        "active": true,
                        "primary": false,
                        "referralReceived": false,
                        "qaAssignedConfirmed": false,
                        "psAssignedConfirmed": false,
                        "vosAssignedConfirmed": false,
                        "orderCancelledBilled": false,
                        "id": suggestionContact.contact_id
                    },
                    "primaryContactId": suggestionContact.contact_id,
                    "level": 0,
                    "name": firmNameText,
                    "dbaName": dbaText,
                    "taxId": tinVal,
                    "npi": npiVal,
                    "alert": alertText,
                    "phone": unformatPhoneNumber(mainPhoneNumber),
                    "phoneExt": phoneExt,
                    "altPhone": unformatPhoneNumber(alternatePhoneNumber),
                    "fax": unformatPhoneNumber(faxNumber),
                    "cellPhone": unformatPhoneNumber(cellPhoneNumber),
                    "email1": firmEmail,
                    "email2": altFirmEmail,
                    "webSite": websiteText,
                    "active": isActive,
                    "preferred": isPreferredActive,
                    "licenseNo": licenseNum,
                    "inactiveReason": inActiveReason,
                    "notes": notesText,
                    "contactList": [
                        {
                            "peopleId": suggestionContact.people_id,
                            "vendorContactId": 0,
                            "people": {
                                "first": primaryContactFName,
                                "last": primaryContactLName,
                                "email1": primaryContactEmail1,
                                "email2": primaryContactEmail2,
                                "id": suggestionContact.people_id
                            },
                            "contactTypeId": 58,
                            "active": true,
                            "primary": true,
                            "referralReceived": false,
                            "qaAssignedConfirmed": false,
                            "psAssignedConfirmed": false,
                            "vosAssignedConfirmed": false,
                            "orderCancelledBilled": false,
                            "id": suggestionContact.contact_id
                        }
                    ],
                    "serviceList": [],
                    "accountNumber": accountNumber,
                    "terms": paymentTermsVal,
                    "submissionTerms": submissionTermsVal,
                    "paymentType": paymentType,
                    "gpName": gpVendor,
                    "gpCode": gpCode,
                    "sapId": sapIdVal,
                    "nationwide": isNationwideActive,
                    "hasChildren": false,
                    "feeCount": 0,
                    "contractedDate": effectiveDate,
                    "credentialedDate": credentialedDate,
                    "endDate": terminateDate,
                    "recredentialedDate": recredentialedDate,
                    "prospective": isProspectiveActive,
                    "retrospective": isRetrospectiveActive,
                    "pgo": isPgoVendorActive,
                    "mainServiceId": vendorTypeId,
                    "mainService": vendorTypeName,
                    "id": 0,
                    "contracted": isContractedActive,
                    "paymentMethod": paymentMethod
                }
            }
            if (vendorTypeName === '') {
                alert('You must select a vendor type.');
            } else if (firmNameText === '') {
                alert('You must enter a vendor name');
            } else if (streetText === "" || zipCode === "") {
                alert("You must enter a complete address.");
            } else if (primaryContactFName === "" || primaryContactLName === "") {
                alert("You must enter the primary contact's first and last name.");
            } else if (primaryContactFName === "" || primaryContactLName === "") {
                alert("You must enter the primary contact's first and last name.");
            } else if (npiVal.length < 10) {
                alert("You must obtain the NPI for that provider before saving record and it must be 10 digit numerical format only. No special characters or dashes are allowed");
            } else if (tinVal.length < 9) {
                alert("You must enter a TIN and it must be 9 digit numerical format only. No special characters or dashes are allowed");
            } else if (tinVal.length == 9 && (tinVal == 123456789 || tinVal == 987654321)) {
                alert("Invalid TIN; please re-enter");
            } else {
                saveVendor(response);
            }
        } else if ( isAfterVendorSaved && vendorId > 0 && (suggestionContact.chr_firstname !== primaryContactFName) && (suggestionContact.chr_lastname !== primaryContactLName)) {
            if (vendorTypeName === '') {
                alert('You must select a vendor type.');
            } else if (firmNameText === '') {
                alert('You must enter a vendor name');
            } else if (streetText === "" || zipCode === "") {
                alert("You must enter a complete address.");
            } else if (primaryContactFName === "" || primaryContactLName === "") {
                alert("You must enter the primary contact's first and last name.");
            } else if (primaryContactFName === "" || primaryContactLName === "") {
                alert("You must enter the primary contact's first and last name.");
            } else if (npiVal.length < 10) {
                alert("You must obtain the NPI for that provider before saving record and it must be 10 digit numerical format only. No special characters or dashes are allowed");
            } else if (tinVal.length < 9) {
                alert("You must enter a TIN and it must be 9 digit numerical format only. No special characters or dashes are allowed");
            } else if (tinVal.length == 9 && (tinVal == 123456789 || tinVal == 987654321)) {
                alert("Invalid TIN; please re-enter");
        }   else{
            let contactResponse = {}
            contactResponse.active = isActive;
            contactResponse.contactTypeId = 0;
            contactResponse.peopleId = 0;
            contactResponse.id = 0;
            contactResponse.functionName = 'saveNewContact';
            contactResponse.zebraUserId = userId;
            contactResponse = {
                first: primaryContactFName,
                last: primaryContactLName,
                email1: primaryContactEmail1,
                email2: primaryContactEmail2,
                phone: unformatPhoneNumber(mainPhoneNumber),
                phoneExt: phoneExt,
                fax: unformatPhoneNumber(faxNumber),
                id: 0,
                cellPhone: unformatPhoneNumber(cellPhoneNumber),
            }
            saveNewContact(contactResponse);
        }
        } else if ( isAfterVendorSaved && vendorId > 0 && (suggestionContact.chr_firstname === primaryContactFName) && (suggestionContact.chr_lastname === primaryContactLName)) {
            response = {
                "zebraId": userId,
                "functionName": "saveVendor",
                "vendorId": vendorId,
                "id": vendorId,
                "profile": "",
                "loadFees": true,
                "vendor": {
                    "parentId": 0,
                    "address": {
                        "zipCodeId": address.code_id,
                        "zipCode": {
                            "city": city,
                            "state": state,
                            "zip": zipCode,
                            "county": county,
                            "timeZone": address.chr_timezone,
                            "id": address.code_id
                        },
                        "street1": streetText,
                        "street2": street2Text,
                        "active": false,
                        "permAddress": false,
                        "id": 0
                    },
                    "remitAddress": {
                        "zipCodeId": remitAddress.code_id,
                        "zipCode": {
                            "city": remitCity,
                            "state": remitState,
                            "zip": remitZipCode,
                            "county": remitCounty,
                            "timeZone": remitAddress.chr_timezone,
                            "id": remitAddress.code_id
                        },
                        "street1": remitStreetText,
                        "street2": remitStreet2Text,
                        "active": false,
                        "permAddress": false,
                        "id": 0
                    },
                    "addressId": 0,
                    "remitAddressId": 0,
                    "primaryContact": {
                        "peopleId": suggestionContact.people_id,
                        "people": {
                            "first": primaryContactFName,
                            "last": primaryContactLName,
                            "email1": primaryContactEmail1,
                            "email2": primaryContactEmail2,
                            "id": suggestionContact.people_id
                        },
                        "contactTypeId": 58,
                        "active": true,
                        "primary": false,
                        "referralReceived": false,
                        "qaAssignedConfirmed": false,
                        "psAssignedConfirmed": false,
                        "vosAssignedConfirmed": false,
                        "orderCancelledBilled": false,
                        "id": suggestionContact.contact_id
                    },
                    "primaryContactId": suggestionContact.contact_id,
                    "level": 0,
                    "name": firmNameText,
                    "dbaName": dbaText,
                    "taxId": tinVal,
                    "npi": npiVal,
                    "alert": alertText,
                    "phone": unformatPhoneNumber(mainPhoneNumber),
                    "phoneExt": phoneExt,
                    "altPhone": unformatPhoneNumber(alternatePhoneNumber),
                    "fax": unformatPhoneNumber(faxNumber),
                    "cellPhone": unformatPhoneNumber(cellPhoneNumber),
                    "email1": firmEmail,
                    "email2": altFirmEmail,
                    "webSite": websiteText,
                    "active": isActive,
                    "preferred": isPreferredActive,
                    "licenseNo": licenseNum,
                    "inactiveReason": inActiveReason,
                    "notes": notesText,
                    "contactList": [
                        {
                            "peopleId": suggestionContact.people_id,
                            "vendorContactId": 0,
                            "people": {
                                "first": primaryContactFName,
                                "last": primaryContactLName,
                                "email1": primaryContactEmail1,
                                "email2": primaryContactEmail2,
                                "id": suggestionContact.people_id
                            },
                            "contactTypeId": 58,
                            "active": true,
                            "primary": true,
                            "referralReceived": false,
                            "qaAssignedConfirmed": false,
                            "psAssignedConfirmed": false,
                            "vosAssignedConfirmed": false,
                            "orderCancelledBilled": false,
                            "id": suggestionContact.contact_id
                        }
                    ],
                    "serviceList": [],
                    "accountNumber": accountNumber,
                    "terms": paymentTermsVal,
                    "submissionTerms": submissionTermsVal,
                    "paymentType": paymentType,
                    "gpName": gpVendor,
                    "gpCode": gpCode,
                    "sapId": sapIdVal,
                    "nationwide": isNationwideActive,
                    "hasChildren": false,
                    "feeCount": 0,
                    "contractedDate": effectiveDate,
                    "credentialedDate": credentialedDate,
                    "endDate": terminateDate,
                    "recredentialedDate": recredentialedDate,
                    "prospective": isProspectiveActive,
                    "retrospective": isRetrospectiveActive,
                    "pgo": isPgoVendorActive,
                    "mainServiceId": vendorTypeId,
                    "mainService": vendorTypeName,
                    "id": vendorId,
                    "contracted": isContractedActive,
                    "paymentMethod": paymentMethod
                }
            }
            if (vendorTypeName === '') {
                alert('You must select a vendor type.');
            } else if (firmNameText === '') {
                alert('You must enter a vendor name');
            } else if (streetText === "" || zipCode === "") {
                alert("You must enter a complete address.");
            } else if (primaryContactFName === "" || primaryContactLName === "") {
                alert("You must enter the primary contact's first and last name.");
            } else if (primaryContactFName === "" || primaryContactLName === "") {
                alert("You must enter the primary contact's first and last name.");
            } else if (npiVal.length < 10) {
                alert("You must obtain the NPI for that provider before saving record and it must be 10 digit numerical format only. No special characters or dashes are allowed");
            } else if (tinVal.length < 9) {
                alert("You must enter a TIN and it must be 9 digit numerical format only. No special characters or dashes are allowed");
            } else if (tinVal.length == 9 && (tinVal == 123456789 || tinVal == 987654321)) {
                alert("Invalid TIN; please re-enter");
            } else {
                saveVendor(response);
            }
        
    };
}


    return (
        <div>
            <Row className="align-items-right" style={{ backgroundColor: "#5f6f8f" }}>
                <Col lg="2" style={{ marginTop: "6px", color: "white" }}>
                    <span>Level:
                    </span>
                </Col>
                <Col lg="2" style={{ marginTop: "6px", color: "white" }}>
                    <span>Path:</span>

                </Col>
                <Col lg="1" style={{ marginLeft: "auto" }}>
                    <Button
                        style={{ width: '100%', color: "white", backgroundColor: "steelblue" }}
                        type="button"
                        onClick={onExit}

                    >
                        EXIT
                    </Button>
                </Col>
            </Row>
            <Form className="vendor-form" onKeyPress={handleKeyPress}>
                {/* Vendor Demographic Section */}
                Basic Info
                <div className="form-section">
                    <Row form className="align-items-center">
                        <Col md={6}>
                            <h5>VENDOR DEMOGRAPHIC</h5>
                        </Col>
                        <Col md={6} className="text-right">
                            <Button color="primary" className="save-button" onClick={onSave}>Save</Button>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="vendorType">Vendor Type <span className='red-text'>*</span></Label>
                                <Input
                                    type="select"
                                    name="vendorType"
                                    id="vendorType"
                                    autoComplete="new-password"
                                    value={vendorType}
                                    style={{ border: "1px solid red" }}
                                    onChange={handlevendorTypeSelect}>
                                    <option value="" disabled hidden>Select a service</option>
                                    {services.filter(option => option.abbreviation !== 'ALL')
                                        .map((option, index) => (
                                            <option key={index} value={option.abbreviation}>{option.displayName}</option>
                                        ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="firmName">Firm Name <span className='red-text'>*</span></Label>
                                <Input type="text" name="firmName" id="firmName" style={{ border: "1px solid red" }} value={firmNameText}
                                    onChange={handleFirmNameChange} autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup>
                                <Label for="dbaName">DBA Name</Label>
                                <Input type="text" name="dbaName" id="dbaName" value={dbaText}
                                    onChange={handleDbaChange} autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup>
                                <Label for="accountNumber">Account Number</Label>
                                <Input type="text" name="accountNumber" id="accountNumber" value={accountNumber}
                                    onChange={handleAccountNumberChange} autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup className="form-group">
                                <Label for="primaryContactFirstName">Primary Contact First Name <span className='red-text'>*</span></Label>
                                <CustomInput
                                    type="text"
                                    name="primaryContactFirstName"
                                    id="primaryContactFirstName"
                                    value={primaryContactFName}
                                    onChange={handleFirstNameChange}
                                    style={{ border: "1px solid red" }}
                                    onFocus={() => setShowFirstNameSuggestions(true)}
                                    onBlur={() => handleInputBlur('firstname')}
                                    autoComplete="new-password"
                                    ref={firstNameRef}
                                />
                                {showFirstNameSuggestions && primaryContactFNameList && primaryContactFNameList.length > 0 && (
                                    <ListGroup className='suggestion-list' ref={firstNameSuggestionRef}>
                                        {primaryContactFNameList.map((suggestion, index) => (
                                            <ListGroupItem
                                                key={index}
                                                onMouseDown={() => handleSuggestionFNameClick(suggestion)}
                                            >
                                                {suggestion.chr_firstname} {suggestion.chr_lastname}
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                )}
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup className="form-group">
                                <Label for="primaryContactLastName">Primary Contact Last Name <span className='red-text'>*</span></Label>
                                <CustomInput type="text"
                                    name="primaryContactLastName"
                                    id="primaryContactLastName"
                                    onChange={handleLastNameChange}
                                    value={primaryContactLName}
                                    style={{ border: "1px solid red" }}
                                    onFocus={() => setShowLastNameSuggestions(true)}
                                    onBlur={() => handleInputBlur('lastname')}
                                    autoComplete="new-password"
                                    ref={lastNameRef}
                                />
                                {showLastNameSuggestions && primaryContactLNameList && primaryContactLNameList.length > 0 && (
                                    <ListGroup className='suggestion-list' ref={lastNameSuggestionRef}>
                                        {primaryContactLNameList.map((suggestion, index) => (
                                            <ListGroupItem
                                                key={index}
                                                onMouseDown={() => handleSuggestionLNameClick(suggestion)}
                                            >
                                                {suggestion.chr_firstname} {suggestion.chr_lastname}
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                )}
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" name="nationwide" checked={isNationwideActive} onClick={toggleNationwide} />
                                    nationwide</Label>
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" name="preferred" checked={isPreferredActive} onClick={togglePreferred} />
                                    Preferred</Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="street">Street <span className='red-text'>*</span></Label>
                                <Input
                                    type="text"
                                    name="street"
                                    id="street"
                                    value={streetText}
                                    onChange={handleStreetChange}
                                    style={{ border: "1px solid red" }} autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="street2">Street 2</Label>
                                <Input
                                    type="text"
                                    name="street2"
                                    id="street2"
                                    value={street2Text}
                                    onChange={handleStreet2Change} autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="city">City <span className='red-text'>*</span></Label>
                                <Input
                                    type="text"
                                    name="city"
                                    id="city"
                                    value={city}
                                    style={{ border: "1px solid red" }}
                                    readOnly />
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup>
                                <Label for="state">St</Label>
                                <Input
                                    type="text"
                                    name="state"
                                    id="state"
                                    value={state}
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="zipCode">Zip Code <span className='red-text'>*</span></Label>
                                <Input
                                    type="text"
                                    name="zipCode"
                                    id="zipCode"
                                    value={zipCode}
                                    onChange={handleTypeAheadSearch}
                                    style={{ border: "1px solid red" }}
                                />
                                {activeField === 'zipCode' && zipCode.length > 3 && zipList && zipList.length > 0 && (
                                    <ListGroup className='suggestion-list'>
                                        {zipList.map((zip, index) => (
                                            <ListGroupItem
                                                key={index}
                                                onClick={() => handleZipCodeClick(zip)}
                                            >
                                                {zip.zip_type_with_county}
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                )}
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup>
                                <Label for="county">County <span className='red-text'>*</span></Label>
                                <Input
                                    type="text"
                                    name="county"
                                    id="county"
                                    value={county}
                                    style={{ border: "1px solid red" }}
                                    readOnly />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="contactEmail">Contact Email</Label>
                                <Input
                                    type="email"
                                    name="contactEmail"
                                    id="email1"
                                    value={primaryContactEmail1}
                                    onChange={handleContactEmail1Change}
                                    autoComplete="new-password"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="alternateContactEmail">Alternate Contact Email</Label>
                                <Input
                                    type="email"
                                    name="alternateContactEmail"
                                    id="email2"
                                    value={primaryContactEmail2}
                                    onChange={handleContactEmail2Change}
                                    autoComplete="new-password"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="firmEmail">Firm Email</Label>
                                <Input type="email" name="firmEmail" id="firmEmail" value={firmEmail}
                                    onChange={handleFirmEmailChange} autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="alternateFirmEmail">Alternate Firm Email</Label>
                                <Input type="email" name="altFirmEmail" id="altFirmEmail" value={altFirmEmail}
                                    onChange={handleFieldChange} autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="website">Website</Label>
                                <Input type="text" name="website" id="website" value={websiteText}
                                    onChange={handleFieldChange} autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="mainPhone">Main Phone</Label>
                                <Input
                                    type="tel"
                                    name="mainPhone"
                                    id="mainPhone"
                                    value={formatPhoneNumber(mainPhoneNumber)}
                                    onChange={handleFieldChange}
                                    placeholder="(111) 111-1111"
                                    pattern="[\(]\d{3}[\)] \d{3}[\-]\d{4}" autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup>
                                <Label for="phoneExt">Phone Ext.</Label>
                                <Input
                                    type="text"
                                    name="phoneExt"
                                    id="phoneExt"
                                    maxLength={10}
                                    value={phoneExt}
                                    onChange={handleFieldChange}
                                    autoComplete="new-password"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="alternatePhone">Alternate Phone</Label>
                                <Input
                                    type="text"
                                    name="alternatePhone"
                                    id="alternatePhone"
                                    value={alternatePhoneNumber}
                                    onChange={handleFieldChange}
                                    placeholder="(111) 111-1111"
                                    pattern="[\(]\d{3}[\)] \d{3}[\-]\d{4}"
                                    autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="cellPhone">Cell Phone</Label>
                                <Input
                                    type="text"
                                    name="cellPhone"
                                    id="cellPhone"
                                    value={cellPhoneNumber}
                                    onChange={handleFieldChange}
                                    placeholder="(111) 111-1111"
                                    pattern="[\(]\d{3}[\)] \d{3}[\-]\d{4}"
                                    autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="fax">Fax</Label>
                                <Input type="text" name="fax" id="fax"
                                    value={faxNumber}
                                    onChange={handleFieldChange}
                                    placeholder="(111) 111-1111"
                                    pattern="[\(]\d{3}[\)] \d{3}[\-]\d{4}"
                                    autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                {/* Vendor Status Section */}
                <div className="form-section">
                    <h5>VENDOR STATUS</h5>
                    <Row form>
                        <Col md={1}>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" name="active" id="active" checked={isActive} onClick={toggleReason} />
                                    Active
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="inactiveReason">Inactive Reason</Label>
                                <Input type="text" name="inactiveReason" id="inactiveReason"
                                    value={inActiveReason}
                                    onChange={handleFieldChange}
                                    disabled={isActive} autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" name="contracted" checked={isContractedActive} onClick={toggleContracted} />
                                    contracted</Label>
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="effectiveDate">Effective Date</Label>
                                <Input
                                    type="date"
                                    name="effectiveDate"
                                    id="effectiveDate"
                                    value={effectiveDate}
                                    onChange={handleEffectiveDateChange}
                                    disabled={!isContractedActive} />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="terminationDate">Termination Date</Label>
                                <Input
                                    type="date"
                                    name="terminationDate"
                                    id="terminationDate"
                                    value={terminateDate}
                                    onChange={handleTerminateDateChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="dateCredentialed">Date Credentialed</Label>
                                <Input
                                    type="date"
                                    name="dateCredentialed"
                                    id="dateCredentialed"
                                    value={credentialedDate}
                                    onChange={handleCredentialedDateChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="dateRecredentialed">Date Recredentialed</Label>
                                <Input
                                    type="date"
                                    name="dateRecredentialed"
                                    id="dateRecredentialed"
                                    value={recredentialedDate}
                                    onChange={handleRecredentialedDateChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="paymentType">Payment Type</Label>
                                <Input type="select" name="paymentType" id="paymentType"
                                    value={paymentType}
                                    onChange={handleFieldChange} defaultValue="">
                                    <option value="" disabled hidden>Choose</option>
                                    <option value="Visa">Visa</option>
                                    <option value="American Express">American Express</option>
                                    <option value="Invoice">Invoice</option>
                                    <option value="Other">Other</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={2} style={{ paddingLeft: "117px" }}>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" name="memberOfGroup" checked={isMemberOfGroupActive} onClick={toggleGroupName} />
                                    Member of Group</Label>
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="groupName">Group Name</Label>
                                <Input type="text" name="groupName" id="groupName" disabled={!isMemberOfGroupActive} value={groupNameVal}
                                    onChange={handleFieldChange} />
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" name="prospective" checked={isProspectiveActive} onClick={toggleProspective} />
                                    Prospective
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        name="retrospective"
                                        checked={isRetrospectiveActive}
                                        onClick={toggleRetrospective}
                                        disabled={(effectiveDate === '') || (terminateDate === '')} />
                                    Retrospective
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" name="pgoVendor" checked={isPgoVendorActive} onClick={togglePgoVendor} />
                                    PGO Vendor
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>

                {/* Accounting Section */}
                <div className="form-section">
                    <h5>ACCOUNTING</h5>
                    <Row form>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="gpVendor">GP Vendor</Label>
                                <Input type="text" name="gpVendor" id="gpVendor" value={gpVendor}
                                    onChange={handleFieldChange} autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="gpCode">GP Code</Label>
                                <Input type="text" name="gpCode" id="gpCode" value={gpCode}
                                    onChange={handleFieldChange} autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="npi">NPI <span className='red-text'>*</span></Label>
                                <Input type="number" name="npi" id="npi" style={{ border: "1px solid red" }} value={npiVal}
                                    onChange={handleFieldChange} maxLength="10" autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="tin">TIN <span className='red-text'>*</span></Label>
                                <Input type="number" name="tin" id="tin" style={{ border: "1px solid red" }} value={tinVal}
                                    onChange={handleFieldChange} maxLength="9" autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="licenseNo">License No.</Label>
                                <Input type="text" name="licenseNo" id="licenseNo" value={licenseNum}
                                    onChange={handleFieldChange} autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="paymentTerms">Payment Terms</Label>
                                <Input type="select" name="paymentTerms" id="paymentTerms" value={paymentTermsVal}
                                    onChange={handleFieldChange}>
                                    <option value='' disabled hidden>Choose</option>
                                    <option value="10 Days">10 Days</option>
                                    <option value="15 Days">15 Days</option>
                                    <option value="30 Days">30 Days</option>
                                    <option value="45 Days">45 Days</option>
                                    <option value="60 Days">60 Days</option>
                                    <option value="90 Days">90 Days</option>
                                    <option value="Immediate">Immediate</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="submissionTerms">Submission Terms</Label>
                                <Input type="select" name="submissionTerms" id="submissionTerms" value={submissionTermsVal}
                                    onChange={handleFieldChange}>
                                    <option value='' disabled hidden>Choose</option>
                                    <option value="10 Days">10 Days</option>
                                    <option value="15 Days">15 Days</option>
                                    <option value="30 Days">30 Days</option>
                                    <option value="45 Days">45 Days</option>
                                    <option value="60 Days">60 Days</option>
                                    <option value="90 Days">90 Days</option>
                                    <option value="Immediate">Immediate</option>
                                </Input> </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="sapId">SAP ID</Label>
                                <Input type="number" name="sapId" id="sapId" value={sapIdVal}
                                    onChange={handleFieldChange} maxLength="6" autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="paymentMethod">Payment Method</Label>
                                <Input type="select" name="paymentMethod" id="paymentMethod"
                                    value={paymentMethod} onChange={handlePaymentMethodChange}>
                                    <option value="" disabled>Choose</option>
                                    <option value="Check">Check</option>
                                    <option value="ACH">ACH</option>
                                </Input> </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="achAccountNo">ACH Account No.</Label>
                                <Input type="text" name="achAccountNo" id="achAccountNo" disabled={paymentMethod !== 'ACH'} maxLength="9" value={achAccountNum}
                                    onChange={handleFieldChange} autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="remitStreet">Remit Address Street</Label>
                                <Input type="text" name="remitStreet" id="remitStreet" value={remitStreetText}
                                    onChange={handleStreetChange} autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="remitStreet">Street2</Label>
                                <Input type="text" name="remitStreet2" id="remitStreet2" value={remitStreet2Text}
                                    onChange={handleStreet2Change} autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup>
                                <Label for="remitCity">City</Label>
                                <Input type="text" name="remitCity" id="remitCity" readOnly value={remitCity} />
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup>
                                <Label for="remitState">St</Label>
                                <Input type="text" name="remitState" id="remitState" readOnly value={remitState} />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="remitZip">Zip Code</Label>
                                <Input type="text" name="remitZip" id="remitZip" value={remitZipCode}
                                    onChange={handleTypeAheadSearch} />
                                {activeField === 'remitZip' && remitZipCode.length > 3 && zipList && zipList.length > 0 && (
                                    <ListGroup className='suggestion-list'>
                                        {zipList.map((zip, index) => (
                                            <ListGroupItem
                                                key={index}
                                                onClick={() => handleRemitZipCodeClick(zip)}
                                            >
                                                {zip.zip_type_with_county}
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                )}
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup>
                                <Label for="remitCounty">County</Label>
                                <Input type="text" name="remitCounty" id="remitCounty" readOnly value={remitCounty} />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                <div className="form-section">
                    <h5>VENDOR INSTRUCTIONS</h5>
                    <Row form>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="notes">Notes</Label>
                                <Input type="textarea" name="notes" id="notes" value={notesText}
                                    onChange={handleFieldChange} autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="alert">Alert</Label>
                                <Input type="textarea" name="alert" id="alert" value={alertText}
                                    onChange={handleFieldChange} autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </Form>

        </div>
    )
}


const mapStateToProps = state => {
    const initialData = fast360Selectors.getFast360InitialData(state);
    const fast360MaintenacneInfo = fast360MaintenanceSelectors.getFast360Maintenance(state);
    const fast360Info = fast360Selectors.getFast360Info(state);
    const user = authSelectors.getUser(state);
    return {
        primaryContactFNameList: fast360MaintenacneInfo.primaryContactFNameList,
        primaryContactLNameList: fast360MaintenacneInfo.primaryContactLNameList,
        gpIntegrationList: initialData.gpIntegrationList,
        zipList: fast360Info.zipList,
        userId: user.id,
        newContactInfo: fast360MaintenacneInfo.newContactInfo,
        vendorInfo: fast360MaintenacneInfo.vendorInfo,
        loadNewVendorInfo: fast360MaintenacneInfo.loadNewVendorInfo
    }
}

const mapDispatchToProps = dispatch => ({
    getPrimaryContactFNameList: data => dispatch(fast360MaintenanceOperations.getPrimaryContactFNameList(data)),
    resetPrimaryContactFList: () =>
        dispatch(fast360MaintenanceActions.resetPrimaryContactFList()),
    getPrimaryContactLNameList: data => dispatch(fast360MaintenanceOperations.getPrimaryContactLNameList(data)),
    resetPrimaryContactLList: () =>
        dispatch(fast360MaintenanceActions.resetPrimaryContactLList()),
    getZipList: data => dispatch(fast360Operations.getZipList(data)),
    resetZipList: () => dispatch(fast360Actions.resetZipList()),
    saveNewContact: data => dispatch(fast360MaintenanceOperations.saveNewContact(data)),
    saveVendor: data => dispatch(fast360MaintenanceOperations.saveVendor(data)),
    getNewVendor: data => dispatch(fast360MaintenanceOperations.getNewVendor(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewVendorEditHeader);