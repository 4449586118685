import 'core-js/stable';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Col, Container, Row, Button } from 'reactstrap';
import { ThemeContext } from 'common/ThemeContext';
import { useRouter } from 'shared/hooks/useRouter';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import LoginForm from './components/LoginForm';
import TwoFactor from './components/TwoFactorAuthuntication';
import SecurityQuestionsModal from './components/SecurityQuestionsModal';
import AboutUsModal from './components/AboutUsModal';
import UpdatePasswordForm from './components/UpdatePasswordForm';
import { useAuth } from './hooks/useAuth';
import SecurityQuestionForm from './components/SecurityQuestionForm';
import { login } from './services/transform';

const Login = () => {
  const {
    errorMessage,
    loginResult,
    user,
    userId,
    currentQuestionId,
    resetPasswordResult,
    securityQuestionResult,
    logoutMessage,
    loadSecurityQuestions,
    zebraTimeoutSeconds,
    zebraTimeoutResult,
    isAuthCodeRequired,
    checkAuthCodeResult,
    passwordUpdateErrorMessage,
  } = useAuth();
  const { location, fromPathname } = useRouter();
  const [loginState, setLoginState] = useState('login');
  const [showSecurityQuestions, setShowSecurityQuestions] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [newUsername, setNewUsername] = useState('');
  const [showAboutUsModal, setShowAboutUsModal] = useState(false);
  const [defaultModule, setDefaultModuleState] = useState('');
  const [systemId, setSystemId] = useState('');

  const { themeName, themePath } = useContext(ThemeContext);
  
  const onSubmitLogin = () => {
    if (loginResult == 1) {
      loadSecurityQuestions(userId);
      if (isAuthCodeRequired) {
        setLoginState('mfa');
      }
      if (isAuthCodeRequired === false && currentQuestionId != 0) {
        setLoginState('security');
      }
      if (isAuthCodeRequired === false && currentQuestionId == 0) {
        setLoginState('securitySetup');
        setShowSecurityQuestions(true);
      }
    }
  };

  useEffect(() => {
    if (loginResult == 1) {
      loadSecurityQuestions(userId);
      if (isAuthCodeRequired) {
        setLoginState('mfa');
      }
      if (isAuthCodeRequired === false && currentQuestionId != 0) {
        setLoginState('security');
      }
      if (isAuthCodeRequired === false && currentQuestionId == 0) {
        setLoginState('securitySetup');
        setShowSecurityQuestions(true);
      }
    }
  }, [loginResult, isAuthCodeRequired, currentQuestionId]);

  useEffect(() => {
    if (loginResult == 1 && securityQuestionResult === true) {
      setLoginState('redirect');
      zebraTimeoutSeconds();
    }
  }, [loginResult, securityQuestionResult]);

  useEffect(() => {
    if (errorMessage === 'Account is locked') {
      setLoginState('forgot');
    } else if (
      (errorMessage === 'Expired password' || loginResult == 3) ||
      loginResult == 4
    ) {
      setLoginState('expired');
    }
  }, [errorMessage, loginResult]);

  useEffect(() => {
    if (user != null && user.defaultapp != null) {
      setDefaultModuleState(user.defaultapp);
    }
  });

  useEffect(() => {
    if (logoutMessage === 'USER SESSION DELETED') {
      setLoginState('login');
    }
  }, [logoutMessage]);

  const getPathname = () => {
    if (defaultModule === 'MODULE_billBrowsing.swf') {
      return '/';
    } else if (defaultModule === 'MODULE_administration.swf') {
      return '/administration/search';
    } else if (defaultModule === 'MODULE_experts.swf') {
      return '/experts';
    } else if (defaultModule === 'MODULE_LMS.swf') {
      return '/fast-360';
    } else if (defaultModule === 'MODULE_LMS_Maintenance.swf') {
      return '/fast-360-maintenance';
    }
  };

  return user && loginState === 'redirect' ? (
    <Redirect
      to={{ pathname: getPathname() || '/', state: { from: location } }}
    />
  ) : (
    <>
      <Container fluid>
        <div className="centeredContainer">
          <img src={`${themePath}/logo.png`} alt="logo" />
          <br />
          <br />
          <br />
          <Row className="align-items-center">
            <Col>
              <Row className="box">
                <Col lg="12">
                  <span className="h1 medium">
                    {themeName === 'careworks'
                      ? 'Medical Bill Review'
                      : 'Care Concierge and Medical Bill Review'}
                  </span>
                </Col>
                <Col lg="12" className="h1 medium">
                  <span style={{ fontStyle: 'italic' }}>
                    Welcome back! Please login to your account
                  </span>
                </Col>
                {loginState === 'login' && (
                  <LoginForm
                    setLoginState={setLoginState}
                    onSubmit={onSubmitLogin}
                    setUsername={setUsername}
                    username={username}
                    systemId={systemId}
                    setSystemId={setSystemId}
                    setPassword={setPassword}
                    password={password}
                  />
                )}

                {loginState === 'mfa' && (
                  <TwoFactor
                    username={username}
                    systemId={systemId}
                    checkAuthCodeResult={checkAuthCodeResult}
                    setShowSecurityQuestions={setShowSecurityQuestions}
                    setLoginState={setLoginState}
                    currentQuestionId={currentQuestionId}
                  />
                )}
                {loginState === 'forgot' && (
                  <ForgotPasswordForm
                    setNewUsername={setNewUsername}
                    setPassword={setPassword}
                    newUsername={newUsername}
                    setLoginState={setLoginState}
                  />
                )}
                {loginState === 'expired' && (
                  <UpdatePasswordForm
                    setLoginState={setLoginState}
                    setUsername={setUsername}
                    setPassword={setPassword}
                  />
                )}
                {loginState === 'security' && <SecurityQuestionForm />}
              </Row>
            </Col>
            <br />
            <br />
            <br />
            <br />
          </Row>
          {loginState === 'login' && (
            <Row>
              <Col>
                <p style={{ paddingTop: '50px', paddingLeft: '0px' }}>
                  Access to this information resource is restricted to
                  authorized users only. This information resource may be
                  monitored for administrative and security reasons. By
                  proceeding, you consent to this monitoring. In order to
                  protect the information entrusted to Sedgwick, unauthorized
                  access and/or unauthorized use of this information resource
                  may be prosecuted to the fullest extent of the law.
                </p>
                <Button
                  color="link"
                  className="h6"
                  style={{ paddingLeft: '0px' }}
                  onClick={() => setShowAboutUsModal(true)}
                >
                  About
                </Button>
              </Col>
            </Row>
          )}
        </div>
      </Container>

      {loginState == 'securitySetup' && (
        <SecurityQuestionsModal
          isOpen={showSecurityQuestions}
          onToggle={() => setShowSecurityQuestions(!showSecurityQuestions)}
          setLoginState={setLoginState}
          setPassword={setPassword}
          password={password}
        />
      )}
      {showAboutUsModal && (
        <AboutUsModal
          isOpen={showAboutUsModal}
          onToggle={() => setShowAboutUsModal(!showAboutUsModal)}
        />
      )}
    </>
  );
};

export default Login;
